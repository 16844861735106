<template>
   <ion-page :style="fontSize" >
        <CabeceraComponent :titulo="titulo_pag" color="primary"/>
        <ion-content class="ion-padding" v-if="device" >  
 
            
            <ion-row class="ion-justify-content-left ion-align-items-top selectores grande" v-if="loading==false">
                    <!--<ion-col  :size="device.tamanyo == 0 ? 48 : device.tamanyo==1 ? 18 : 24" >-->
                    <ion-col  :size="device.tamanyo <=2   ? 48 :  12 " > 
                        <div class="p-input-icon-right  mismo-que-paginador">
                            <InputText type="text" v-model="termino" placeholder="Buscar (min 3 letras)" @input="debounceSearch"/>
                            <i class="pi pi-search" />
                        </div>
                        <div class="nota">
                            <ion-note v-if="disponibles"> {{disponibles}} registros de {{datos.length}} totales, viendo los {{filtrados.length}} primeros </ion-note>
                        </div>
                    </ion-col>
                    <ion-col :size="device.tamanyo <=2 ? 48 : 18" > 
                            <ion-toolbar> 
                                <ion-buttons slot="start" >
                                    <DropDown v-model="negativo" :options="negativos" optionLabel="etiqueta" class=" selectores_cabecera cabecera "
                                        :filter="false"   v-bind:key="'selector_vista_negativos'"  
                                        @hide="tratamientoDatos"
                                        :placeholder="'Negativos?' " />

                                    <DropDown v-model="inconsistente" :options="inconsistentes" optionLabel="etiqueta" class=" selectores_cabecera cabecera "
                                        :filter="false"   v-bind:key="'selector_vista_inconsistentes'"  
                                        @hide="tratamientoDatos"
                                        :placeholder="'Inconsistentes?' " />
                                    
                                    <DropDown v-model="inactiva" :options="inactivas" optionLabel="etiqueta" class=" selectores_cabecera cabecera "
                                        :filter="false"   v-bind:key="'selector_vista_inactivas'"  
                                        @hide="tratamientoDatos"
                                        :placeholder="'Inactivas?' " />
                                </ion-buttons>                        
                            </ion-toolbar>
                    </ion-col>
                    <!--<ion-col  :size="device.tamanyo == 0 ? 48 : device.tamanyo==1 ? 30 : 24" >-->
                    <ion-col  :size="device.tamanyo <= 2 ? 48 : 18" >
                        <div class="p-input-icon-right mismo-que-paginador force-right" >
                            <wj-collection-view-navigator
                                class="navegador force-right"
                                headerFormat="Pagina {currentPage:n0} de {pageCount:n0}"
                                :byPage="true"
                                :cv="filtrados" />
                            <DropDown v-model="filas_paginas" :options="paginador"   class="selector_paginas cabecera force-right"
                                v-bind:key="'selector_filas'"  
                                @hide="tratamientoDatos"
                                placeholder="Filas " /> 
                        </div>
                    </ion-col>
            </ion-row>
            <ion-row v-if="loading" class="spin ion-justify-content-center "  >
                <ion-col  v-if="loading" class="spin ion-align-self-center" size="12" > 
                    <ion-spinner color="primary" v-if="loading"></ion-spinner>
                </ion-col>
            </ion-row>
           <ion-row class="ion-padding-left ion-margin-left grande">
                <ion-toolbar> 
                    <DropDown v-model="vista" :options="vistas" optionLabel="etiqueta" class="selector_kg"
                        :filter="false"   v-bind:key="'selector_vista_editados'"  
                        :disabled="cambios==0||cambios==undefined"
                        @hide="tratamientoDatos"
                        :placeholder="'Estado de los registros' " />
                    <DropDown v-model="nivel" :options="niveles" optionLabel="etiqueta"  class="selector_kg"
                        :filter="false"   v-bind:key="'selector_solo_marcas'"  
                        @hide="tratamientoDatos"
                        :placeholder="'Alcance de marcas' " />

                <!--     <DropDown v-model="nivel" :options="niveles" optionLabel="etiqueta"  class="selector_kg"
                        :filter="false"   v-bind:key="'selector_solo_marcas'"  
                        @hide="tratamientoDatos"
                        :placeholder="'Tipo inconsistente' " />
                -->

                    <ion-buttons slot="end" >
                        <ion-button color="tertiary"  fill="solid" @click="go_actuals">
                            <b>Ver actuales</b>
                        </ion-button>  
                        <ion-button color="primary"  fill="solid" @click="descartar">
                            <b>Descartar todo</b>
                        </ion-button>  
                        <ion-button color="success"  fill="solid" @click="grabar" :disabled="cambios==0||cambios==undefined" >
                            <b>{{vista.id==undefined||vista.id!='C' ? 'Revisar' : 'Grabar'}} Cambios</b>
                        </ion-button>  
                        <ion-button color="warning"  fill="solid" @click="exportar">
                            <b>Excel Export</b>
                        </ion-button>  
                        <ion-button color="darkdanger"  fill="solid" @click="importar">
                            <b>Importar</b>
                        </ion-button>  
                    </ion-buttons>
                </ion-toolbar>

            </ion-row>
            <ion-row v-if="!loading">
                <wj-flex-grid 
                    :showMarquee="true"
                    :anchorCursor="true"
                    :headersVisibility="'Column'"
                    :showSelectedHeaders="'All'"
                    :itemsSource="filtrados" 
                    :alternatingRowStep="0"  
                    ref="theGrid" v-if="datos"
                    :cellEditEnded = "onEditCell"
                    :pastedCell = "onEditCell"  
                    :loaded-rows = "onloadedRows"
                    >
                    <ion-row class="titulin" >
                        <div v-for="(ck,index) in titulos"  :key="'cx'+index" :style="`width : ${ck.sz}% !important`"  
                            class="wj-header ion-text-center ion-align-self-center">
                            {{ck.gr}}
                        </div>
                    </ion-row>

                    <wj-flex-grid-filter  :filterColumns="['codemp', 'codmar', 'desfam', 'last_update']" />
                        <wj-flex-grid-column v-for="(c,index) in columnas"   
                            :key="'c'+index" :header="c.header" :binding="c.col" 
                            :width="c.width+'*'" 
                            :align="c.align == undefined ? '': c.align"
                            :isReadOnly ="isEditable(c.col)"
                            :cssClass="isEditable(c.col) ? 'bloqueada' : 'no-bloqueada'"
                            :format="c.format==undefined ? '' : c.format" 
                            :dataMap="c.dmap == undefined ? '' : c.dmap"
                            >
                            <!-- Campos que requieren una presentación específica--> 
                            <wj-flex-grid-cell-template cellType="Cell" v-if="c.col=='tipo'"  v-slot="cell">
                                     <span :class="(cell.item['check_dto']===true ) ? 'peligro' :'' ">
                                        {{cell.item.tipo}}
                                      </span>
                                    <i class="peligro fa fa-exclamation-triangle" v-if="cell.item['check_dto']===true"></i>  
                            </wj-flex-grid-cell-template>

                            <!-- descuentos negativos resaltados-->
                            <wj-flex-grid-cell-template cellType="Cell" v-if="['oa','ob','oc','od','oe','og','oz'].indexOf(c.col)!=-1 "  v-slot="cell">
                                     <span :class="(cell.item[c.col]<=0 ) ? 'peligro' :'' ">
                                        {{formatNumber(cell.item[c['col']],'n1')}}
                                      </span>
                            </wj-flex-grid-cell-template>

                            <!-- descuentos negativos resaltados-->

                            <wj-flex-grid-cell-template cellType="Cell" v-if="['p_dto_z','perc_dto'].indexOf(c.col)!=-1 "  v-slot="cell">
                                <span v-html="TipoHabitual(cell.item[c['col']])" :class="(cell.item['check_dto']===true ) ? 'peligro' :'' "/>
                            </wj-flex-grid-cell-template>


                        </wj-flex-grid-column>

                        <wj-flex-grid-column
                            key="'c_edit" header="" 
                            width="0.3*"  align="center" 
                            :isReadOnly ="true"
                            cssClass="bloqueada del"
                            :cellTemplate="tplCustomEdit"
                            >
                        </wj-flex-grid-column>
                        <wj-flex-grid-column
                            key="'c_at" header="Borrar" 
                            width="0.7*"  align="center" :isReadOnly ="true"
                            cssClass="bloqueada del"
                            :cellTemplate="tplCustomDelButton"
                            >
                        </wj-flex-grid-column>
                        
                        
                </wj-flex-grid>
                </ion-row>    
        </ion-content>
        


    </ion-page>
</template>

<script>

    import { IonPage, IonContent,   
            IonRow,IonCol,  
            IonSpinner,  
            IonToolbar, IonButtons, IonButton, IonNote, modalController
     } from '@ionic/vue';
    import CabeceraComponent from '@/components/CabeceraComponent'
    import { Control } from "@grapecity/wijmo";
    import { CellMaker } from '@grapecity/wijmo.grid.cellmaker';
    import { CollectionView } from '@grapecity/wijmo';
    import { wjFormat } from '@grapecity/wijmo.vue2.core';
    import * as wjcGridXlsx from '@grapecity/wijmo.grid.xlsx';
    import { defineComponent } from 'vue';
    import { pluck, groupBy, chain, pick, union, difference, each, map, extend, omit   } from 'underscore';
    import {openAlerta ,openToast} from  '@/utilities/global_functions';
    import DescuentosClientesListImportacion  from  '@/pages/pricing/descuentos_clientes/DescuentosClientesListImportacion'
    import {  CARGO_TRANSPORTE } from '@/utilities/global_constants';
    import eventBus from "@/event-bus";
    import { useRoute } from 'vue-router';
    export default defineComponent({
            name : "DescuentosClientesListEditable",
            components: { 
                IonPage, IonContent, 
                IonRow,IonCol, IonSpinner, 
                IonToolbar, IonButtons, IonButton, IonNote, 
                CabeceraComponent  
            } , 
            props : ['codemp', 'codmar'], 
            computed :{
                fontSize () { 
                    window.fake_console('ClasificacionesEditable',`--texto-size : ${9+(this.device.tamanyo)}px`)
                    return `--texto-size : ${8+(this.device.tamanyo)}px;`
                }, 
                titulos() {
                    const totales = this.columnas.reduce( (m,v) => {return m+v['width']}, 1 )
                    const titulos =  chain(this.columnas).pluck('grupo').uniq().reduce((m,v)=>{m[v]=0;return m },{}).value()
                    titulos['Otros']=titulos['Otros']+1
                    each(this.columnas, (v)=> {titulos[v['grupo']]+=v['width']})
                    const resultado = map(titulos, (v,k)=> { return {'gr': k,'sz':(v/totales*100) }} )
                    return resultado
                }, 
                columnas() {
                    const cols = [
                        { 'grupo' : '' ,  "col": "codemp" , "header": "Pdv",   "width": 0.5, "show" : true },  
                        { 'grupo' : 'Marca' ,  "col": "codmar" , "header": "Marca",   "width": 0.5, "show" : true },  
                        { 'grupo' : 'Marca' ,  "col": "nommar" , "header": "Nombre", "width": 0.8, "show" : this.device.tamanyo>2  },  
                        { 'grupo' : 'Familia Descuento' ,  "col": "desfam" , "header": "Desfam",  "width": 1, "show" : true },  
                        { 'grupo' : 'Familia Descuento' ,  "col": "nomdf" , "header": "Descripcion ", "width": 1.5, "show" : this.device.tamanyo>1 },  
                        { 'grupo' : 'Datos Familia' ,  "col": "destot" , "header": "Dto.Compra","format" : "n1", "width": 0.8,"align": "right",  show : true },
                        { 'grupo' : 'Datos Familia' ,  "col": "factor_tarifa" , "header": "Tarven/Tarcom","format" : "n2", "width": 0.9,"align": "right",  show : true },
                        { 'grupo' : 'Datos Familia' ,  "col": "act" , "header": "Activa", "format" : "n0", "width": 0.5, "align": "right","show" : true },
                        { 'grupo' : 'Datos Familia' ,  "col": "ranking" , "header": "Rank ", "format" : "n0", "width": 0.5, "align": "right","show" : true },
                        { 'grupo' : 'Forma Venta Habitual' ,  "col": "perc_dto" , "header": "Pdv ",  "width": 0.8, "align": "left", "show" : true },
                        { 'grupo' : 'Forma Venta Habitual' ,  "col": "p_dto_z" , "header": "Zona ", "width": 0.8, "align": "left", "show" : true },
                        { 'grupo' : 'Margen Medio' ,  "col": "media" , "header": "Pdv", "format" : "n1", "width": 0.5 , "align": "right","show" : true },
                        { 'grupo' : 'Margen Medio' ,  "col": "z_avg" , "header": "Zona", "format" : "n1", "width":  0.5 , "align": "right","show" : true },
                        { 'grupo' : 'Objetivo Margen por clasif.' ,  "col": "a" , "header": "A", "format" : "n1", "width": 0.5, "align": "right","show" : true },
                        { 'grupo' : 'Objetivo Margen por clasif.' ,  "col": "b" , "header": "B", "format" : "n1", "width": 0.5, "align": "right","show" : true },
                        { 'grupo' : 'Objetivo Margen por clasif.' ,  "col": "c" , "header": "C", "format" : "n1", "width": 0.5, "align": "right","show" : true },
                        { 'grupo' : 'Objetivo Margen por clasif.' ,  "col": "d" , "header": "D", "format" : "n1", "width": 0.5, "align": "right","show" : true },
                        { 'grupo' : 'Objetivo Margen por clasif.' ,  "col": "e" , "header": "E", "format" : "n1", "width": 0.5, "align": "right","show" : true },
                        { 'grupo' : 'Objetivo Margen por clasif.' ,  "col": "z" , "header": "Cont.", "format" : "n1", "width": 0.5, "align": "right","show" : true },
                        { 'grupo' : 'Objetivo Margen por clasif.' ,  "col": "g" , "header": "GGCC", "format" : "n1", "width": 0.5, "align": "right","show" : true },
                        { "grupo" : "Margen Alerta" ,  "col": "marmin" , "header": "Alerta", "format" : "n1", "width": 0.5, "align": "right","show" : true },
                        { "grupo" : "Margen Alerta" ,  "col": "marblq" , "header": "Bloqueo", "format" : "n1", "width": 0.5, "align": "right","show" : true },
                        { 'grupo' : 'Condicion G2K por clasificacion' ,  "col": "tipo" , "header": "Tipo", "width": 0.7, "align": "left",  "show" : true, "dmap": ["MARG",'DTO'] }, 
                        { 'grupo' : 'Condicion G2K por clasificacion' ,  "col": "oa" , "header": "A", "format" : "n1", "width": 0.5, "align": "right","show" : true },
                        { 'grupo' : 'Condicion G2K por clasificacion' ,  "col": "ob" , "header": "B", "format" : "n1", "width": 0.5, "align": "right","show" : true },
                        { 'grupo' : 'Condicion G2K por clasificacion' ,  "col": "oc" , "header": "C", "format" : "n1", "width": 0.5, "align": "right","show" : true },
                        { 'grupo' : 'Condicion G2K por clasificacion' ,  "col": "od" , "header": "D", "format" : "n1", "width": 0.5, "align": "right","show" : true },
                        { 'grupo' : 'Condicion G2K por clasificacion' ,  "col": "oe" , "header": "E", "format" : "n1", "width": 0.5, "align": "right","show" : true },
                        { 'grupo' : 'Condicion G2K por clasificacion' ,  "col": "oz" , "header": "Cont", "format" : "n1", "width": 0.5, "align": "right","show" : true },
                        { 'grupo' : 'Condicion G2K por clasificacion' ,  "col": "og" , "header": "GGCC", "format" : "n1", "width": 0.5, "align": "right","show" : true } , 
                        { 'grupo' : 'Condicion G2K por clasificacion' ,  "col": "last_update" , "header": "Last", "width": 0.8, "align": "center","show" : true } , 
]
  
                    return cols.filter(x=>x.show) 
                }
            },
            data() {    
                return {
                    vistas: [{'id':'A' ,'etiqueta': 'Todos'}, {'id':'C' ,'etiqueta': 'Solo Cambios'} ], 
                    vista: {'id':'A' ,'etiqueta': 'Todos'}, 
                    niveles: [{'id':0 ,'etiqueta': 'Todas las combinaciones'}, {'id':3 ,'etiqueta': 'Solo marcas,sin detalle'} , {'id':1 ,'etiqueta': 'Solo último nivel (codmar/codfam/desfam)'} ], 
                    nivel: {'id':0 ,'etiqueta': 'Todas las combinaciones'},
                    negativos: [{'id':0 ,'etiqueta': 'Negativos y positivos'}, {'id':1 ,'etiqueta': 'Solo negativos'}  ], 
                    negativo: {'id':0 ,'etiqueta': 'Negativos y positivos'},
                    inconsistentes: [{'id':0 ,'etiqueta': 'Todos los tipos'}, {'id':1 ,'etiqueta': 'Tipo inconsistente'}  ], 
                    inconsistente: {'id':0 ,'etiqueta': 'Todos los tipos'},
                    inactivas: [{'id':0 ,'etiqueta': 'Todas las familias'}, {'id':1 ,'etiqueta': 'Solo inactivas'}  ], 
                    inactiva: {'id':0 ,'etiqueta': 'Todas las familias'},


                    cambios: 0, 
                    titulo_pag:"Edicion columnas de descuento de clientes" , 
                    filas_paginas : 30, 
                    paginador : [15,30,50,100],
                    tplCustomDelButton: CellMaker.makeButton({
                        text : '<i class="${item.ea==\'D\' ? \'fa fa-undo\' :  (item.ea!=\'X\'||item.ms_uuid!=undefined) ? \'fa fa-trash\'  : \'\'  }"/>',
                        click: this.onDeleteCell
                    }),                 
                    tplCustomEdit: CellMaker.makeButton({
                            text: '<i class="${item.ea==\'D\' ?  \'fa fa-trash\' : item.edit===1 ? \'fa fa-pencil\'  : \'\'  }"/>',
                    }),

                   /** Variables para los filtros */

                   /** Variable de control para actuar en mount/update */
                    ruta: undefined , 
                    fetchUrl : undefined, 
                    /** Variable que controla el spinner de carga  */
                    loading: true,                     

                    /** variable de control del debounce (diferimiento ) */
                    debounce: null,

                    /**
                     * Variable donde estan los datos que efectivamente se ven en la tabla 
                     * una vez aplicados todos los filtros 
                     */
                    datos: undefined, 
                                        
                    /**
                     * Variable donde estan los datos que efectivamente se ven en la tabla 
                     * una vez aplicados todos los filtros 
                     */
                    filtrados: undefined, 
                    disponibles : undefined, 
                    editable : undefined, 
                    campos : undefined, 
                    largo: undefined, 



                    /** 
                     * Termino de busqueda que introducimos en el filtro libre 
                     */                    
                    termino: undefined, 

                    /** 
                     * Datos del dispositivo, son globales, controlamos los eventos de 'window' 
                     * para responder a los cambios de tamaño de ventana, orientacion...  
                     */                    
                    device: this.$device, 

                }
            },  
            async updated(){
                window.fake_console('ClasificacionesEditable','en el update 245')
                const route = useRoute();
                window.fake_console('ClasificacionesEditable',this.ruta, route.path)
                if (route.fullPath!=this.ruta)
                {
                    this.loading = true;
                    window.fake_console('ClasificacionesEditable','llamo al update ')
                    this.ruta = route.fullPath
                    await this.procesoInicial()
                    
                }         
                else {
                    window.fake_console('ClasificacionesEditable','NO llamo al update')
                }       
            },
            async mounted() {
                const route = useRoute();
                this.ruta = route.fullPath
                window.fake_console('ProhabGrupo',route.path, route.query)
                await this.procesoInicial()
            }, 
            methods :{

                async cambiar_headers(grid ){
                    const estado = grid.columns[0].header === grid.columns[0].binding
                    const cabeceras = this.columnas.reduce( (m,v) =>  { m[v['col']] =v['header']; return m }, {})
                    if (!estado) {
                        each(grid.columns,(v)=> {v.header = v.header == 'Borrar' ? v.header.toLowerCase() : v.binding})
                    }
                    else {
                        each(grid.columns,(v)=> {v.header = v.header == 'borrar' ? 'Borrar' : cabeceras[v['binding']]} )
                    } 
                    grid.refresh()
                },
                async exportar() {
                    let self = this
                    const hoy =  this.$filters.now2Date()
                    const theGrid =  await Control.getControl(this.$refs.theGrid.$el);
                    await this.cambiar_headers(theGrid)
                    const paginas = this.filas_paginas
                    this.filas_paginas = 0 
                    await this.tratamientoDatos()
                    await wjcGridXlsx.FlexGridXlsxConverter.saveAsync(
                        theGrid,
                        {
                            includeColumnHeaders: true ,
                            includeStyles: true, 
                            includeColumns: function(x)  {
                                return (
                                    /* es editable*/ 
                                    !self.isEditable(x.binding) 
                                    /* es clave primaria*/ 
                                    || ['codemp','codmar','desfam'].indexOf(x.binding)!=-1||
                                    /* es la marca de borrado */
                                    (x.header||'').toLowerCase() == 'borrar')
                                }
                        },
                        `${hoy}.descuentos_abc.pdv.${this.codemp}.marca.${this.codmar}.xlsx`, 
                        async ()=> {
                            await this.cambiar_headers(theGrid)
                        }
                    );
                    this.filas_paginas = paginas
                    await this.tratamientoDatos()
                },
                async procesoInicial() {
                    this.loading = true;
                    this.device = this.$device 
                    eventBus().emitter.on('device', (sz) => {  this.device=sz })          
                    this.fetchData()
                }, 
                formatNumber: function(value, format) {
                    return wjFormat(value, format);
                }  , 
                TipoHabitual: function(value){
                    const tipo = value<50 ? 'MARG' : 'DTO' 
                    const valor = value <50 ? 100-value : value 
                    return `${tipo} <span style="font-size: smaller !important;"> ( ${valor}% )</span>`
                },

                isEditable(cname){
                    /** no esta en la lista de editables  */
                    return this.editable.indexOf(cname)==-1
                },
                onloadedRows(grid) {
                  grid.rows.forEach(row => {
                        const dt = row.dataItem; 
                        row.cssClass= dt.ea=='D' ? "tachar" :  dt.ea =='X' ? '' : 'cambiado'
                    })
      
                 },      
                onDeleteCell(e,ctx) {
                    const dt = ctx.item
                    const row = ctx.row
                    if (dt.ea == 'X' && dt.edit!=1 && dt.ms_uuid==undefined)
                    { void(0) }
                    else {
                        dt.ea = dt.ea!='D' ? 'D' : dt.edit===1 ? (dt.ms_uuid == undefined ? 'I' : 'U') : 'X'
                        row.isReadOnly = dt.ea == 'D' ;
                        row.cssClass= dt.ea=='D' ? "tachar" : dt.ea =='X' ? '' : 'cambiado'
                        this.cambios = pluck(this.datos,'ea').filter((z)=>{return z!='X'}).length
                        window.fake_console(this.cambios)
                    }
                }, 
               async onEditCell(grid,e) {
                   
                    const dt = grid.rows[e.row].dataItem
                    const row = grid.rows[e.row]
                    dt.edit = 1 
                    dt.ea =  dt.ms_uuid == undefined ? 'I' : 'U'
                    dt['check_dto'] = (dt['tipo']=='MARG' && dt['perc_dto']>50) || (dt['tipo']=='DTO' && dt['perc_dto']<50);
                    row.cssClass= 'cambiado'
                    /** INCORPORAMOS EL CONTRASTE DEL TIPO PARA EVITAR QUE PONGAMOS DESCUENTOS DONDE NO ES POSIBLE */
                    if (
                        dt['tipo']=='DTO' && 
                        (
                            ( (dt.destot||0)== 0 && (dt.factor_tarifa||1)== 1 )
                            ||
                            (!dt.destot || !dt.factor_tarifa)  
                        )
                    ) {
                        dt['tipo']='MARG'
                    }
                    each(['a','b','c','d','e','z','g'], async (v)=>{
                        const valor = await this.cambioDato(dt[v],dt.tipo, dt.destot, dt.factor_tarifa)
                        dt['o'+v] = valor
                    })
                    this.cambios = pluck(this.datos,'ea').filter((z)=>{return z!='X'}).length
                    window.fake_console(this.cambios)
                }, 
                async cambioDato(margen, tipo, dto,factor) {
                    if (tipo==='MARG') {
                        return Math.trunc(margen*2)/2
                    }
                    else  {
                        const d = 100- (dto ? Math.trunc(dto*100)/100 : 0)
                        /*
                            Modelo previo, añadimos coste transporte por consistencia con Mysql 
                            const m = 100 -   Math.trunc(margen*100)/100
                        */
                        const m = (100 - Math.trunc(Math.min(margen,99)*100)/100) / (1 + CARGO_TRANSPORTE)
                        const f = factor ? Math.trunc(factor*100)/100 : 1
                        /*
                            Modelo previo, redondeamos a la baja y sin decimales
                            return Math.max(0,100 - (Math.trunc((d*200/m)/f)/2))
                        */
                        return Math.max(0, 100-Math.ceil(Math.trunc((d*200/m)/f)/2))
                    }
                },
                async fetchData(c){ 
                    this.loading = true
                    const url =`/pricing/clasificaciones-todas/${this.codemp}/${this.codmar}`
                    window.fake_console('ClasificacionesEditable',url, this.fetchUrl)
                    if (url !=this.fetchUrl ||c!=undefined)
                    {
                        
                        this.fetchUrl = url
                        const result = await this.$http.get(url) 
                        window.fake_console('ClasificacionesEditable','Es cache?? : ',result.request.fromCache)
                        window.fake_console(result.data)
                        this.editable = result.data.editable 
                        this.campos = result.data.campos
                        const data = result.data.data 
                        this.datos = data.reduce((m,v)=> {
                            v['ea']='X';
                            v['i']= 'fa fa-trash';
                            v['check_dto'] = (v['tipo']=='MARG' && v['perc_dto']>50) || (v['tipo']=='DTO' && v['perc_dto']<50);
                            v['negativos'] = ['oa','ob','oc','od','oe','og','oz'].reduce((m,k)=> {return m+(v[k]<=0 ? 1 : 0 )} , 0 )!=0
                            m.push(v);return m} ,[])
                        this.vista = await this.vistas.filter((i)=>{window.fake_console(i); return i.id=='A'})[0]
                        await this.tratamientoDatos()
                    }
                    this.loading = false
                }, 
                debounceSearch() {
                    clearTimeout(this.debounce)
                    this.debounce = setTimeout(() => { this.tratamientoDatos()}, 250)
                },

                async tratamientoDatos() {
                    let filtrados = this.datos
                    if (this.termino!=undefined && this.termino.length>=2){
                        this.termino.toLowerCase().split(' ').forEach(t=>
                            filtrados = filtrados.filter((v)=> {return JSON.stringify(v).toLowerCase().indexOf(t)!=-1})
                        )
                    }
                    if (this.vista.id ==='C') {
                        filtrados = filtrados.filter(x =>x.ea!='X')
                    }
                    if (this.negativo.id === 1) {
                        filtrados = filtrados.filter(x=>x.negativos )
                    }
                    if (this.inconsistente.id === 1) {
                        filtrados = filtrados.filter(x=>x.check_dto )
                    }
                    if (this.inactiva.id === 1) {
                        filtrados = filtrados.filter(x=>x.act==0 )
                    }
                    this.disponibles = filtrados.length
                    this.filtrados =  new CollectionView(filtrados, { pageSize: this.filas_paginas});
                    return 1
                }, 
                
                async descartar() {
                    this.fetchData(1)
                },
                async go_actuals() {
                    const pdv = (this.codemp =='000' && this.codmar== '999') ? '001' : this.codemp 
                    const marca = this.codemp!='000' ? '999' : this.codmar 
                    window.fake_console(this.codemp, this.codmar, pdv, marca)
                    this.$router.replace(`/descuentos-abc/list/${pdv}/${marca}?q=${Date.now()}`)
                }, 
                async cambiarPuntoVista(){
                    this.vista = await this.vistas.filter((i)=>{window.fake_console(i); return i.id=='C'})[0]
                    await this.tratamientoDatos()
                }, 

                async grabar() {
                    var self = this
                    if ( this.vista.id =='A')
                    {
                        openAlerta(
                            'Antes de seguir...',
                            'Vas a actualizar datos de asignacion de proveedor interno a marcas, antes de hacerlo dale un vistado a los cambios.' 
                             , ()=>{
                                self.cambiarPuntoVista()
                             })
                    }
                    else 
                    {
                        const campos =  difference(this.campos, ['ms_uuid','usr'])
                        const editables = union(this.editable,['ms_uuid'])
                        const nuevos = groupBy(this.datos.filter(x =>x.a!='X'),'ea')
                        nuevos['D'] = chain(nuevos['D']).pluck('ms_uuid').compact().value()
                        nuevos['U'] = chain(nuevos['U']).map((v)=>pick(v,editables)).value()
                        nuevos['I'] = chain(nuevos['I']).map((v)=>pick(v,campos)).value()
                        const url = `/pricing/maintain-clasificaciones/${this.codemp}/${this.codmar}`
                        const post = await this.$http.post(url, omit(nuevos, 'X')) 
                        if (post.data.status && post.data.status ===false ) {
                                openAlerta('Error entrada clasificaciones', 'Algo no hay ido bien, contacta con ATI...',
                                ()=> this.go_actuals() )
                         }
                        else {
                            const texto = this.codemp == '000' ? 'de la marca' : 'del pdv'
                            openToast('Edicion entrada clasificaciones',`Tus cambios de condiciones por clasificacion interno han sido grabados, te llevamos al listado principal ${texto}`)
                            this.go_actuals()
                        }
                    }
                }, 
                async mergeData(data) {
                    let self = this
                    const theGrid =  await Control.getControl(this.$refs.theGrid.$el);
                    /** Construimos el diccionario de nuevos registros considerando solo los que encajan con la página a nivel de pdv y  marca  */
                    const codemp = (this.codemp ==='000' && this.codmar === '999') ? '001' : this.codemp === '000' ? '\\d{3}' : this.codemp 
                    const pdvRegex = new RegExp('^'+codemp+'$','i')
                    const marcaRegex = new RegExp('^'+(this.codemp!='000' ? '.{3}' : this.codmar )+'$','i')
                    const newData = data.reduce((m,v)=> {
                        if ( pdvRegex.test(v.codemp) && marcaRegex.test(v.codmar))
                        {
                            const clav = `${v.codemp}.${v.codmar}.${v.codmar == '999' ? null : v.desfam}`
                            m[clav] = v 
                        }
                        return m 
                    } , {})
                    each(this.datos, (vv)=> {
                        const clave =  `${vv.codemp}.${vv.codmar}.${vv.desfam}`
                        if(newData[clave] != undefined) {
                            const borralo =  newData[clave].borrar
                            const ndat = pick(newData[clave],self.editable)
                            switch(borralo){
                                case 1: 
                                    vv.ea = 'D'
                                    break
                                default:
                                    vv = extend(vv,ndat)
                                    vv.ea = vv.ms_uuid == null ? 'I' : 'U'
                                    vv.edit= 1
                                    vv['check_dto'] = (vv['tipo']=='MARG' && vv['perc_dto']>50) || (vv['tipo']=='DTO' && vv['perc_dto']<50);
                                    /** Verificamos que tenga sentido el tipo DTO => que hay dto de compra y factor de tarifa*/
                                    if (
                                        vv['tipo']=='DTO' && 
                                        (
                                           ( (vv.destot||0)== 0 && (vv.factor_tarifa||1)== 1 )
                                           ||
                                           (!vv.destot || !vv.factor_tarifa)  
                                        )
                                    ) {
                                        vv['tipo']='MARG'
                                    }
                                    each(['a','b','c','d','e','z','g'], async (vx)=>{
                                        const valor = await this.cambioDato(vv[vx],vv.tipo, vv.destot, vv.factor_tarifa)
                                        vv['o'+vx] = valor
                                    })
                                    vv['negativos'] = ['oa','ob','oc','od','oe','og','oz'].reduce((m,k)=> {return m+(vv[k]<=0 ? 1 : 0 )} , 0 )!=0

                                    break
                            }
                        }
                    });
                    this.cambios = pluck(this.datos,'ea').filter((z)=>{return z!='X'}).length
                    this.tratamientoDatos()
                    theGrid.refresh()
                }, 
                async importar () {
                    const pdv = (this.codemp =='000' && this.codmar== '999') ? '001' : this.codemp 
                    const marca = this.codemp!='000' ? '999' : this.codmar 
                    window.fake_console(this.codemp, this.codmar, pdv, marca)
                    const modal = await modalController
                        .create({
                                component: DescuentosClientesListImportacion,
                                cssClass: 'custom-recommendations-modal', 
                                componentProps: { codemp: pdv, codmar: marca },
                                })
                    eventBus().emitter.on('cerrar-importador-descuentos_abc',async (nuevos_datos)=>{
                        modal.dismiss();
                        if (nuevos_datos!=undefined) {  
                            await this.mergeData(nuevos_datos)
                        }  
                    });
                    modal.present();
                }, 
                

            }            
})
</script>


<style scoped lang="scss">
     @import '@/theme/listas-editables.scss';
</style>