<template>
   <ion-page :style="fontSize" >
        <CabeceraComponent :titulo="titulo_pag" color="primary"/>
        <ion-content class="ion-padding" v-if="device" >  

                
            <ion-row v-if="loading" class="spin ion-justify-content-center "  >
                <ion-col  v-if="loading" class="spin ion-align-self-center" size="12" > 
                    <ion-spinner color="primary" v-if="loading"></ion-spinner>
                </ion-col>
            </ion-row>

            <DataTable :value="datos" 
                v-if="!loading && datos"
                v-bind:key="device.tipo && device.ancho"
                :paginator="false" 
                class="editable-cells-table"
                scrollHeight="flex" 
                scrollDirection="vertical" 
                :scrollable="true" 
                removableSort
                :sortable="true"
                ref="datatable_gestor_detail"
                responsiveLayout="scroll"
                :rowClass="Validar_Linea"
                selectionMode="single" 
                editMode="row" dataKey="numlin" v-model:editingRows="editingRows" @row-edit-save="recalcular_linea"
                @rowUnselect="onRowUnselect" 
                @rowExpand="onRowExpand"
                v-model:expandedRows="expandedRows"
                    >
                <template #header>
                <div >
                    <ion-row>
                        <ion-col size="36" >
                            <h3>Detalle del pedido {{cabecera.id}} del {{$filters.text2Date(cabecera.fecped)}}</h3>
                            <h5>
                                <ul>
                                    <li>Proveedor : {{cabecera.prov}}</li>
                                    <li>Usuario : {{cabecera.usu}}</li>
                                    <li v-if="cabecera.notped!=null">Nota : {{cabecera.notped}}</li>
                                </ul>
                            </h5>
                        </ion-col>
                         <ion-col size="12">
                            <ion-toolbar> 
                                <ion-buttons slot="end" size="large">
                                    <ion-button color="success" fill="solid" size="large" @click="updateOrder"> Grabar Cambios</ion-button>                                                       
                                    <ion-button color="danger"  fill="solid"  size="large" @click="goBack"> Salir sin Grabar</ion-button> 
                                </ion-buttons>
                            </ion-toolbar>
                        </ion-col>
                    </ion-row>
                </div>


                </template>
                <template #loading>
                    Cargando datos de stock. Por favor, espera.
                </template>

                <!-- COLUMNAS DE LA CABECERA--> 
                <ColumnGroup type="header" >
                    <Row :headerStyle="AnchoCol(100)" >
                        
                        <Column header="Datos Clave" :headerStyle="anchos_grupos['datos_gen']" :bodyStyle="anchos_grupos['datos_gen']" />
                        <Column header="Ventas / Dias" :headerStyle="anchos_grupos['ventas']" :bodyStyle="anchos_grupos['ventas']" />
                        <Column header="Pedido Cliente" :headerStyle="anchos_grupos['pedidos_cliente']" :bodyStyle="anchos_grupos['pedidos_cliente']" />
                        <Column header="Stocks" :headerStyle="anchos_grupos['stock']" :bodyStyle="anchos_grupos['stock']" />
                        <Column header="Precios / PMP" :headerStyle="anchos_grupos['precios']" :bodyStyle="anchos_grupos['precios']" />
                        <Column class="no-border" :headerStyle="anchos_titulo['act']" :bodyStyle="anchos_titulo['act']" />
                    </Row>
                    
                    <Row :headerStyle="AnchoCol(100)">
                           
                        <!-- Datos Generales --> 
                        <Column :headerStyle="anchos['codmar']" :bodyStyle="anchos['codmar']" />
                        <Column header="Marca" :sortable="true" field="codmar"    :headerStyle="anchos['codmar']" :bodyStyle="anchos['codmar']" />
                        <Column header="Articulo" :sortable="true" field="artpro"    :headerStyle="anchos['artpro']" :bodyStyle="anchos['artpro']" />
                        <Column header="Nombre" :sortable="true" field="nomart"    :headerStyle="anchos['nomart']" :bodyStyle="anchos['nomart']" />
                        <Column header="Uds" :sortable="true" field="unidad"    :headerStyle="anchos['unidad']" :bodyStyle="anchos['unidad']" />
                        <Column header="Pvp" :sortable="true" field="precom"    :headerStyle="anchos['precom']" :bodyStyle="anchos['precom']" />
                        <Column header="Dto." :sortable="true" field="destot"    :headerStyle="anchos['destot']" :bodyStyle="anchos['destot']" />
                        <Column header="Importe Total" :sortable="true" field="impbru"    :headerStyle="anchos['impbru']" :bodyStyle="anchos['impbru']" />
                        <Column header="N1" :sortable="true" field="n1"    :headerStyle="anchos['n1']" :bodyStyle="anchos['n1']" />

                        <!--  Venta y consumos --> 
                        <Column header="Dias Tam" :sortable="true" field="d_tam"    :headerStyle="anchos['d_tam']" :bodyStyle="anchos['d_tam']" />
                        <Column header="M0" :sortable="true" field="v_m0"    :headerStyle="anchos['v_m0']" :bodyStyle="anchos['v_m0']" />
                        <Column header="Q1" :sortable="true" field="v_q1"    :headerStyle="anchos['v_q1']" :bodyStyle="anchos['v_q1']" />
                        <Column header="Q2" :sortable="true" field="v_q2"    :headerStyle="anchos['v_q2']" :bodyStyle="anchos['v_q2']" />

                        <!-- Pedidos clientes --> 
                        <Column header="Unidades" :sortable="true" field="uped_cli"    :headerStyle="anchos['uped_cli']" :bodyStyle="anchos['uped_cli']" />
                        <Column header="Dias Pedido" :sortable="true" field="dias_pedcli"    :headerStyle="anchos['dias_pedcli']" :bodyStyle="anchos['dias_pedcli']" />
                        <Column header="Dias Entrega" :sortable="true" field="dias_cli"    :headerStyle="anchos['dias_cli']" :bodyStyle="anchos['dias_cli']" />

                        <!-- Stocks -->
                        <Column header="Alm." :sortable="true" field="stoalm"    :headerStyle="anchos['stoalm']" :bodyStyle="anchos['stoalm']" />
                        <Column header="Recib." :sortable="true" field="recibir"    :headerStyle="anchos['recibir']" :bodyStyle="anchos['recibir']" />
                        <Column header="Serv." :sortable="true" field="servir"    :headerStyle="anchos['servir']" :bodyStyle="anchos['servir']" />
                        <Column header="Dispo" :sortable="true" field="dispo"    :headerStyle="anchos['dispo']" :bodyStyle="anchos['dispo']" />
                        <Column header="Stock CL" :sortable="true" field="st_caldes"    :headerStyle="anchos['st_caldes']" :bodyStyle="anchos['st_caldes']" />

                        <!-- Precios / PMP --> 
                        <Column header="Precio" :sortable="true" field="imp_uni"    :headerStyle="anchos['imp_uni']" :bodyStyle="anchos['imp_uni']" />
                        <Column header="Pmp" :sortable="true" field="pmp"    :headerStyle="anchos['pmp']" :bodyStyle="anchos['pmp']" />
                        <Column header="Pmp CL" :sortable="true" field="pmp_caldes"    :headerStyle="anchos['pmp_caldes']" :bodyStyle="anchos['pmp_caldes']" />

                        <!-- botones de accion --> 
                         <Column header="Acciones" :sortable="false" field="act"    :headerStyle="anchos['act']" :bodyStyle="anchos['act']" />
                    </Row>  
                </ColumnGroup>

                <!-- COLUMNAS DE DATOS --> 
                <Column :expander="true" :headerStyle="anchos['dummy1']" :bodyStyle="anchos['dummy1']"  />
                <Column v-for="(c, index) of columnas" :field="c" 
                    :key="c + '_' + index" 
                    :sortable="true"
                    :headerStyle="anchos[c]" 
                    :bodyStyle="anchos[c]" 
                    >   

                    <template #body="slotProps" >
                        <!-- campos texto alineacion izquierda-->
                        <div class="izquierda azul" v-if="c=='codmar'">
                            {{slotProps.data[c]}} 
                        </div>

                        <div class="izquierda azul" v-else-if="c =='artpro'">
                            <i class="wait fa fa-clock-o" v-if="slotProps.data.est_gest=='P'"></i>  
                            {{slotProps.data[c]}}  
                        </div>
                        
                        <div class="izquierda azul" v-else-if="c =='nomart'">
                            <i class="outlet fa fa-exclamation-triangle" v-if="slotProps.data.outlet==1"></i>  
                            {{slotProps.data[c]}}  
  
                        </div>
                        <!-- campos decimales-->
                        <div class="derecha azul" v-else-if="['numlin','unidad','n1','d_tam','v_m0','v_q1','v_q2','uped_cli','dias_pedcli','dias_cli','stoalm','recibir','servir','dispo','st_caldes'].indexOf(c)!=-1">
                            {{ $filters.Entero(slotProps.data[c])}}
                        </div>
                        <div class="derecha azul" v-else-if="['impbru'].indexOf(c)!=-1">
                            {{ $filters.s1Decimal(slotProps.data[c])}}
                        </div>

                        
                        <!-- Campos porcentaje 1 digito-->
                        <div class="derecha azul" v-else-if="['precom', 'impuni','pmp','pmp_caldes'].indexOf(c)!=-1">
                            {{ $filters.sDecimal( slotProps.data[c])}}
                        </div>  
                        <!-- Campos porcentaje sin decimal-->
                        <div class="derecha azul" v-else-if="['destot'].indexOf(c)!=-1">
                            {{ $filters.sIntPorcentaje2D(slotProps.data[c])}}
                        </div>
                        <div class="derecha" v-else-if="['act'].indexOf(c)!=-1">
                            <ion-row class="ion-no-padding ion-no-margin">
                                <ion-col  class="ion-no-padding ion-no-margin">
                                    <ion-button color="success" size="small">A</ion-button>                                    
                                </ion-col>
                                <ion-col class="ion-no-padding ion-no-margin">
                                    <ion-button color="danger"  size="small">R</ion-button>                                    
                                </ion-col>
                            </ion-row>
                        </div>
                        <div v-else class="centro">
                            {{slotProps.data[c]}}                            
                        </div>
                    </template>

                    <!-- TEMPLATE PARA EDICION DE DATOS  -->
                    <template #editor="slotProps" v-if="['unidad','precom','destot'].indexOf(c)!=-1" >
                        <div class="derecha" v-if="c=='unidad'">
                            <InputNumber class="p-inputtext-sm p-invalid"  v-model="slotProps.data[c]"  />
                        </div>
                        <div class="derecha" v-if="c!='unidad'">
                            <InputNumber class="p-inputtext-sm p-invalid"  mode="decimal" locale="de-DE" :minFractionDigits="2" :maxFractionDigits="2"  v-model="slotProps.data[c]"  />
                        </div>
                    </template>
                </Column>   

                <Column :rowEditor="true" :headerStyle="anchos['act']" 
                    :bodyStyle="anchos['act']" 
                ></Column>

                <!-- DETALLE EXPANDIBLE DE LA REFERENCIA -->
                <template #expansion="slotProps" >
                    <div class="sku-details-subtable"  style=" margin-left: 22% !important; margin-bottom: 0px !important">
                        <DataTable :value="slotProps.data.detalle" 
                            :rowClass="esGrupo"
                            class="p-datatable-detalle p-datatable-sm" 
                            :scrollable="false" 
                            scrollHeight="flex"
                        >
                            <template #loading>
                                Cargando datos de la referencia. Por favor, espera.
                            </template>
                            <Column v-for="(c, index) of ['p',  'sc',   'ec','rc','vc', 'wc', 'wx', 'vn_x', 'vn_u','co_u', 'fse', 'use']" :field="c" 
                                :header="titulos_detalle[c]" :key="c + '_det_' + index+'_'+[device.tipo]" 
                                :headerStyle="ancho_detalle" 
                                :bodyStyle="ancho_detalle"
                                :sortable="false">
                                <template #body="slotProps" >
                                    <div class="izquierda azul" v-if="['p','m','ar'].indexOf(c)!=-1">
                                        {{slotProps.data[c]}} 
                                    </div>
                                    <div class="derecha azul resaltado" v-else-if="['wc','wx'].indexOf(c)!=-1">
                                        {{ $filters.sIntPorcentaje(slotProps.data[c])}} 
                                    </div>

                                    <div class="derecha azul" v-else-if="[ 'fse'].indexOf(c)!=-1">
                                        {{ $filters.sIntPorcentaje(slotProps.data[c])}} 
                                    </div>

                                    <div class="derecha azul" v-else-if="['vn_u','co_u'].indexOf(c)!=-1">
                                        {{ $filters.num2Month(slotProps.data[c])}} 
                                    </div>

                                    <div class="derecha azul" v-else>
                                            {{ $filters.Entero(slotProps.data[c]) }}
                                    </div>
                                </template>
                            </Column>
                        </DataTable>
                    </div>
                </template>


                <ColumnGroup type="footer" >
                    <Row :footerStyle="AnchoCol(100)" >
                           <!--  :footer="$filters.sDecimal(total_original)"-->
                        <Column :footerStyle="anchos_footer['inicio']" >
                            <template #footer>
                                <div class="izquierda azul indentado">Totales (Originales)</div>
                            </template>
                        </Column>
                        
                        <Column  class="derecha azul" :footerStyle="anchos_footer['impbru']" >
                            <template #footer>
                                    <div class="derecha azul">
                                    {{ $filters.s1Decimal(total_original)}}
                                </div>
                            </template>
                        </Column>
                        <Column :footerStyle="anchos_footer['resto']" />
                    </Row>          
                    <Row :footerStyle="AnchoCol(100)" >
                           <!--  :footer="$filters.sDecimal(total_original)"-->
                        <Column :footerStyle="anchos_footer['inicio']" >
                            <template #footer>
                                <div class="izquierda azul indentado">Nuevo total pedido tras cambios</div>
                            </template>
                        </Column>
                        <Column  class="derecha azul" :footerStyle="anchos_footer['impbru']" >
                            <template #footer>
                                    <div class="derecha azul">
                                    {{ $filters.s1Decimal(nuevo_total)}}
                                </div>
                            </template>
                        </Column>
                        <Column :footerStyle="anchos_footer['resto']" />
                    </Row>          

                </ColumnGroup>

            </DataTable>
        </ion-content>
        


    </ion-page>
</template>

<script>
    import { IonPage, IonContent,   
    IonRow,IonCol,  IonButton, 
    IonSpinner,IonButtons,IonToolbar,
     } from '@ionic/vue';
    import CabeceraComponent from '@/components/CabeceraComponent'
    import {openAlerta} from  '@/utilities/global_functions';
    import { defineComponent } from 'vue';
    import * as _ from 'underscore';
    import eventBus from "@/event-bus";
    import { closeCircle } from 'ionicons/icons';
    import { useRoute } from 'vue-router';
    export default defineComponent({
            name : "GestorDetail",
            components: { 
                IonPage, IonContent, 
                IonRow,IonCol, IonButton,
                IonSpinner,IonButtons,IonToolbar,
                CabeceraComponent  
                
            } , 
            props :['codemp', 'codsuc','numped'],
            computed :{
                fontSize () { 
                    window.fake_console('GestorList',`--texto-size : ${11+(this.device.tamanyo)}px`)
                    return `--texto-size : ${11+(this.device.tamanyo)}px;`
                }, 
                ancho_detalle() {
                        return `width: ${(this.device.ancho-32)/15}px !important`
                },
                
                anchos_grupos() {
                    const x = this.base_anchos
                    const grupos = {
                        'datos_gen':x['dummy1']+x['codmar']+x['artpro']+x['nomart']+x['unidad']+x['precom']+x['destot']+x['impbru']+x['n1'],
                        'ventas': x['d_tam']+x['v_m0']+x['v_q1']+x['v_q2'],
                        'pedidos_cliente': x['uped_cli']+x['dias_pedcli']+x['dias_cli'],
                        'stock':x['stoalm']+x['recibir']+x['servir']+x['dispo']+x['st_caldes'],
                        'precios': x['impuni']+x['pmp']+x['pmp_caldes']  , 
                        'act' : x['act']                         
                    }
                    const factor = _.reduce(_.values(this.base_anchos), (m,v)=> {return m+=(v/100)},0)
                    const resultado = _.reduce(grupos, (m,v,k)=> {m[k]= this.AnchoCol(v / factor) ;return m },{})
                    return resultado
                },

                anchos_footer() {
                    const x = this.base_anchos
                    const grupos = {
                        'inicio':x['dummy1']+x['codmar']+x['artpro']+x['nomart']+x['unidad']+x['precom']+x['destot'],
                        'impbru':x['impbru'], 
                        'resto': x['n1']+x['d_tam']+x['v_m0']+x['v_q1']+x['v_q2']+ x['uped_cli']+x['dias_pedcli']+x['dias_cli']
                                + x['stoalm']+x['recibir']+x['servir']+x['dispo']+x['st_caldes']+ x['impuni']+x['pmp']+x['pmp_caldes'] + x['act']       
                    }
                    const factor = _.reduce(_.values(this.base_anchos), (m,v)=> {return m+=(v/100)},0)
                    const resultado = _.reduce(grupos, (m,v,k)=> {m[k]= this.AnchoCol(v / factor) ;return m },{})
                    return resultado
                },

                anchos_titulo(){
                    const factor = _.reduce(_.values(this.base_anchos), (m,v)=> {return m+=(v/100)},0)
                    const resultado = _.reduce(this.base_anchos, (m,v,k)=> {m[k]= this.AnchoCol(v / factor) ;return m },{})
                    return resultado
                },
                anchos () {
                    /** Nos aseguramos que las definiciones de columnas sumen 100, sino aplicamos proporcionalidad  */
                    const factor = _.reduce(_.values(this.base_anchos ), (m,v)=> {return m+=(v/100)},0)
                    window.fake_console(factor)
                    const resultado = _.reduce(this.base_anchos , (m,v,k)=> {m[k]= this.AnchoCol(v / factor) ;return m },{})
                    window.fake_console(this.device.tipo, this.device.ancho,  factor, resultado)
                    return resultado
                    },
            },
            data() {    
                return {
                    base_anchos : { 
                            "dummy1": 2, "codmar"  : 2 , "artpro"  : 6 , "nomart"  : 8 , "unidad"  : 3 , "precom"  : 3 , "destot"  : 3 , 
                            "impbru"  : 4 , "n1"  : 2 , "d_tam"  : 3 , "v_m0"  : 3 , "v_q1"  : 3 , "v_q2"  : 3 , "uped_cli"  : 3 , 
                            "dias_pedcli"  : 3 , "dias_cli"  : 3 , "stoalm"  : 3 , "recibir"  : 3 , "servir"  : 3 , "dispo"  : 3 ,
                            "st_caldes"  : 3 , "impuni"  : 3 , "pmp"  : 3 , "pmp_caldes"  : 3 , "act" : 3
                        },
                    titulos_detalle: {
                            "co_x" : "Compra 15d", "co_u" : "Ult. Compra","ec" : "Pdte. Servir","fse" : "Freq. Venta",
                            "p" : "Pdv","rc" : "Pdte. Recibir","use" : "Uds. Med. Semanal","sc" : "Uds Stock",
                            "vc" : "Valor Stock","vn_x" : "Venta Ltm","vn_u" : "Ult. venta", "wx" : "% Hoy", 
                            "wc" : "% Provision Efectivo Cierre"  , 
                        }, 
                    titulo_pag:"Gestor de Compras. Detalle Pedido " , 
                    clases: {'edited':'info', 'deleted':'warning', 'added':'success'}, 
                    paginador: false, 
                    expandedRows: [] ,
                    editingRows: [],
                    filas_actualizar: {},

                    /** Iconos, se han de declara en data para poder ser utilizados  */
                    closeCircle,                     
                    columnas :["codmar","artpro","nomart","unidad","precom","destot","impbru","n1","d_tam","v_m0","v_q1","v_q2","uped_cli","dias_pedcli","dias_cli","stoalm","recibir","servir","dispo","st_caldes","impuni","pmp","pmp_caldes"], 
                    tiempo: undefined,


                   /** Variable de control para actuar en mount/update */
                    ruta: undefined , 
                    fetchUrl : undefined, 
                    filas: undefined , 
                    /** Variable que controla el spinner de carga  */
                    loading: true,  
                    inicio: undefined, 
                    total_original : undefined, //total original del pedido 
                    nuevo_total: undefined, //total tras cambios 
                    

                    /** variable de control del debounce (diferimiento ) */
                    debounce: null,

                  
                    /**
                     * Variable donde estan los datos que efectivamente se ven en la tabla 
                     * una vez aplicados todos los filtros 
                     */
                    datos: undefined, 
                    cabecera: undefined, 
                                        
                    /**
                     * Variable donde estan los datos que efectivamente se ven en la tabla 
                     * una vez aplicados todos los filtros 
                     */
                    filtrados: undefined, 
                    largo: undefined, 

                    /** 
                     * Termino de busqueda que introducimos en el filtro libre 
                     */                    
                    termino: undefined, 

                    
                    
                    /** 
                     * Datos del dispositivo, son globales, controlamos los eventos de 'window' 
                     * para responder a los cambios de tamaño de ventana, orientacion...  
                     */                    
                    device: this.$device, 

                }
            },  
            async updated(){
                this.inicio = new Date().getTime()
                window.fake_console('GestorList','en el update 245')
                const route = useRoute();
                window.fake_console('GestorList',this.ruta, route.path)
                if (route.fullPath!=this.ruta)
                {
                    this.loading = true;
                    window.fake_console('GestorList','llamo al update ')
                    this.ruta = route.fullPath
                    this.procesoInicial()

                }         
                else {
                    window.fake_console('GestorList','NO llamo al update')
                }       
            },
            async mounted() {
                this.inicio = new Date().getTime()
                this.loading = true;
                window.fake_console('GestorList','en el mount 273')
                const route = useRoute();
                const {termino} = route.query
                window.fake_console(termino, route.query)
                this.termino = termino!=undefined ? termino : this.termino

                window.fake_console('GestorList',this.ruta, route.path, route.query)
                this.ruta = route.fullPath
                window.fake_console('GestorList',route.path, route.query)
                this.procesoInicial()

            }, 
            methods :{
                AnchoCol(x) { return `width: ${(this.device.ancho-32)*x/100}px !important`}, 
                
                async procesoInicial() {
                    this.loading = true;
                    this.device = this.$device 
                    eventBus().emitter.on('device', (sz) => {  this.device=sz })          
                    this.fetchData()
                }, 
                
                async fetchData(){ 
                    this.loading = true
                    const headUrl = `/buy/monitor-header/${this.codemp}/${this.codsuc}/${this.numped}`
                    const head = await this.$http.get(headUrl) 
                    this.cabecera = head.data 
                    const url = `/buy/monitor-detail/${this.codemp}/${this.codsuc}/${this.numped}`
                    window.fake_console(url)
                    window.fake_console('GestorList',url, this.fetchUrl)
                    if (url !=this.fetchUrl)
                    {
                        this.fetchUrl = url
                        const result = await this.$http.get(url) 
                        window.fake_console('GestorList','Es cache?? : ',result.request.fromCache)
                        await this.tratamientoDatos(result.data)
                    }
                    this.loading = false
                }, 

                async onRowExpand(e) {
                    window.fake_console(JSON.stringify(e.data))
                    const pdv = this.codemp
                    const detalle = await this.fetchSkuDetailData(pdv,e.data.codmar, e.data.artpro )
                    window.fake_console(detalle)
                    e.data.detalle = detalle
                    e.data.selected = true;
                    //alert(JSON.stringify(this.skuData))
                }, 
                onRowEditSave (event){
                    window.fake_console(event.data)
                    this.recalcular_linea(event.data)
                    let { newData, index } = event;
                    window.fake_console(newData, index)

                },

                async fetchSkuDetailData(pdv,marca,articulo){ 
                    try 
                        {                    
                            const url =`/sku_details?sku=${marca}.${articulo}`
                            window.miconsola('SkuDetails ',  url, this.fetchUrl)
                            const result = await this.$http.get(url) 
                            const data = result.data
                            
                            window.miconsola('L477 : ',data)
                            const target = ['000','012',pdv]
                            const final =  _.chain(data).filter( (v)=>{ return target.indexOf(v.p)!=-1 }).sortBy((y)=> {return target.indexOf(y.p)}).value()
                            const temp = _.chain(data).filter((v)=> {return target.indexOf(v.p)==-1}).sortBy((y)=>{ return y.sc==undefined ? 0 : -y.sc }).value().slice(0,3)
                            return _.union(final, temp)
                        }                
                    catch(ex) {
                        return false
                    }

                    }, 




                Validar_Linea(data) {
                    window.fake_console('SkuDetails ',  data)
                    return data.est_gest =='P' ? 'check_azul' :null
                }, 
             
                onRowUnselect(e) {
                    e.selected = false;
                },  
                async tratamientoDatos(result) {
                    window.fake_console(JSON.stringify(result).slice(0,100))
                    const d = result 
                    const hoy = new Date()
                    let before6M = new Date()
                    before6M = before6M.setMonth( before6M.getMonth() -6)
                    const DIAS_TAM = Math.ceil(hoy.getDate()+((hoy-before6M)/(1000 * 3600 * 24)))
                    this.total_original = _.pluck(d, 'impbru').reduce((m,v)=> {return m+=v}, 0)
                    this.datos = _.chain(d).sortBy('numlin').reduce( (m,e)=> {
                        window.fake_console(e)
                        e['dispo'] = e.stoalm-e.servir;
                        e['impuni'] = (e.implin / e.unidad).toFixed(2);
                        e['d_tam'] = (((e.unidad||0)+(e.dispo||0)) ==0 ? 0: ((e.v_m0||0)+(e.v_q1||0)+(e.v_q2||0))==0 ? Number.POSITIVE_INFINITY : ((e.unidad||0)+(e.dispo||0))/(((e.v_m0||0)+(e.v_q1||0)+(e.v_q2||0))/DIAS_TAM)).toFixed(2);
                        window.fake_console({before6M, DIAS_TAM,UNIDAD:e.unidad, DISP:e.dispo, VM0: e.v_m0, VMQ1: e.v_q1, VMQ2: e.v_q1 })
                        e['edit'] = false;
                        m.push(e);
                        return m 
                    }, []).value()     
                    this.tiempo = new Date().getTime() - this.inicio 
                        window.fake_console('test out')
                },
            
            async recalcular_linea(event){
                    let pd = event.data
                    window.fake_console(pd)
                    pd.implin = +(pd.unidad*(pd.precom/(pd.unipre))*(100-pd.destot)/100).toFixed(3)
                    pd.impcar = +(pd.precar == undefined ? 0 : (pd.unidad*pd.precar)).toFixed(3)
                    pd.impbru = +(pd.implin+(pd.unidad*(pd.precar== undefined ? 0 : pd.precar))).toFixed(3)
                    pd.action = 'edited'
                    window.fake_console(pd)
                    this.nuevo_total = _.pluck(this.datos, 'impbru').reduce((m,v)=> {return m+=v}, 0)
                    const clave = `${this.codemp}.${this.codsuc}.${this.numped}.${pd.numlin}`
                    const {codmar, artpro, unidad, precom, destot , implin, progra, estado, numlin} = pd
                    this.filas_actualizar[clave] = {codemp: this.codemp, codsuc: this.codsuc, numped:this.numped, codmar, artpro, unidad, precom, destot , implin, progra, estado, numlin}
                    window.fake_console(this.filas_actualizar)
                },    
            esGrupo(data) {
                    window.fake_console('SkuDetails ',  data)
                    return data.p==='000' ? 'resaltar' : null
                }, 
            goBack(){
                const tb = this.termino==undefined ? '' : `&termino=${this.termino}`
                this.$router.replace(`/gestor/list?q=${Date.now()}${tb}`)
            }, 
            async updateOrder(){
                const self = this
                const valores = Object.values(this.filas_actualizar)
                const ord = await this.$http.post(`/buy/monitor-update-detail/${this.codemp}/${this.codsuc}/${this.numped}`, {data: valores})
                const confirmacion = ord.data
                window.fake_console(ord)
                if (confirmacion.ok!=undefined) {
                        openAlerta('Actualizacion Correcta!', confirmacion.ok, ()=>{ self.goBack() } )
                    }
                    
                else {
                    openAlerta('Error en la actualización!', confirmacion.ko, ()=>{ self.goBack() })
                }
            }
            
            }, 
            
            
})
</script>



<style scoped>
    i.wait {
        color:var(--ion-color-primary) !important;
        float: right !important;
    }
    i.outlet {
        color:var(--ion-color-danger) !important; 
        float:right !important;  
    }
    .azul {
        color:var(--ion-color-primary) !important;
        }
    .indentado {
        padding-left: 20px !important;
    }
    .resaltado {
          background-color: var(--color-resaltado-negativo) !important;  
          border: dotted 1px var(--color-dark-danger) !important;
    }
    ion-row.selectores {
        border: 1px solid var(--ion-color-light);
    }
    ion-row.grande {
        margin-top: 12px! important; 
        margin-bottom: 12px! important; 
    }

    ion-button {
        margin: 2px !important;
        color:white !important;
    }
    /* color del texto del boton  */
    .ion-color-warning {
        --ion-color-contrast:white !important;
    }
    .derecha {
        padding-left: 6px !important;
        text-align: right !important;
        width: 100%;
    }
    .izquierda {
        padding-right: 6px !important;
        text-align: left !important;
        width: 100%;
    }
    .centro {
        text-align: center !important;
        width: 100%;
    }

    tfoot.p-datatable-tfoot {
        background-color: red !important;
    }
</style>