import { createRouter, createWebHistory } from '@ionic/vue-router';
import eventBus from "@/event-bus";

import GestorList from '@/pages/gestor/GestorList'
import GestorDetail from '@/pages/gestor/GestorDetail'
import GestorPlanificador from '@/pages/gestor/GestorPlanificador'

import TraspasosList from '@/pages/traspasos/TraspasosList'
import TraspasoDetail from '@/pages/traspasos/TraspasoDetail'

/* Elementos de control de stock y visibilidad*/ 
import PriceProtectionList from '@/pages/gestor/price_protection/PriceProtectionList'
import PriceProtectionListEditable from '@/pages/gestor/price_protection/PriceProtectionListEditable'
import RutasPdvsList from '@/pages/gestor/rutas_pdvs/RutasPdvsList'
import RutasPdvsListEditable from '@/pages/gestor/rutas_pdvs/RutasPdvsListEditable'

import DescuentosClientesList from '@/pages/pricing/descuentos_clientes/DescuentosClientesList'
import DescuentosClientesListEditable from '@/pages/pricing/descuentos_clientes/DescuentosClientesListEditable'
import DescuentosClientesListImportacion from '@/pages/pricing/descuentos_clientes/DescuentosClientesListImportacion'


import Login from '@/pages/Login.vue'

import firebase from 'firebase/app';
import 'firebase/auth';

const routes = [
  {
    path: '/',
    component: () => import ('@/pages/Login.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  
  /** GESTOR DE COMPRAS */
  {
    path: '/gestor/list',
    name: 'GestorList',
    component: GestorList, 
    props: true, 
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/traspasos/list',
    name: 'TraspasosList',
    component: TraspasosList, 
    props: true, 
    meta: {
      requiresAuth: true
    }
  },
  
  {
    path: '/gestor/detail/:codemp(\\d{3})/:codsuc(\\d{2})/:numped(\\d{1,7})',
    name: 'GestorDetail',
    component: GestorDetail, 
    props: true, 
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/traspasos/detail/:codemp(\\d{3})/:codsuc(\\d{2})/:numpet(\\d{1,7})',
    name: 'TraspasoDetail',
    component: TraspasoDetail, 
    props: true, 
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/gestor/planificador',
    name: 'GestorPlanificador',
    component: GestorPlanificador, 
    props: true, 
    meta: {
      requiresAuth: true
    }
  },

  /** Modulo Pricing  */
  /*{
    path: '/marcas-kg/list_editable/:codemp(\\d{3})/:codmar(.{3})',
    name: 'MarcasKgListEditable',
    component: MarcasKgListEditable, 
    props: true, 
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/marcas-kg/list/:codemp(\\d{3})/:codmar(.{3})',
    name: 'MarcasKgList',
    component: MarcasKgList, 
    props: true, 
    meta: {
      requiresAuth: true
    }
  },*/
  {
    path: '/descuentos-abc/list_editable/:codemp(\\d{3})/:codmar(.{3})',
    name: 'DescuentosClientesListEditable',
    component: DescuentosClientesListEditable, 
    props: true, 
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/descuentos-abc/list_import/:codemp(\\d{3})/:codmar(.{3})',
    name: 'DescuentosClientesListImportacion',
    component: DescuentosClientesListImportacion, 
    props: true, 
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/descuentos-abc/list/:codemp(\\d{3})/:codmar(.{3})',
    name: 'DescuentosClientesList',
    component: DescuentosClientesList, 
    props: true, 
    meta: {
      requiresAuth: true
    }
  },



  /** Control de Stocks  */
  {
    path: '/price-protection/list_editable',
    name: 'PriceProtectionListEditable',
    component: PriceProtectionListEditable, 
    props: true, 
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/price-protection/list',
    name: 'PriceProtection',
    component: PriceProtectionList, 
    props: true, 
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/rutas-pdvs/list_editable/:codemp(\\d{3})',
    name: 'RutasPdvsListEditable',
    component: RutasPdvsListEditable, 
    props: true, 
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/rutas-pdvs/list/:codemp(\\d{3})',
    name: 'RutasPdvsList',
    component: RutasPdvsList, 
    props: true, 
    meta: {
      requiresAuth: true
    }
  },
  /*{
    path: '/prohab-grupo/list/:codemp(\\d{3})/:codmar(.{3})',
    name: 'ProhabGrupoList',
    component: ProhabGrupoList, 
    props: true, 
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/prohab-grupo/list_editable/:codemp(\\d{3})/:codmar(.{3})',
    name: 'ProhabGrupoListEditable',
    component: ProhabGrupoListEditable, 
    props: true, 
    meta: {
      requiresAuth: true
    }
  },*/




  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

/** miramos en casda caso si estamos locados con algún usuario  */
router.beforeEach((to, from, next) => {
  window.fake_console(from,to,next)
  const currentUser = firebase.auth().currentUser;
  window.fake_console('index86 : en el router desde: '+from.path+' hacia : '+to.path) 
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  if (requiresAuth && !currentUser) {
    eventBus().emitter.emit('deeplink',to);
    window.fake_console('Redireccion a login, intento pagina y no hay credencial')
    next('/');
  } 
  else {

    next();
  }
});


export default router
