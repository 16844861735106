<template>
    <ion-col>
        <ion-card :key="contador">
            <ion-card-header v-if="titulo.length>0 && titulo!=undefined" class="head">
                <ion-card-subtitle size="small"
                    v-if="titulo.length>0 && titulo!=undefined">
                    <!-- {{titulo}}-->
                     <ion-row class=" mini-lista titulin">
                        <ion-col size="14" >Peticion</ion-col>
                        <ion-col size="20" >Usuario</ion-col>
                        <ion-col size="4" >Pdv</ion-col>
                        <ion-col size="10">Estado</ion-col>
                    </ion-row>
                </ion-card-subtitle>
     
            </ion-card-header>

            <ion-card-content  v-if="datos">
                <ion-row v-for="(item,index) in datos"  v-bind:key="index" class="bordecito" >
                        <ion-col size="14" class=" bordecito ion-text-nowrap  mini-lista nombre_cortado ion-text-start">{{ item.t }}</ion-col>
                        <ion-col size="20" class=" bordecito mini-lista ion-text-start pad peque"> {{ item.u }}</ion-col>
                        <ion-col size="4" class=" bordecito mini-lista ion-text-start peque"> {{ item.pdv }}</ion-col>

                        <ion-col v-if="item.st!='true'" size="10" class=" bordecito mini-lista ion-text-start pad peque"> {{ item.st== 'false' ? 'Pendiente' :  'En curso'  }}</ion-col>
                        <ion-col v-else size="10" class=" bordecito mini-lista ion-text-start pad peque" @click="descarga(item)" > 
                            <a href="javascript:void(0);">Disponible</a>
                        </ion-col>

                </ion-row>  
            </ion-card-content>

        </ion-card>
    </ion-col>
</template>
    

<script>
    import {    IonCol,IonRow,
                IonCard, IonCardHeader, 
                IonCardSubtitle,
                IonCardContent
            } from '@ionic/vue';

    import { defineComponent } from 'vue';

    export default defineComponent({
            name : "TarjetaListaPlanificaciones",
            components :  {    
                IonCol,IonCard, IonCardHeader, IonRow,
                IonCardSubtitle,
                IonCardContent 
            }, 
            mounted() {
                this.listaPendientes()
            }, 
            updated() {
                this.listaPendientes()
            }, 
            methods: {
                async listaPendientes(){
                var self = this
                    const pd = await self.$http.get('/buy/planificador')
                    self.datos = pd.data
                    self.contador = ++self.contador||0
                    setInterval(async function () {
                        const pd = await self.$http.get('/buy/planificador')
                        window.fake_console('Lista planificacion 68',self.datos)
                        self.datos = pd.data
                        self.contador = ++self.contador||0
                        window.fake_console('Lista planificacion 70',self.datos)
                    },5000)
                }, 
                async descarga(item){
                    const d_url = `/buy/planificador-xlsx/propuesta:${item.t}:${item.u}:${item.pdv}:true`
                    const response = await this.$http({url: d_url, method:'GET', responseType: 'blob'})
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `propuesta.${item.t}.${item.u}.${item.pdv}.xlsx`); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                    //alert('descargado...')
                    }
            },

            data() {
                return {
                    datos:undefined, 
                    contador : undefined 
                } 
                
            }, 
            props:['titulo']
    })
</script>

<style scoped>
        ion-col { padding: 2px;}
        ion-card {
            margin:2px  2px !important;
            border: none !important;
            box-shadow: none !important; 
 
        }
        .pad {
            padding-left: 6px !important; 
        }
        ion-card-content {
            padding-top: 4px;
            padding-bottom: 4px;
            padding-left: 2px; 
            padding-right: 2px;
            text-align: right; 
            font-size:smaller; 
        }
        
        ion-card-subtitle{
            text-align: center;
        }
        ion-card-header.head {
            padding-left: 2px;
            padding-right: 2px;
            padding-top: 4px ; 
            padding-bottom : 4px; 
            border-bottom: 2px solid var(--ion-color-light);
        }
        ion-card-header.foot {
            padding-left: 3px;
            padding-right: 2px;
            padding-top: 4px ; 
            padding-bottom : 4px; 
            border-top: 2px solid var(--ion-color-light);
        }

        ion-col.mini-lista{
            padding: 0px 4px;
        }
        .titulin {
            font-size: smaller; 
            text-transform: capitalize;
            padding-top: 6px !important; 
            padding-bottom: 6px !important; 

        }
</style>