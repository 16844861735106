import { toastController, alertController} from '@ionic/vue';

const dNumber = (n)=>{
    return n ===undefined ? 0 : n 
}     

const openToast= async function (head, msg) {
    const toast = await toastController
        .create({
        header: head,
        message: msg,
        duration: 2000,
        position: 'middle',
        buttons: [
            {
            side: 'end',
            text: 'Ok',
            handler: () => {
                window.fake_console('ok');
                }}
        ]
        })
    await toast.present();
    //const { role } = await toast.onDidDismiss();
    //window.fake_console('onDidDismiss resolved with role', role);
    };

    const openAlerta = async function(head, msg,callback) {
        const alerta = await alertController
            .create({
                header: head, 
                message: msg,
                mode : "md",
                buttons: [
                    {
                    text: 'Ok',
                    handler: () => { 
                        window.fake_console('en el handler')
                        callback()
                    },
                    },
                ]
            })
        return alerta.present(); 
    }

export {
 dNumber,   openToast, openAlerta
}

