<template>
   <ion-page :style="fontSize" >
        <CabeceraComponent :titulo="titulo_pag" color="primary"/>
        <ion-content class="ion-padding" v-if="device" >  
 
             <ion-row v-if="loading" class="spin ion-justify-content-center "  >
                <ion-col  v-if="loading" class="spin ion-align-self-center" size="12" > 
                    <ion-spinner color="primary" v-if="loading"></ion-spinner>
                </ion-col>
            </ion-row>


            <!-- CONFIGURACION ACTUAL -->             
            <ion-row class="ion-justify-content-left ion-align-items-top selectores grande" v-if="loading==false">
                    <ion-col  :size="device.tamanyo == 0 ? 48 : device.tamanyo==1 ? 18 : 24" >
                        <div class="p-input-icon-right mismo-que-paginador" >
                            <InputText type="text" v-model="termino" placeholder="Buscar (min 3 letras)" @input="debounceSearch"/>
                            <i class="pi pi-search" />
                        </div>
                        <div class="nota">
                            <ion-note v-if="disponibles"> {{disponibles}} registros de {{datos.length}} totales, viendo los {{filtrados.length}} primeros </ion-note>
                        </div>

                    </ion-col>
                    <ion-col  :size="device.tamanyo == 0 ? 48 : device.tamanyo==1 ? 30 : 24" >
                        <div class="p-input-icon-right mismo-que-paginador force-right" >
                            <wj-collection-view-navigator
                                class="navegador force-right"
                                headerFormat="Pagina {currentPage:n0} de {pageCount:n0}"
                                :byPage="true"
                                :cv="filtrados" />
                            <DropDown v-model="filas_paginas" :options="paginador"   class="selector_paginas cabecera force-right"
                                v-bind:key="'selector_filas'"  
                                @hide="tratamientoDatos"
                                placeholder="Filas " /> 
                        </div>
                    </ion-col>
            </ion-row>
            <ion-row class="ion-justify-content-left ion-align-items-top grande" v-if="loading==false">
                    <ion-toolbar> 
                        <DropDown v-model="selector.pdv" :options="selectores.pdv" optionLabel="n" class="selector_kg"
                            :filter="true"   v-bind:key="'selector_pdv'"  
                            @hide="Go(selector.pdv._id,'999')"
                            placeholder="Pdvs activos " />
                        <ion-buttons slot="end" >
                            <ion-button color="primary"  fill="solid" @click="NuevaCombinacion">
                                <b>Editar ...</b>
                            </ion-button>  
                        </ion-buttons>
                    </ion-toolbar>
            
            </ion-row>
            <ion-row v-if="!loading">
                <wj-flex-grid 
                    :showMarquee="true"
                    :anchorCursor="true"                    
                    :showSelectedHeaders="'All'"
                    :itemsSource="filtrados" 
                    :alternatingRowStep="0"  
                    :headersVisibility="'Column'"
                    :selectionMode="'None'"
                    ref="theGrid" v-if="datos">
                        <wj-flex-grid-column v-for="(c,index) in columnas"   
                            :key="'c'+index" :header="c.header" :binding="c.col" 
                            :width="c.width" 
                            :align="c.align == undefined ? '': c.align"
                            :isReadOnly ="true"
                            cssClass="no-bloqueada-list"
                            :format="c.format==undefined ? '' : c.format" 
                            />
                        <wj-flex-grid-column
                            key="c_at" header="" 
                            width="0.8*" 
                            align="center"
                            :isReadOnly ="true"
                            cssClass="bloqueada del"
                            :cellTemplate="tplCustomButton"
                            >
                        </wj-flex-grid-column>
                </wj-flex-grid>
                </ion-row>    
        </ion-content>
        


    </ion-page>
</template>

<script>

    import { IonPage, IonContent,   
            IonRow,IonCol,  
            IonToolbar, IonButtons, IonButton, IonNote, 
            IonSpinner, modalController
     } from '@ionic/vue';
    import CabeceraComponent from '@/components/CabeceraComponent'
    import SelectorPdvModal  from  '@/components/SelectorPdvModal'
    import { CellMaker } from '@grapecity/wijmo.grid.cellmaker';
    import { CollectionView } from '@grapecity/wijmo';
    import { defineComponent } from 'vue';
    import { useRoute } from 'vue-router';

    import eventBus from "@/event-bus";
    export default defineComponent({
            name : "RutasPdvsList",
            components: { 
                IonPage, IonContent,    
                IonToolbar, IonButtons, IonButton, IonNote, 
                IonRow,IonCol, IonSpinner,  
                CabeceraComponent  
            } , 
            props : ['codemp'], 
            computed :{
                fontSize () { 
                    window.fake_console('RutasPdvsList',`--texto-size : ${9+(this.device.tamanyo)}px`)
                    return `--texto-size : ${9+(this.device.tamanyo)}px;`
                }, 

                columnas() {
                    const cols = [
                        { "col": "pdvorig" , "header": "Origen",   "width": "1.5*", "align": "center","show" : true },  
                        { "col": "pdvdest" , "header": "Destino", "width": "1.5*","align": "center", "show" : true  },  
                        { "col": "rutaid" , "header": "Ruta",   "width": "2*","align": "left", "show" : true },  
                        { "col": "activo" , "header": "Activo",  "width": "1.5*","align": "center", "show" : true },  
                        { "col": "activo_ish" , "header": "Activo ISH",  "width": "1.5*", "align": "center","show" : true },  
                        { "col": "l" , "header": "L", "format" : "n0", "width": "*","align": "center", "show" : true },  
                        { "col": "m" , "header": "M",  "format" : "n0",  "width": "*", "align": "center", "show" : true },  
                        { "col": "x" , "header": "X", "format" : "n0", "width": "*", "align": "center","show" : true },  
                        { "col": "j" , "header": "J ", "format" : "n0", "width": "*","align": "center", "show" : true },  
                        { "col": "v" , "header": "V ", "format" : "n0", "width": "*","align": "center", "show" : true },  
                        { "col": "km" , "header": "Km. ", "format" : "n0", "width": "1.4*","align": "right", "show" : true },  
                        { "col": "sale" , "header": "Salida ",  "width": "*","align": "center", "show" : true },  
                        { "col": "llega" , "header": "Llegada ",  "width": "*","align": "center", "show" : true },  
                        { "col": "last_update" , "header": "Ult.Cambio ",   "width": "2*" , "align": "center", "show" : this.device.tamanyo>1 },
                        { "col": "usr" , "header": "Usuario ",   "width": "3*", "align": "left", "show" : this.device.tamanyo>2 },
                        ]
                    return cols.filter(x=>x.show) 
                }

            },
            data() {    
                return {
                    titulo_pag:"Conexiones activas entre pdvs " , 
                    filas_paginas : 30, 
                    paginador : [15,30,50,100],
                    selector : {}, 
                    selectores : undefined, 

                    /** Variable que controla el spinner de carga  */
                    loading: true, 
                    
                    /** Boton para el grid de wijmo */
                    tplCustomButton: CellMaker.makeButton({
                        text: '<i class="fa fa-pencil"></i>',
                        click: (e, ctx) => this.$router.replace(`/rutas-pdvs/list_editable/${ctx.item.pdvdest}`) 
                        
                    }),

                   /** Variable de control para actuar en mount/update */
                    ruta: undefined , 
                    fetchUrl : undefined, 

                    /** variable de control del debounce (diferimiento ) */
                    debounce: null,

                    /**
                     * Variable donde estan los datos que efectivamente se ven en la tabla 
                     * una vez aplicados todos los filtros 
                     */
                    datos: undefined, 
                                        
                    /**
                     * Variable donde estan los datos que efectivamente se ven en la tabla 
                     * una vez aplicados todos los filtros 
                     */
                    filtrados: undefined, 
                    disponibles : undefined, 

                    /** 
                     * Termino de busqueda que introducimos en el filtro libre 
                     */                    
                    termino: undefined, 

                    /** 
                     * Datos del dispositivo, son globales, controlamos los eventos de 'window' 
                     * para responder a los cambios de tamaño de ventana, orientacion...  
                     */                    
                    device: this.$device, 

                }
            },  
            async updated(){
                const route = useRoute();
                window.fake_console('RutasPdvsList',this.ruta, route.path)
                if (route.fullPath!=this.ruta)
                {
                    this.loading = true;
                    window.fake_console('RutasPdvsList','llamo al update ')
                    this.ruta = route.fullPath
                    await this.procesoInicial()
                }         
                else {
                    window.fake_console('RutasPdvsList','NO llamo al update')
                }       
            },
            async mounted() {
                const route = useRoute();
                this.ruta = route.fullPath
                window.fake_console('RutasPdvsList',route.path, route.query)
                await this.procesoInicial()
            }, 
            methods :{
                async procesoInicial() {
                    /** fallback por si viene la combinación más generica de todas  */
                    if (this.codemp =='000') {
                        this.$router.replace(`/rutas-pdvs/list/000?q=${Date.now()}`)
                    }
                    this.loading = true;
                    this.device = this.$device 
                    eventBus().emitter.on('device', (sz) => {  this.device=sz })    
                    this.fetchData()
                }, 
                
                async fetchData(){ 
                    this.loading = true
                    const select_url = `/buy/rutas-select`
                    const url =`/buy/rutas-actuales/${this.codemp}`
                    window.fake_console('RutasPdvsList',url, this.fetchUrl)
                    if (url !=this.fetchUrl)
                    {
                        const selc = await this.$http.get(select_url)
                        this.selectores = selc.data
                        this.selector['pdv'] = this.selectores['pdv'].filter(v=>v._id===this.codemp)[0]
                        this.fetchUrl = url
                        const result = await this.$http.get(url) 
                        window.fake_console('RutasPdvsList','Es cache?? : ',result.request.fromCache)
                        this.datos = result.data.data
                        await this.tratamientoDatos( )
                    }
                    this.loading = false
                }, 
                debounceSearch() {
                    clearTimeout(this.debounce)
                    this.debounce = setTimeout(() => { this.tratamientoDatos()}, 250)
                },
              async tratamientoDatos() {
                    let filtrados = this.datos
                    if (this.termino!=undefined && this.termino.length>=2){
                        this.termino.toLowerCase().split(' ').forEach(t=>
                            filtrados = filtrados.filter((v)=> {return JSON.stringify(v).toLowerCase().indexOf(t)!=-1})
                        )
                    }
                    this.disponibles = filtrados.length
                    this.filtrados =  new CollectionView(filtrados, { pageSize: this.filas_paginas});
                }, 
                async Go(codemp) {
                    //const pdv = (codemp =='000' ) ? '012' : codemp 
                    this.$router.replace(`/rutas-pdvs/list/${codemp}?q=${Date.now()}`)
                 }, 
                async NuevaCombinacion () {
                    var self = this
                    const modal = await modalController
                        .create({
                                component: SelectorPdvModal,
                                cssClass: 'custom-modal', 
                                componentProps: { titulo: 'Seleccion Pdv' },
                                })
                    eventBus().emitter.on('cerrar-selector-pdv',async (seleccion)=>{
                        modal.dismiss();
                        if (seleccion!=undefined) {  
                            window.fake_console(seleccion)
                            window.fake_console('en la condicion de cambio')
                            self.$router.replace(`/rutas-pdvs/list_editable/${seleccion.pdv}`) 
                            
                        }    
                    });
                    modal.present();
                }, 
            }
            
})
</script>


<style scoped lang="scss">
     @import '@/theme/listas.scss';
</style>
