<template>
   <ion-page :style="fontSize" >
        <CabeceraComponent :titulo="titulo_pag" color="primary"/>
        <ion-content class="ion-padding ion-margin" >  
            <ion-row>
                <ion-col  size="48" size-sm="24" >                    
                    <div v-if="filtros && seleccion.pdv">
                    <ion-row class="ion-justify-content-end" style="margin: 16px">
                        <ion-col size="24" size-sm="12">
                            <ion-button class ="derecha" color="danger" @click="Borrar" v-if="seleccion.pdv" >Borrar</ion-button>
                        </ion-col>
                        <ion-col size="24" size-sm="12">
                            <ion-button class="izquierda" color="primary" @click="Solicitar" 
                                :disabled="!(seleccion.pdv && seleccion.pdvs && seleccion.marcas && seleccion.dmin && seleccion.dmed && seleccion.fent)" >Solicitar</ion-button>
                        </ion-col>
                    </ion-row>
                    </div>
                    <!-- DIAS MEDIOS, MINIMOS Y CORTE PEDIDOS-->
                    <div class="elegir" v-if="filtros && seleccion.pdv && seleccion.pdvs && seleccion.marcas">

                        <ion-toolbar>
                            <ion-title class="completo">Dias y corte de planificacion</ion-title>
                        </ion-toolbar> 
                        <ion-row class="ion-justify-content-left ion-align-items-top ion-margin-top" >
                            <ion-col size="48" size-sm="16" v-bind:key="device.tipo+'dmed'">
                                <!-- placeholder="Dias Medios" -->
                                <span class="p-float-label">
                                        <InputNumber v-model="seleccion.dmed"                                     
                                            :min="20" id="dmed"  />
                                        <label for="dmed">Dias Medios</label>
                                    </span>
                                        <p style="padding:4px !important">
                                            Dias medios que quieres planificar.<br/>
                                            Un mes equivale a 20 días.
                                        </p>                    
                            </ion-col>
                            <ion-col size="48" size-sm="16" v-bind:key="device.tipo+'dmin'">
                                    <span class="p-float-label">
                                        <InputNumber v-model="seleccion.dmin" 
                                            :min="8" id="dmin"  />
                                        <label for="dmin">Dias Minimo</label>
                                    </span>
                                    <p style="padding:4px !important">
                                            Dias minimos a considerar. Por defecto se consideran 8 días (laborables).<br/> 
                                            Se tomarán las referencias con cobertura inferior a los días marcados.
                                    </p>

                            </ion-col>
                            <ion-col size="48" size-sm="16" v-bind:key="device.tipo+'fent'">
                                    <span class="p-float-label">
                                        <Calendar v-model="fent" 
                                            dateFormat="dd.mm.yy" 
                                            @dateSelect="asignarFecha()"
                                            :numberOfMonths="2" 
                                            :touchUI="true"
                                            id="fent"  />
                                        <label for="fent">Fecha de Corte</label>
                                    </span>
                                    <p style="padding:4px !important">    
                                        Fecha para incluir pedidos a proveedores pendientes de recepión.<br/>
                                        Se considerarán solo los pedidos con entrega planificada antes de esa fecha
                                        aumentados en 9 días. <br/>
                                        Por defecto se toma el día actual 
                                    
                                    </p>

                            </ion-col>
                        </ion-row>
                    </div>  
                    
                    <!-- MARCAS A PLANIFICAR-->
                    <div class="elegir" v-if="filtros && seleccion.pdv && seleccion.pdvs">
                        <ion-toolbar>
                            <ion-title class="completo">Marcas</ion-title>
                        </ion-toolbar> 
                        <ion-row class="ion-justify-content-left ion-align-items-top " >
                            <ion-col size="48" size-sm="24" v-bind:key="device.tipo+'_marcasa'">
                            
                                <MultiSelect  v-model="seleccion.marcas" :options="filtros['marcas']" optionLabel="k" :optionValue="(e)=>e.v[0]"
                                    class="selector_multi"
                                    :filter="true"   
                                    placeholder="Añadir Marcas" display="comma"
                                    />
                                

                            </ion-col>
                            <ion-col size="48" size-sm="24" v-bind:key="device.tipo+'_marcasb'">
                                    <ion-badge mode="ios" 
                                        v-for="(zn,index) in seleccion.marcas" 
                                        color="primary" v-bind:key="index"
                                        @click="eliminar('marcas',index)">
                                        {{zn}}
                                        <ion-icon :icon="closeCircle" class="badge"></ion-icon>
                                    </ion-badge>
                            </ion-col>
                        </ion-row>
                    </div>  
                
                    <!-- PUNTOS DE VENTA CONSUMIDORES   -->
                    <div class="elegir" v-if="filtros && seleccion.pdv">
                        <ion-toolbar>
                            <ion-title class="completo">Puntos de Venta consumidores</ion-title>
                        </ion-toolbar> 
                        <ion-row class="ion-justify-content-left ion-align-items-top " >
                            <ion-col size="48" size-sm="24" v-bind:key="device.tipo+'_pdvsa'">
                            
                                <MultiSelect  v-model="seleccion.pdvs" :options="filtros['pdvs']" optionLabel="k" :optionValue="(e)=>e.k.slice(0,3)"
                                    class="selector_multi"
                                    :filter="true"   
                                    appenTo="etiquetas_ocultas" 
                                    placeholder="Pdvs individuales" display="comma"
                                    />
                                <DropDown v-model="zonas" :options="filtros['zonas']" optionLabel="k" class="selector_multi "
                                    :filter="true"  v-bind:key="device.tipo+'_pdvsb'"  
                                    @change="AddZone()"
                                    placeholder="Añadir todos los pdvs de una zona" />
                                

                            </ion-col>
                            <ion-col size="48" size-sm="24" v-bind:key="device.tipo+'_pdvs2'">
                                    <ion-badge mode="ios" 
                                        v-for="(zn,index) in seleccion.pdvs" 
                                        color="primary" v-bind:key="index"
                                        @click="eliminar('pdvs',index)">
                                        {{zn}}
                                        <ion-icon :icon="closeCircle" class="badge"></ion-icon>
                                    </ion-badge>
                            </ion-col>
                        </ion-row>
                    </div>

                    <!-- PUNTO DE VENTA A PLANIFICAR-->
                    <div class="elegir">
                        <ion-toolbar>
                            <ion-title class="completo">Punto de Venta que compra</ion-title>
                        </ion-toolbar> 
                        <ion-row class="ion-justify-content-left ion-align-items-top selectores" v-if="filtro_pdv">
                            <ion-col size="48" size-sm="24" v-bind:key="device.tipo+'_pdva'">
                                    <DropDown v-model="seleccion.pdv" :options="filtro_pdv" optionLabel="k" class="selector"
                                        :optionValue="(e)=>e.k.slice(0,3)"
                                        :filter="true"  v-bind:key="device.tipo+'_pdvc'"  
                                        :placeholder="'Pdv comprador '" />
                                    
                            </ion-col>

                            <ion-col size="48" size-sm="24" v-bind:key="device.tipo+'_pdvb'">
                                <div v-if="seleccion.pdv">
                                    <ion-badge mode="ios" 
                                        color="primary"
                                        @click="eliminar('pdv',index)">
                                        {{seleccion.pdv}}
                                        <ion-icon :icon="closeCircle" class="badge"></ion-icon>
                                    </ion-badge>
                                </div>
                            </ion-col>
                        </ion-row>
                    </div>
                   
                    <!-- PETICIONES PENDIENTES-->
                    <div class="elegir">
                        <ion-toolbar>
                            <ion-title class="completo">Peticiones Pendientes</ion-title>
                        </ion-toolbar> 
                        <TarjetaListaPlanificaciones  titulo="Peticiones Pendientes"/>
                    </div>
                    



                    
                </ion-col>
            </ion-row>
        </ion-content>

    </ion-page>
</template>

<script>
    import { IonPage, IonContent,   IonRow,IonCol, IonBadge, 
            IonIcon,IonTitle,IonToolbar, IonButton,
            alertController
             
     } from '@ionic/vue';
    import CabeceraComponent from '@/components/CabeceraComponent';
    import TarjetaListaPlanificaciones from '@/components/gestor/TarjetaListaPlanificaciones'

    import { defineComponent } from 'vue';
    import {union } from 'underscore';
    import eventBus from "@/event-bus";
    import { Plugins } from '@capacitor/core';
    const { Storage } = Plugins;
    
    import { closeCircle, storefront } from 'ionicons/icons';
    
    export default defineComponent({
            name : "GestorPlanificador",
            components: { 
                IonPage, IonContent, IonRow,IonCol,  IonBadge, IonIcon,
                IonTitle,IonToolbar, IonButton, 
                CabeceraComponent,TarjetaListaPlanificaciones
                  
            } , 
            computed :{
                fontSize () { 
                    window.miconsola('StocksList',`--texto-size : ${12+(this.device.tamanyo)}px`)
                    return `--texto-size : ${12+(this.device.tamanyo)}px;`
                }

            },
           
            data() {    
                return {
                    /** Iconos, se han de declara en data para poder ser utilizados  */
                    closeCircle, storefront, 
                    titulo_pag: "Propuesta de planificacion", 

                    /** Variable con los filtros para elegir */
                    filtros: {},
                    filtro_pdv: undefined, 
                    zonas: undefined, 
                    /** Seleccion*/
                    fent : undefined, 
                    seleccion : {
                        pdv: undefined, 
                        pdvs : undefined , 
                        marcas: undefined, 
                        fent: undefined, 
                        dmin: 8, 
                        dmed : 20 

                    },
                    pendientes: undefined, 

                    /** Variable que controla el spinner de carga  */
                    loading: true, 
                    /** variable de control del debounce (diferimiento ) */
                    debounce: null,
                    /** 
                     * Datos del dispositivo, son globales, controlamos los eventos de 'window' 
                     * para responder a los cambios de tamaño de ventana, orientacion...  
                     */                    
                    device: this.$device
                } 

            },  
            async mounted() {
                this.loading = true;
                window.miconsola('StocksList','en el mount 273')
                await this.procesoInicial()
                this.loading = false;

            }, 

            methods :{
                async AddZone(){
                    window.fake_console('GestorPlanificador',this.zonas)
                    const z = this.zonas['v']
                    window.fake_console('GestorPlanificador',z)  
                    window.fake_console('GestorPlanificador',this.seleccion.pdvs)
                    const nueva= union(this.seleccion.pdvs||[],z)
                    window.fake_console('GestorPlanificador',nueva)
                    this.seleccion.pdvs = nueva
                    this.zonas = undefined
                },
                async procesoInicial() {
                    this.loading = true;
                    this.fent = new Date(new Date().getTime()+(9*24*60*60*1000));
                    this.asignarFecha()
                    const fl =  await Storage.get({ key: 'stocks:filtros' });
                    window.fake_console('StocksList',fl)
                    this.filtros = JSON.parse(fl.value) 
                    this.filtro_pdv = union([],this.filtros['pdvs'])
                    window.fake_console('StocksList',Date.now() +'filtros recuperados')
                    this.device = this.$device 
                    eventBus().emitter.on('device', (sz) => {  this.device=sz })          
                    /** este es el bloque de codigo que debe ejecutarse tanto si inicializamos como si actualizamos el componente  */
                    window.fake_console('GestorPlanificador',this.filtros)
                },
                debounceSearch() {
                    clearTimeout(this.debounce)
                    this.debounce = setTimeout(() => { window.fake_console('GestorPlanificador','DEBOUNCE.....')}, 250)
                }, 
                eliminar(s,i){
                    this.seleccion[s].splice(i,1)  
                },
                async Borrar(){
                    this.seleccion = {
                        pdv: undefined, 
                        pdvs : undefined , 
                        marcas: undefined, 
                        fent: undefined, 
                        dmin: 8, 
                        dmed : 20 
                    }
                    this.fent = new Date(new Date().getTime()+(9*24*60*60*1000));
                    this.asignarFecha()
                }, 
                asignarFecha(){
                    try {
                        window.fake_console('GestorPlanificador',this.fent)
                        this.seleccion.fent = parseInt(this.fent.toISOString().slice(0,10).replace(/-/g,''))
                    }
                    catch(e){
                        this.selection.fent = undefined
                    }
                }, 
                
                async Solicitar() {
                    window.fake_console('GestorPlanificador',this.seleccion)
                    const post = await this.$http.post('/buy/planificador',{'data': this.seleccion })
                    if (post.data.status ===false ) {
                        alert('Algo no hay ido bien, contacta con ATI...')
                    }
                    else {
                     this.confirmacion()
                    }

                }, 
  
            async confirmacion () {
                 var self = this;
                 const alerta = await alertController
                        .create({
                            header: 'Confirmacion', 
                            message: 'Estamos procesando tu petición de planificación. En breve la recibirás por correo ',
                            mode : "md",
                            buttons: [
                                {
                                text: 'Si',
                                handler: async () => { self.Borrar()},
                                },
                            ]
                        })
                    return alerta.present(); 

                }, 

    
        }
                })
</script>

<style scoped>    
    ion-toolbar {
        --min-height: 36px;
    }
    div.crear, div.elegir, ion-row.elegir {
        padding-left: 8px;
        padding-right: 8px;
        padding-bottom: 8px;
        padding-top: 0px;
        border: 1px solid var(--ion-color-light);
        margin-top:8px;
    }
    ion-title.completo {
        padding-top: 8px !important;
        padding-left: 16px !important; 
        padding-right: 16px !important;
        width: 100% !important; 
        text-align: left;
    }
    ion-row.spin{
        min-height: 300px;
    }
    spin {
        height: 75%
    }
    ion-spinner {
        width:75px; 
        height:75px;
        display: block;
        margin: auto;
           
    }
    ion-segment-button > ion-label {
        color : var(--ion-color-light) !important;
    }

    ion-badge {
        margin-top: 6px; 
        margin-right:6px; 
        padding-top: 0px;
        padding-right: 12px; 
        padding-left: 12px; 
        padding-bottom: 4px; 
    }
    ion-icon.badge {
        padding-top: 4px; 
        font-size:1.25rem; 
    }
    
    ion-row.selectores {
        /*border: 1px solid var(--ion-color-light);*/
        margin: 8px !important;
    }
    .derecha {
        text-align: right !important;
        width: 100%;
    }
    .izquierda {
        text-align: left !important;
        width: 100%;
    }
    ion-button{
        width: 90%; 
        height: 3rem;
        --border-radius: 6px;

    }

</style>


/** Todos los estilos relacionados con PrimeVue Datatable los declaramos como globales, 
    sino no  funcionan */
<style lang="scss" >
    
    div.p-multisdelect.selector_multi, 
    div.p-dropdown.selector_multi
     {
        width:100% !important;
        padding-top: 3.5px !important; 
        padding-bottom: 2.5px !important; 
        margin-top: 8px !important;
        margin-bottom: 8px !important;
        max-height: 39px !important; 
        background-color: var(--ion-color-light);
        font-weight : bold !important;
        color: var(--ion-color-danger) !important;
    }
    .referencia_sku {
       width:120px !important;
    }

    div .p-dropdown { max-height: 32px !important; }
    div .p-dropdown> span.p-dropdown-label { padding-top : 7px; padding-bottom: 8px; }
    
    div .p-column-title {
        white-space: pre-wrap;
        text-align: center !important;
    }
    .p-multiselect{
        width:100%;
    }
    div.p-dropdown.selector {
        width:100% !important;
        padding-top: 3.5px !important; 
        padding-bottom: 2.5px !important; 
        max-height: 39px !important; 
        background-color: var(--ion-color-light);
        font-weight : bold !important;
        color: var(--ion-color-danger) !important;
    }

    
    div.p-input-icon-right{
        width:100%  !important;
    }
    input.p-inputtext.p-component{
        width:100% !important;
    }
    Button.p-button-rounded{
        color: var(--ion-color-darksuccess);
        font-size: 1.75rem;
        font-weight: bold;
    }
      
    tr.resaltar_sku {
        background-color: var(--color-resaltado-medio) !important;  
    }
</style>