<template> 

      <ion-menu content-id="main-content" type="overlay" mode="md" v-bind:key="ges">
        <ion-content>
          <ion-list id="inbox-list">

            <ion-list-header lines="none" class="sin-borde">Gestion de Stocks </ion-list-header>
            <ion-item @click="logout()">
                  <ion-label class="nombre">{{who}}</ion-label>
                  <ion-button color="darkdanger" slot="end">Salir</ion-button>
            </ion-item>

              <ion-item class="nombre-secundario" lines="none">
                  <ion-label class="nombre-secundario">
                    Conectado como: {{usr_name}}
                  </ion-label>
              </ion-item>
            
            <ion-menu-toggle auto-hide="true" key="next_weg_summary">
            <ion-item   router-direction="root" 
                router-link="/cambio_pdv" lines="none" detail="false" class="hydrated cambios" > 
              <div class="cambios">
                <ion-button size="default" class="cambios" color="secondary">
                    <ion-icon :md="people" class="cambios"> </ion-icon>
                    Cambio PDV
                </ion-button>
              </div>
            </ion-item>
            </ion-menu-toggle>

            


            <!-- SUFFICIENCY WURTH HOY -->
           
            <!-- <ion-menu-toggle auto-hide="false" key="next_weg_summary">
                <ion-item @click="selectedIndex = 'next_weg_summary'" router-direction="root" 
                  router-link="/resumen/next_weg" detail="false" class="hydrated" 
                  lines="none"
                  :class="{ selected: selectedIndex === 'next_weg_summary'}"
                  id="bloque"
                  v-if="false"
                  >
                    <ion-icon  md="star" color="danger" class="icono-titulo"></ion-icon>
                    <ion-label class="elemento-menu" color="danger">Resumen simulacion Wurth </ion-label>
                </ion-item>
              </ion-menu-toggle> -->





            <!-- SUFFICIENCY DE LOS PDVS -->
            <!-- <div class="contenedor-menu" @click="pdvs_detail = !pdvs_detail">
               <ion-toolbar  color="default" class="block-title">
                <ion-title class="ion-text-start subtitulo">
                  <ion-icon :md="pdvs_detail ? caretDownOutline : caretForwardOutline" class="titulo"></ion-icon> 
                   Sufficiency Pdvs
                </ion-title>
              </ion-toolbar>

              <ion-menu-toggle auto-hide="false" v-for="(p, i) in PaginasSufficiency" :key="i">
                <ion-item @click="selectedIndex = p.clave" router-direction="root" 
                  :router-link="p.url" detail="false" class="hydrated" 
                  :lines="i === (PaginasSufficiency.length-1) ? 'none':'full' "
                  :class="{ selected: selectedIndex === p.clave}"
                  id="bloque"
                  v-if="pdvs_detail"
                  >
                    <ion-icon :md="p.mdIcon" class="icono-titulo" :color="p.destacar ==true ? 'darkdanger' : ''" v-if="p.mdIcon"></ion-icon>
                    <ion-icon  :class="'icono-titulo '+p.name" :color="p.destacar ==true ? 'darkdanger' : ''" v-if="p.name"></ion-icon>
                    <ion-label class="elemento-menu" :color="p.destacar ==true ? 'darkdanger' : ''">{{ p.title }}</ion-label>
                </ion-item>
              </ion-menu-toggle>
            </div> -->

 
 
            <!-- SUFFICIENCY Wurth -->
            <!-- <div class="contenedor-menu" @click="wurth_detail = !wurth_detail" v-if="$ges">
               <ion-toolbar  color="default" class="block-title">
                <ion-title class="ion-text-start subtitulo">
                <ion-icon :md="wurth_detail ? caretDownOutline : caretForwardOutline" class="titulo"></ion-icon> 
                  Sufficiency Wurth</ion-title>
              </ion-toolbar>

              <ion-menu-toggle auto-hide="false" v-for="(p, i) in PaginasWeg" :key="i">
                <ion-item @click="selectedIndex = p.clave" router-direction="root" 
                  :router-link="p.url" detail="false" class="hydrated" 
                  :lines="i === (PaginasWeg.length-1) ? 'none':'full' "
                  :class="{ selected: selectedIndex === p.clave}"
                  id="bloque"
                  v-if="wurth_detail"
                  >
                    <ion-icon :md="p.mdIcon" class="icono-titulo" :color="p.destacar ==true ? 'darkdanger' : ''" >
                    </ion-icon>
                    <ion-label class="elemento-menu" :color="p.destacar ==true ? 'darkdanger' : ''">{{ p.title }}</ion-label>
                </ion-item>
              </ion-menu-toggle>
            
            </div> -->

            <!-- GESTION DE STOCKS-->
             <!-- <div class="contenedor-menu"  @click="stock_detail = !stock_detail">
               <ion-toolbar  color="default" class="block-title">
                <ion-title class="ion-text-start subtitulo">
                  <ion-icon :md="stock_detail ? caretDownOutline : caretForwardOutline" class="titulo"></ion-icon> 
                  Seguimiento de Stocks
                  </ion-title>
              </ion-toolbar>

              <ion-menu-toggle auto-hide="false" v-for="(p, i) in PaginasStock" :key="i">
                <ion-item @click="selectedIndex = p.clave" router-direction="root" 
                  :router-link="p.url" detail="false" class="hydrated" 
                  :lines="i === (PaginasStock.length-1) ? 'none':'full' "
                  :class="{ selected: selectedIndex === p.clave}"
                  id="bloque"
                  v-if="stock_detail && (p.clave != 'stock_list_cash_carry' ? true : mispdvs )"
                  >
                    <ion-icon  :md="p.mdIcon" class="icono-titulo"></ion-icon>
                    <ion-label class="elemento-menu" :color="p.destacar ==true ? 'darkdanger' : ''">{{ p.title }}</ion-label>
                </ion-item>
              </ion-menu-toggle>
              </div> -->
            
            <!-- REGULARIZACIONES DE INVENTARIO -->
             <!-- <div class="contenedor-menu"  @click="ajustes_detail = !ajustes_detail">
               <ion-toolbar  color="default" class="block-title">
                <ion-title class="ion-text-start subtitulo">
                  <ion-icon :md="ajustes_detail ? caretDownOutline : caretForwardOutline" class="titulo"></ion-icon> 
                  Control regularizaciones
                  </ion-title>
              </ion-toolbar>

              <ion-menu-toggle auto-hide="false" v-for="(p, i) in PaginasAjustes" :key="i">
                <ion-item @click="selectedIndex = p.clave" router-direction="root" 
                  :router-link="p.url" detail="false" class="hydrated" 
                  :lines="i === (PaginasAjustes.length-1) ? 'none':'full' "
                  :class="{ selected: selectedIndex === p.clave}"
                  id="bloque"
                  v-if="ajustes_detail"
                  >
                    <ion-icon  :md="p.mdIcon" class="icono-titulo"></ion-icon>
                    <ion-label class="elemento-menu">{{ p.title }}</ion-label>
                </ion-item>
              </ion-menu-toggle>
              </div> -->

            <!-- ANALISIS DE ROTACIONES-->
             <!-- <div class="contenedor-menu" v-if="true">
               <ion-toolbar  color="default" class="block-title">
                <ion-title class="ion-text-start subtitulo">Analisis</ion-title>
              </ion-toolbar>

              <ion-menu-toggle auto-hide="false" v-for="(p, i) in PaginasAnalisis" :key="i">
                <ion-item @click="selectedIndex = p.clave" router-direction="root" 
                  :router-link="p.url" detail="false" class="hydrated" 
                  :lines="i === (PaginasAnalisis.length-1) ? 'none':'full' "
                  :class="{ selected: selectedIndex === p.clave}"
                  id="bloque"
                  >
                    <ion-icon  :md="p.mdIcon" class="icono-titulo" v-if="p.mdIcon"></ion-icon>
                    <ion-icon  :class="'icono-titulo '+p.name " v-if="p.name"></ion-icon>
                    <ion-label class="elemento-menu">{{ p.title }}</ion-label>
                </ion-item>
              </ion-menu-toggle>
              </div> -->
            


            <!-- GESTOR DE COMPRAS -->
             <div class="contenedor-menu" v-if="$compras"  @click="gestor_detail = !gestor_detail">
               <ion-toolbar  color="default" class="block-title">
                <ion-title class="ion-text-start subtitulo">
                  <ion-icon :md="gestor_detail ? caretDownOutline : caretForwardOutline" class="titulo"></ion-icon> 
                  Gestor de Compras
                </ion-title>
              </ion-toolbar>

              <ion-menu-toggle auto-hide="false" v-for="(p, i) in PaginasGestor" :key="i">
                <ion-item @click="selectedIndex = p.clave" router-direction="root" 
                  :router-link="p.url" detail="false" class="hydrated" 
                  :lines="i === (PaginasGestor.length-1) ? 'none':'full' "
                  :class="{ selected: selectedIndex === p.clave}"
                  id="bloque"
                  v-if="gestor_detail"
                  >
                    <ion-icon  :md="p.mdIcon" class="icono-titulo" v-if="p.mdIcon"></ion-icon>
                    <ion-icon  :class="'icono-titulo '+p.name " v-if="p.name"></ion-icon>
                    <ion-label class="elemento-menu">{{ p.title }}</ion-label>
                </ion-item>
              </ion-menu-toggle>
              </div>

            <!-- PRICING -->
             <div class="contenedor-menu" v-if="$pricing"  @click="pricing_detail = !pricing_detail">
               <ion-toolbar  color="default" class="block-title">
                <ion-title class="ion-text-start subtitulo">
                  <ion-icon :md="pricing_detail ? caretDownOutline : caretForwardOutline" class="titulo"></ion-icon> 
                  Mantenimientos pricing
                </ion-title>
              </ion-toolbar>

              <ion-menu-toggle auto-hide="false" v-for="(p, i) in PaginasPricing" :key="i">
                <ion-item @click="selectedIndex = p.clave" router-direction="root" 
                  :router-link="p.url" detail="false" class="hydrated" 
                  :lines="i === (PaginasPricing.length-1) ? 'none':'full' "
                  :class="{ selected: selectedIndex === p.clave}"
                  id="bloque"
                  v-if="pricing_detail"
                  >
                    <ion-icon  :md="p.mdIcon" class="icono-titulo" v-if="p.mdIcon"></ion-icon>
                    <ion-icon  :class="'icono-titulo '+p.name " v-if="p.name"></ion-icon>
                    <ion-label class="elemento-menu">{{ p.title }}</ion-label>
                </ion-item>
              </ion-menu-toggle>
              </div>








           </ion-list>
  
        </ion-content>
      </ion-menu>
    
</template>

<script>
import { 
  IonButton, IonContent, IonIcon, IonItem, IonLabel, IonList, IonListHeader, 
  IonMenu, IonMenuToggle, menuController , 
  IonToolbar,IonTitle 
  } from '@ionic/vue';
import { intersection } from 'underscore';
import { PDVS_FLUIDS }  from '../utilities/global_constants';
import {openToast} from  '../utilities/global_functions'

import { defineComponent, ref } from 'vue';
import { statsChartOutline, peopleOutline,listCircleOutline, shareOutline, 
         appsOutline, list, people,speedometerOutline,searchCircleOutline, 
         caretDownOutline, caretForwardOutline, alertCircleOutline, handLeft, 
         star, swapHorizontal, logoMicrosoft, logoUsd
 } from 'ionicons/icons';
import eventBus from "@/event-bus";

export default defineComponent({
  name: 'App',
  components: {
    IonButton, 
    IonContent,  IonIcon,  IonItem, IonLabel, IonList,IonListHeader,  IonMenu, 
    IonMenuToggle, 
    IonToolbar,IonTitle 
  },
  mounted() {
        window.fake_console('Menu ','Menu mounted')
        eventBus().emitter.on('user-logged', (dat) => {
          window.fake_console('Menu ','recibo user-logged en Menu  '+JSON.stringify(dat))
          this.who= dat.who
          this.users = dat.users
          this.usr_name= dat.uname
          this.ges = dat.ges
          this.mispdvs = dat.tp == 'all' ? true 
            :dat.tp== 'pdvs' ?  intersection(dat.pdvs , PDVS_FLUIDS).length>0 : false
        });

  }, 
  data() {
    return {
      usr_name:undefined, 
      who: undefined, 
      users: undefined, 
      pdvs_detail: false, 
      wurth_detail: false, 
      stock_detail: false , 
      ajustes_detail : false,
      gestor_detail: false ,
      pricing_detail: false, 
      ges : false, 
      mispdvs : undefined 
    }
  },
  methods: {
    showMembers(url){
      return url!=='/cambio_pdv' ? true : (this.$multi==='multi' && this.$utype!='marca') 
    },
    async logout() { 
       this.$firebase.auth().setPersistence(this.$firebase.auth.Auth.Persistence.NONE)
       this.$firebase.auth().signOut()
        .then(function() {
          openToast('Cierre Aplicacion', 'A continuación se cerrará la aplicación')
          menuController.close()
          window.close()
        })
        .catch(function(error) {
          window.fake_console('Menu ',JSON.stringify(error))
          openToast('Ufff!', 'Uff parece que algo ha fallado....')
          });
    }
  },

  setup() {
    window.fake_console('Menu setup')
    const selectedIndex = ref (0);
    const PaginasSufficiency = [           
      /**
       * Pagina resumen donde recuperamos el resumen por pdv/marca 
       * Damos una visión sobre el total actual y 
       * la calidad media del stock actual 
       */
      {
        title: 'Resumen Mensual',
        url: '/resumen/sufficiency',
        clave : 'resumen_sufficiency',
        mdIcon: speedometerOutline
      },
      {
        title: 'Lo que viene... si no actuas',
        url: '/listado/stock/top_dx?q='+(Date.now()),
        clave : 'sufficiency_list_t100_dx',
        mdIcon: handLeft, 
        destacar:true
      },
      { 
        title: 'Listados detallados',
        url: '/listado/sufficiency/main?q='+(Date.now()),
        clave : 'sufficiency_list',
        mdIcon: listCircleOutline
      },

      {
        title: 'Top 100 provision',
        url: '/listado/sufficiency/top_pc?q='+(Date.now()),
        clave : 'sufficiency_list_t100_pc',
        mdIcon: list
      },
      {
        title: 'Top 100 impacto mes KPI',
        url: '/listado/sufficiency/top_dm?q='+(Date.now()),
        clave : 'sufficiency_list_t100_dm',
        mdIcon: list
      },
      {
        title: 'Top 100 impacto ytd KPI',
        url: '/listado/sufficiency/top_dy?q='+(Date.now()),
        clave : 'sufficiency_list_t100_dy',
        mdIcon: list
      },
      {
        title: 'Outlet actual',
        url: '/listado/outlet/main?q='+(Date.now()),
        clave : 'outlet_list',
        mdIcon: undefined,
        name : 'custom-outlet', 
        destacar:true
      }
      
    ]



    const PaginasWeg = [           
      /**
       * Pagina resumen donde recuperamos el resumen por pdv/marca 
       * Damos una visión sobre el total actual y 
       * la calidad media del stock actual 
       */
       {
        title: 'Resumen Mensual',
        url: '/resumen/weg',
        clave : 'resumen_weg',
        mdIcon: speedometerOutline
      },
      {
        title: 'Lo que viene....',
        url: '/listado/stock/top_dg?q='+(Date.now()),
        clave : 'sufficiency_list_t100_dg',
        mdIcon: alertCircleOutline, 
        destacar:true
      },
      { 
        title: 'Listados detallados',
        url: '/listado/weg/main?q='+(Date.now()),
        clave : 'weg_list',
        mdIcon: listCircleOutline
      },
      {
        title: 'Top 100 provision',
        url: '/listado/weg/top_pc?q='+(Date.now()),
        clave : 'weg_list_t100_pc',
        mdIcon: list
      },
      {
        title: 'Top 100 impacto mes',
        url: '/listado/weg/top_dm?q='+(Date.now()),
        clave : 'weg_list_t100_dm',
        mdIcon: list
      },
      {
        title: 'Top 100 impacto ytd',
        url: '/listado/weg/top_dy?q='+(Date.now()),
        clave : 'weg_list_t100_dy',
        mdIcon: list
      },
      {
        title: 'Top 100 impacto ltm',
        url: '/listado/weg/top_dl?q='+(Date.now()),
        clave : 'weg_list_t100_dl',
        mdIcon: list
      },

    ]

    const PaginasStock = [           
    // Menu como Jefe de ventas, Gerente, PM, director...      
      {
        title: 'Resumen diario de Stocks',
        url: '/resumen/stock',
        clave : 'resumen_stock',
        mdIcon: statsChartOutline
      },
     { 
        title: 'Listados detallados',
        url: '/listado/stock/main?q='+(Date.now()),
        clave : 'stock_list',
        mdIcon: listCircleOutline
      },
      {
        title: 'Top 100 aumentos mes',
        url: '/listado/stock/top_dm?q='+(Date.now()),
        clave : 'stock_list_t100_dm',
        mdIcon: list
      },
      {
        title: 'Top 100 aumentos ytd',
        url: '/listado/stock/top_dy?q='+(Date.now()),
        clave : 'stock_list_t100_dy',
        mdIcon: list
      },
      {
        title: 'Top 100 valor inventario',
        url: '/listado/stock/top_vc?q='+(Date.now()),
        clave : 'stock_list_t100_vc',
        mdIcon: list
      },
      {
        title: 'Reponer Cash & Carry Fluids',
        url: '/listado/cash_carry?q='+(Date.now()),
        clave : 'stock_list_cash_carry',
        mdIcon: list, 
        destacar:true
      },
    ]
    

    const PaginasAjustes = [           
    // Menu como Jefe de ventas, Gerente, PM, director...      
      {
        title: 'Control Diario regularizaciones',
        url: '/resumen/ajustes',
        clave : 'resumen_ajustes',
        mdIcon: statsChartOutline
      },
      
      {
        title: 'Top 100 dia',
        url: '/listado/ajustes/top_dd?q='+(Date.now()),
        clave : 'ajustes_list_t100_dd',
        mdIcon: list
      },{
        title: 'Top 100 mes',
        url: '/listado/ajustes/top_dm?q='+(Date.now()),
        clave : 'ajustes_list_t100_dm',
        mdIcon: list
      },
      {
        title: 'Top 100 mes anterior',
        url: '/listado/ajustes/top_da?q='+(Date.now()),
        clave : 'ajustes_list_t100_da',
        mdIcon: list
      },
      {
        title: 'Top 100 año',
        url: '/listado/ajustes/top_dy?q='+(Date.now()),
        clave : 'ajustes_list_t100_dy',
        mdIcon: list
      },
    ]
    

    const PaginasAnalisis = [           
    // Menu como Jefe de ventas, Gerente, PM, director...      
      {
        title: 'Rentabilidad Marcas (RAC)',
        /*url: '/rac/marcas/cat/pline?q='+(Date.now()), */
        url: '/rac/marcas/cat/fab?q='+(Date.now()),
        clave : 'rac_marcas',
        mdIcon: undefined, 
        name : 'fa fa-table',
      }, 
      {
        title: 'Analisis de rotaciones',
        url: '/analisis/main?q='+(Date.now()),
        clave : 'analisis_rotaciones',
        mdIcon: undefined, 
        name : 'custom-analytics',
      }, 


    ]

    const PaginasGestor = [           
    // Menu Gestor de compras, solo miembros del colectivo de compras y directores (roles:30,41,50,51)
      {
        title: 'Gestor de compras',
        url: '/gestor/list?q='+(Date.now()),
        clave : 'gestor_compras',
        mdIcon: undefined, 
        name : 'custom-monitor',
      }, 
       {
        title: 'Planficador',
        url: '/gestor/planificador?q='+(Date.now()),
        clave : 'gestor_planificador',
        mdIcon: logoMicrosoft
      },
      {
        title: 'Traspasos OE',
        url: '/traspasos/list?q='+(Date.now()),
        clave : 'traspasos_oe',
        mdIcon: swapHorizontal
      }, 
      /*
      {
        title: 'Marcas compra consolidada',
        url: '/prohab-grupo/list/012/999?q='+(Date.now()),
        clave : 'prohab_grupo',
        name : 'custom-center',
      },*/
      /*{
        // NO PUESTO EN MARCHA POR AHORA , 
        // DIFICULTAD PARA GESTIONAR CASOS VACIOS, VALIDACIONES... 
        title: 'Price Protection ',
        url: '/price-protection/list?q='+(Date.now()),
        clave : 'price-protection',
        name : 'custom-protection', 

      },      */
      {
        title: 'Rutas entre Pdvs ',
        url: '/rutas-pdvs/list/012?q='+(Date.now()),
        clave : 'rutas_pdvs',
        name : 'custom-nodes', 

      },
    ]

 const PaginasPricing = [           
    // Menu Gestor de compras, solo miembros del colectivo de compras y directores (roles:30,41,50,51)
      {
        title: 'Descuentos Clientes',
        url: '/descuentos-abc/list/001/999?q='+(Date.now()),
        clave : 'gestor_compras',
        name : 'custom-percent',
      }/*, 
      {
        title: 'MarcasKG',
        url: '/marcas-kg/list/012/999?q='+(Date.now()),
        clave : 'marcas_kg',
        mdIcon: logoUsd
      }*/

      
    ]

    return {  statsChartOutline, peopleOutline,listCircleOutline, shareOutline, 
              appsOutline, list, people,searchCircleOutline,caretDownOutline, caretForwardOutline, 
              alertCircleOutline, handLeft, star, swapHorizontal, logoMicrosoft,logoUsd,
              selectedIndex,
              PaginasSufficiency,PaginasStock,PaginasAnalisis, PaginasWeg, PaginasAjustes,
              PaginasGestor,PaginasPricing
               
    }
  }
});
</script>

<style scoped>
  ion-icon.titulo {
    font-size: 20px !important;
    vertical-align: bottom;
  }
  .nombre {
    color: var(--ion-color-primary);
    font-weight: bold;
  }
  .nombre-secundario {
    color: var(--ion-color-primary);
    font-style: italic;
    font-weight: bold;
    border-bottom:none;
    font-size: 12px !important; 

  }
  ion-label { 
    padding-left : 8px;
    font-size: 14px !important;
    }
  
  ion-item.ultima {
    border-style : hidden 0px !important ; 
  }

  div.contenedor-menu {
    margin-top: 6px !important;
    margin-left: 12px !important ;
    margin-right: 12px !important ;
    padding-bottom: 6px !important; 
    border: 2px solid var(--ion-color-light);
  }
  ion-toolbar.block-title {
    --min-height:38px !important; 
    margin-bottom: 6px !important;
    background-color: var(--ion-color-light);
  }
  .subtitulo {
    color: var(--ion-color-dark-tint);
    font-size: 16px !important;
    font-weight: bold;
    padding-bottom: 4px !important;
    padding-left: 6px !important;
    padding-right: 6px !important;    
    vertical-align: bottom;
  }
  ion-icon.icono-titulo {
    font-size:16px !important;
  }
  ion-menu ion-content {
    --background: var(--ion-item-background, var(--ion-background-color, #fff));
  }
  
  ion-menu ion-content {
    --padding-start: 8px;
    --padding-end: 8px;
    --padding-top: 20px;
    --padding-bottom: 20px;
  }


  ion-menu ion-list {
    padding: 6px 0;
  }

  ion-menu ion-list#inbox-list  ion-list-header {
    font-size: 22px;
    font-weight: 600;
    min-height: 32px;
  }

  ion-list-header.sin-borde{
    border: none !important;
  }

  ion-menu ion-item {
    --padding-start: 10px;
    --padding-end: 10px;
    border-radius: 4px;
    --min-height: 40px;
  }

  ion-item.cambios {
    --padding-start: 10px;
    --padding-end: 0px;
    --min-height: 40px;
    margin-bottom: 16px;
  }
  ion-menu ion-item.selected {
    --background: rgba(var(--ion-color-primary-rgb), 0.14);
  }

  ion-menu ion-item.selected ion-icon {
    color: var(--ion-color-primary);
  }

  ion-menu ion-item ion-icon {
    color: #616e7e;
  }
  ion-menu ion-item   ion-icon.cambios {
    padding-right : 8px; 
    color: var(--ion-color-blanco) !important;
  }
  ion-button.cambios  {
    float:right; 
    text-transform: none;
    

  }
  div.cambios {
      width:100%; 
  }

  ion-menu ion-item ion-label {
    font-weight: 400;
  }

  ion-item.selected {
    --color: var(--ion-color-primary);
  }

  #bloque {
    margin-left: 8px;
    margin-right: 8px !important;
    font-size: 14px !important;
    --min-height: 28px; 
  }
  ion-label.elemento-menu{
    margin-top: 6px !important; 
    margin-bottom: 6px !important; 
  }

</style>