<template>
   <ion-page :style="fontSize" >
        <CabeceraComponent :titulo="titulo_pag" color="primary"/>
        <ion-content class="ion-padding" v-if="device" >  
 
             <ion-row v-if="loading" class="spin ion-justify-content-center "  >
                <ion-col  v-if="loading" class="spin ion-align-self-center" size="12" > 
                    <ion-spinner color="primary" v-if="loading"></ion-spinner>
                </ion-col>
            </ion-row>
            <ion-row class="ion-justify-content-left ion-align-items-top selectores grande" v-if="loading==false">
                    <ion-col  :size="device.tamanyo == 0 ? 48 : device.tamanyo==1 ? 18 : 24" >
                        <div class="p-input-icon-right mismo-que-paginador" >
                            <InputText type="text" v-model="termino" placeholder="Buscar (min 3 letras)" @input="debounceSearch"/>
                            <i class="pi pi-search" />
                        </div>
                        <div class="nota">
                            <ion-note v-if="disponibles"> {{disponibles}} registros de {{datos.length}} totales, viendo los {{filtrados.length}} primeros </ion-note>
                        </div>
                    </ion-col>
                    <ion-col  :size="device.tamanyo == 0 ? 48 : device.tamanyo==1 ? 30 : 24" >
                        <div class="p-input-icon-right mismo-que-paginador force-right" >
                            <wj-collection-view-navigator
                                class="navegador force-right"
                                headerFormat="Pagina {currentPage:n0} de {pageCount:n0}"
                                :byPage="true"
                                :cv="filtrados" />
                            <DropDown v-model="filas_paginas" :options="paginador"   class="selector_paginas cabecera force-right"
                                v-bind:key="'selector_filas'"  
                                @hide="tratamientoDatos"
                                placeholder="Filas " /> 
                        </div>
                    </ion-col>
            </ion-row>
            <ion-row class="ion-justify-content-left ion-align-items-top grande" v-if="loading==false">
                    <ion-toolbar> 
                        <ion-buttons slot="end" >
                            <ion-button color="primary"  fill="solid" @click="NuevaCombinacion">
                                <b>Nueva Price Protection</b>
                            </ion-button>  
                        </ion-buttons>
                    </ion-toolbar>            
            </ion-row>
            <ion-row v-if="!loading">

                <wj-flex-grid 
                    :showMarquee="true"
                    :anchorCursor="true"
                    :showSelectedHeaders="'All'"
                    :itemsSource="filtrados" 
                    :alternatingRowStep="0"                      
                    :headersVisibility="'Column'"
                    :selectionMode="'None'"
                    ref="theGrid" v-if="datos">
                    <wj-flex-grid-filter />
                        <wj-flex-grid-column v-for="(c,index) in columnas"   
                            :key="'c'+index" :header="c.header" :binding="c.col" 
                            :width="c.width" 
                            :align="c.align == undefined ? '': c.align"
                            :isReadOnly ="true"
                            cssClass="no-bloqueada"
                            :format="c.format==undefined ? '' : c.format" 
                            />
                </wj-flex-grid>
                </ion-row>    
        </ion-content>
        


    </ion-page>
</template>

<script>

    import { IonPage, IonContent,   
            IonRow,IonCol,  
            IonToolbar, IonButtons, IonButton, IonNote, 
            IonSpinner
     } from '@ionic/vue';
    import CabeceraComponent from '@/components/CabeceraComponent'
    import { CollectionView } from '@grapecity/wijmo';
    import { defineComponent } from 'vue';
    import { useRoute } from 'vue-router';

    import eventBus from "@/event-bus";
    export default defineComponent({
            name : "PriceProtectionList",
            components: { 
                IonPage, IonContent,   
                IonToolbar, IonButtons, IonButton, IonNote, 
                IonRow,IonCol, IonSpinner,  
                CabeceraComponent  
            } , 
            props : ['codemp', 'codmar'], 
            computed :{
                fontSize () { 
                    window.fake_console('PriceProtectionList',`--texto-size : ${9+(this.device.tamanyo)}px`)
                    return `--texto-size : ${9+(this.device.tamanyo)}px;`
                }, 

                columnas() {
                    const cols = [
                        { "col": "codemp" , "header": "Pdv",   "width": "*", "show" : true },  
                        { "col": "codmar" , "header": "Marca",   "width": "*", "show" : true },  
                        { "col": "nommar" , "header": "Nombre Marca", "width": "2*", "show" : this.device.tamanyo>1  },  
                        { "col": "artpro" , "header": "Articulo",   "width": "2*", "show" : true },  
                        { "col": "pmp" , "header": "Pmp",  "format" : "n5", "width": "1.5*",  "align": "right","show" : true }, 
                        { "col": "unipre" , "header": "Unidad Precio", "format" : "n0", "width": "1.5*", "align": "right","show" : true },  
                        { "col": "tipo" , "header": "Unidad Precio", "format" : "n0", "width": "1.5*", "align": "right","show" : true },  
                        { "col": "codpro" , "header": "Proveedor", "format" : "n0", "width": "1.5*", "align": "right","show" : true },  
                        { "col": "fecha_aplicacion" , "header": "Fecha Aplicac.",   "width": "1.2*" , "align": "center", "show": true },
                        { "col": "last_update" , "header": "Ult.Cambio ",   "width": "1.2*" , "align": "center", "show" : this.device.tamanyo>1 },
                        { "col": "usr" , "header": "Usuario ",   "width": "1.4*", "align": "left", "show" : this.device.tamanyo>2 },
                        ]
                    return cols.filter(x=>x.show) 
                }
                /* "artpro" , "pmp" , "unipre" , "tipo" , "fecha_aplicacion" , "codpro" , "last_update" , "usr"]*/ 
            },
            data() {    
                return {
                    titulo_pag:"Price protections pendientes de carga" , 
                    filas_paginas : 30, 
                    paginador : [15,30,50,100],
                    /** Variable que controla el spinner de carga  */
                    loading: true, 
                   /** Variable de control para actuar en mount/update */
                    ruta: undefined , 
                    fetchUrl : undefined, 

                    /** variable de control del debounce (diferimiento ) */
                    debounce: null,

                    /**
                     * Variable donde estan los datos que efectivamente se ven en la tabla 
                     * una vez aplicados todos los filtros 
                     */
                    datos: undefined, 
                                        
                    /**
                     * Variable donde estan los datos que efectivamente se ven en la tabla 
                     * una vez aplicados todos los filtros 
                     */
                    filtrados: undefined, 
                    disponibles : undefined, 

                    /** 
                     * Termino de busqueda que introducimos en el filtro libre 
                     */                    
                    termino: undefined, 

                    /** 
                     * Datos del dispositivo, son globales, controlamos los eventos de 'window' 
                     * para responder a los cambios de tamaño de ventana, orientacion...  
                     */                    
                    device: this.$device, 

                }
            },  
            async updated(){
                const route = useRoute();
                window.fake_console('PriceProtectionList',this.ruta, route.path)
                if (route.fullPath!=this.ruta)
                {
                    this.loading = true;
                    window.fake_console('PriceProtectionList','llamo al update ')
                    this.ruta = route.fullPath
                    await this.procesoInicial()
                }         
                else {
                    window.fake_console('PriceProtectionList','NO llamo al update')
                }       
            },
            async mounted() {
                const route = useRoute();
                this.ruta = route.fullPath
                window.fake_console('PriceProtectionList',route.path, route.query)
                await this.procesoInicial()
            }, 
            methods :{
                async procesoInicial() {
                    /** fallback por si viene la combinación más generica de todas  */
                    if (this.codemp =='000' && this.codmar === '999') {
                        this.$router.replace(`/marcas-kg/list/012/999?q=${Date.now()}`)
                    }
                    this.loading = true;
                    this.device = this.$device 
                    eventBus().emitter.on('device', (sz) => {  this.device=sz })    
                    this.fetchData()
                }, 
                
                async fetchData(){ 
                    this.loading = true
                    const url =`/buy/price-protection-actuales`
                    window.fake_console('PriceProtectionList',url, this.fetchUrl)
                    if (url !=this.fetchUrl)
                    {
                        this.fetchUrl = url
                        const result = await this.$http.get(url) 
                        window.fake_console('PriceProtectionList','Es cache?? : ',result.request.fromCache)
                        this.datos = result.data.data
                        await this.tratamientoDatos()
                    }
                    this.loading = false
                }, 
                debounceSearch() {
                    clearTimeout(this.debounce)
                    this.debounce = setTimeout(() => { this.tratamientoDatos()}, 250)
                },

                async tratamientoDatos() {
                    let filtrados = this.datos
                    if (this.termino!=undefined && this.termino.length>=2){
                        this.termino.toLowerCase().split(' ').forEach(t=>
                            filtrados = filtrados.filter((v)=> {return JSON.stringify(v).toLowerCase().indexOf(t)!=-1})
                        )
                    }
                    this.disponibles = filtrados.length
                    this.filtrados =  new CollectionView(filtrados, { pageSize: this.filas_paginas});
                }, 
                async NuevaCombinacion () {
                    this.$router.replace(`/price-protection/list_editable`) 
                }, 
            }
            
})
</script>

<style scoped lang="scss">
     @import '@/theme/listas.scss';
</style>