<template>
   <ion-page :style="fontSize" >
        <CabeceraComponent :titulo="titulo_pag" color="primary"/>
        <ion-content class="ion-padding" v-if="device" >  
            
            <ion-row class="ion-justify-content-left ion-align-items-top selectores grande" v-if="loading==false">
                    <ion-col  :size="device.tamanyo == 0 ? 24 : device.tamanyo==1 ? 16 : 12" >
                        <div class="p-input-icon-right">
                            <InputText type="text" v-model="termino" placeholder="Buscar (min 3 letras)" @input="debounceSearch"/>
                            <i class="pi pi-search" />
                        </div>
                    </ion-col>
               
                    <ion-col :size="device.tamanyo == 0 ? 24 : device.tamanyo==1 ? 16 : 12" v-for = "(vf,idf) in 
                            device.tamanyo>1 
                                ? {  'fecpet' : fechas, 'usu': usuarios , 'nompos' : pdvs }
                                : {  'fecpet' : fechas, 'nompos' : pdvs }
                            " 
                            v-bind:key="device.tipo+idf+'_999'" >
                        <DropDown v-model="SelectedFilters[idf]" :options="vf" optionLabel="n" class="selector"
                            :filter="true"   v-bind:key="device.tipo+idf"  
                            @change="filtrar"
                            :placeholder="'Filtrar '+etiquetas[idf]" />
                    </ion-col>

            </ion-row>
            <ion-row v-if="loading" class="spin ion-justify-content-center "  >
                <ion-col  v-if="loading" class="spin ion-align-self-center" size="12" > 
                    <ion-spinner color="primary" v-if="loading"></ion-spinner>
                </ion-col>
            </ion-row>
            <DataTable :value="filtrados" 
                v-if="!loading && datos"
                v-bind:key="device.tipo + device.ancho + contador"
                :paginator="paginador" :rows="filas" 
                scrollHeight="flex" 
                scrollDirection="vertical" 
                :scrollable="true" 
                removableSort
                :sortable="true"
                ref="datatable_gestor"
                paginatorTemplate="RowsPerPageDropdown  FirstPageLink PrevPageLink  NextPageLink LastPageLink CurrentPageReport"
                :rowsPerPageOptions="[10,20,50,100]"
                currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                responsiveLayout="scroll"
                :rowClass="Validar_Linea"
                selectionMode="multiple" 
                v-model:selection="selectedTraspasos"
                    >

                <template #header>
                <div >
                    <ion-row>
                        <ion-col :size="device.tamanyo < 1 ? 48 : 28" >
                            <h3>Traspasos Operaciones Especiales ({{largo}} pendientes)</h3>
                        </ion-col>
                        <ion-col >
                            <ion-toolbar> 
                                <ion-buttons slot="end" :size="device.tamanyo==0 ? 'small' : 'large'" :disabled="selectedTraspasos===undefined ||selectedTraspasos.length==0">
                                    <ion-button color="success" fill="solid" :size="device.tamanyo==0 ? 'small' : 'large'" 
                                            :disabled="selectedTraspasos===undefined ||selectedTraspasos.length==0"
                                            @click="EditTraspasos('a')"
                                            >
                                            <b>(A)</b><small>ceptar</small>
                                    </ion-button>                                                       
                                    <ion-button color="danger"  fill="solid"  :size="device.tamanyo==0 ? 'small' : 'large'" 
                                            :disabled="selectedTraspasos===undefined ||selectedTraspasos.length==0"
                                            @click="EditTraspasos('r')"
                                            >
                                            <b>(R)</b><small>echazar</small>
                                    </ion-button>     
                                </ion-buttons>
                            </ion-toolbar>
                        </ion-col>
                    </ion-row>
                </div>


                </template>
                <template #loading>
                    Cargando datos . Por favor, espera.
                </template>
                <ColumnGroup type="header" >
                    <Row :headerStyle="AnchoCol(100)">
                        <Column headerStyle="width: 2rem !important;"></Column>
                        <Column header="Pdv" :sortable="true" field="codemp"    :headerStyle="anchos['codemp']" :bodyStyle="anchos['codemp']"   v-if="device.tamanyo==0" />
                        <Column header="Nombre Pdv" :sortable="true" field="nompos"    :headerStyle="anchos['nompos']" :bodyStyle="anchos['nompos']"   v-if="device.tamanyo!=0" />
                        <Column header="Numero" :sortable="true" field="numpet"    :headerStyle="anchos['numpet']" :bodyStyle="anchos['numpet']" />
                        
                        <Column header="Usuario" :sortable="true" field="usu"    :headerStyle="anchos['usu']" :bodyStyle="anchos['usu']" />
                        <Column header="Fecha" :sortable="true" field="fecpet" :headerStyle="anchos['fecpet']" :bodyStyle="anchos['fecpet']" />
                        <Column header="Orig." :sortable="true" field="codsuc"    :headerStyle="anchos['codsuc']" :bodyStyle="anchos['codsuc']" />
                        <Column header="Dest." :sortable="true" field="recsuc"    :headerStyle="anchos['recsuc']" :bodyStyle="anchos['recsuc']" />
                        
                        <!-- Segundo nivel: Impactos económicos-->
                        <Column header="Lineas" :sortable="true" field="lins"  :headerStyle="anchos['lins']" :bodyStyle="anchos['lins']" />
                        <Column header="Valor" :sortable="true" field="val" :headerStyle="anchos['val']" :bodyStyle="anchos['val']" />

                        <!-- nivel único-->
                        <Column header="Nota " :sortable="true" field="obstra"    :headerStyle="anchos['obstra']" :bodyStyle="anchos['obstra']"  />
                        
                    </Row>  
                </ColumnGroup>
                <Column selectionMode="multiple" headerStyle="width: 2rem !important;"></Column>
                <Column v-for="(c, index) of columnas" :field="c" 
                    :key="c + '_' + index" 
                    :sortable="true"
                    :headerStyle="anchos[c]" 
                    :bodyStyle="anchos[c]" 
                    >
                    
                    <template #body="slotProps" >
                        <!-- campos texto alineacion izquierda-->
                        <div class="izquierda azul" v-if="['codemp'].indexOf(c)!=-1 && device.tamanyo <=1">
                            {{device.tamanyo==0 ? slotProps.data[c].split(' ')[0]: slotProps.data[c]}} 
                        </div>

                        <div class="izquierda azul" v-if="['nompos'].indexOf(c)!=-1 && device.tamanyo>1">
                            {{device.tamanyo==0 ? slotProps.data[c].split(' ')[0]: slotProps.data[c]}} 
                        </div>

                        <div class="izquierda azul" v-if="['usu'].indexOf(c)!=-1 && device.tamanyo!=0">
                            {{device.tamanyo==0 ? slotProps.data[c].split(' ')[0]: slotProps.data[c]}} 
                        </div> 
                        <div class="izquierda azul" v-if="['obstra'].indexOf(c)!=-1">
                            {{slotProps.data[c]}} 
                        </div>
                        <div class="derecha azul" v-if="['codsuc','recsuc'].indexOf(c)!=-1">
                            {{slotProps.data[c]}} 
                        </div>

                        <!-- campos link-->
                        <div class="derecha azul" v-else-if="['numpet'].indexOf(c)!=-1" @click="irDetalle(slotProps.data)">
                            <a  href="javascript:void(0);">{{ $filters.Entero(slotProps.data[c] ==undefined ? '-' : slotProps.data[c])}}</a>
                        </div>                       
                         <!-- campos decimales-->
                        <div class="derecha azul" v-else-if="['lins'].indexOf(c)!=-1">
                            {{ $filters.Entero(slotProps.data[c] ==undefined ? '-' : slotProps.data[c])}}
                        </div>
                        <div class="derecha azul" v-else-if="['val'].indexOf(c)!=-1">
                            {{ $filters.s1Decimal(slotProps.data[c])}}
                        </div>

                        <!-- Campos fecha -->
                        <div class="derecha azul" v-else-if="['fecpet'].indexOf(c)!=-1">
                            {{ $filters.text2Date(slotProps.data[c] ==undefined ? '-' : slotProps.data[c])}} 
                        </div>



                    </template>
                </Column>   
            </DataTable>
        </ion-content>
        


    </ion-page>
</template>

<script>

    import { IonPage, IonContent,   
            IonRow,IonCol,  IonButton, 
            IonSpinner,IonButtons,IonToolbar, 
            modalController
     } from '@ionic/vue';
    import CabeceraComponent from '@/components/CabeceraComponent'
    import  TraspasosMultiActionModal  from  '@/pages/traspasos/TraspasosMultiActionModal'
    import { defineComponent } from 'vue';
    import * as _ from 'underscore';
    import eventBus from "@/event-bus";
    import { closeCircle } from 'ionicons/icons';
    import { useRoute } from 'vue-router';
    export default defineComponent({
            name : "TraspasosList",
            components: { 
                IonPage, IonContent, 
                IonRow,IonCol, IonButton,
                IonSpinner,IonButtons,IonToolbar,
                CabeceraComponent  
                
            } , 
            computed :{
                fontSize () { 
                    window.fake_console('TraspasosList',`--texto-size : ${9+(this.device.tamanyo)}px`)
                    return `--texto-size : ${9+(this.device.tamanyo)}px;`
                }, 

                anchos () {
                    /** Nos aseguramos que las definiciones de columnas sumen 100, sino aplicamos proporcionalidad  */
                    const bases = this.Bases()
                    const factor = _.reduce(_.values(bases ), (m,v)=> {return m+=(v/100)},0)
                    window.fake_console(factor)
                    const resultado = _.reduce(bases , (m,v,k)=> {m[k]= this.AnchoCol(v / factor) ;return m },{})
                    window.fake_console(this.device.tipo, this.device.ancho,  factor, resultado)
                    return resultado
                    },
                columnas() {
                    const cc = ["codemp", "nompos", "numpet","usu","fecpet", "codsuc", "recsuc", "lins", "val","obstra"]
                    return this.device.tamanyo ==0 
                        ? _.without(cc,['nompos','usu'])  : this.device.tamanyo == 1 ? _.without(cc,'nompos')
                            : _.without(cc,'codemp')
                }
            },
            data() {    
                return {
                    base_anchos : {
                        "codemp": 1.5, "nompos": 6,  "numpet": 2.2, "usu": 4,    "fecpet": 2.5,   "val": 2,   "codsuc": 1.8,   "recsuc": 1.8,  
                        "lins": 2,  "obstra": 6
                        },
                
                    etiquetas:  {
                        'fecpet':'fecha de peticion',
                        'nompos': 'punto de venta', 
                        'usu' : 'usuario'
                    },
                    titulo_pag:"Traspasos OE " , 
                    paginador: false, 
                    /** Iconos, se han de declara en data para poder ser utilizados  */
                    closeCircle, 
                    
                   /** Variables para los filtros */
                    fechas : undefined, 
                    usuarios: undefined, 
                    pdvs : undefined ,
                    tiempo: undefined,

                    /** Valores seleccionados para cada filtro, los posibles son los que estan en filters */
                    SelectedFilters: { 'fecpet' : 0   ,  'nompos' : 0 , 'usu': 0  }, 

                   /** Variable de control para actuar en mount/update */
                    ruta: undefined , 
                    fetchUrl : undefined, 
                    filas: undefined , 
                    selectedTraspasos:undefined, 
                    /** Variable que controla el spinner de carga  */
                    loading: true, 
                    inicio: undefined, 
                    contador : 0,


                    /** variable de control del debounce (diferimiento ) */
                    debounce: null,

                  
                    /**
                     * Variable donde estan los datos que efectivamente se ven en la tabla 
                     * una vez aplicados todos los filtros 
                     */
                    datos: undefined, 
                                        
                    /**
                     * Variable donde estan los datos que efectivamente se ven en la tabla 
                     * una vez aplicados todos los filtros 
                     */
                    filtrados: undefined, 
                    largo: undefined, 

                    /** 
                     * Termino de busqueda que introducimos en el filtro libre 
                     */                    
                    termino: undefined, 

                    /** 
                     * Datos del dispositivo, son globales, controlamos los eventos de 'window' 
                     * para responder a los cambios de tamaño de ventana, orientacion...  
                     */                    
                    device: this.$device, 

                }
            },  
            async updated(){
                this.inicio = new Date().getTime()
                window.fake_console('TraspasosList','en el update 245')
                const route = useRoute();
                window.fake_console('TraspasosList',this.ruta, route.path)
                if (route.fullPath!=this.ruta)
                {
                    this.loading = true;
                    window.fake_console('TraspasosList','llamo al update ')
                    this.ruta = route.fullPath
                    this.actualizacion()

                }         
                else {
                    window.fake_console('TraspasosList','NO llamo al update')
                }       
            },
            async mounted() {
                window.fake_console('en el moutn')
                await this.actualizacion()
            }, 
            methods :{
                async actualizacion () {
                    window.fake_console('En la actualizacion')
                    this.inicio = new Date().getTime()
                    this.loading = true;
                    window.fake_console('TraspasosList','en el mount 273')
                    const route = useRoute();
                    window.fake_console('TraspasosList',this.ruta, route.path, route.query)
                    this.ruta = route.fullPath
                    window.fake_console('TraspasosList',route.path, route.query)
                    this.procesoInicial()
                }, 

                Bases () {
                    const omisiones =  {
                        0 : ['nompos',"usu"],
                        1 : ['nompos'], 
                        2 : ['codemp'],
                        3 : ['codemp']
                    }
                    
                    return  _.omit(this.base_anchos,omisiones[this.device.tamanyo]) 
                }, 

                AnchoCol(x) { 
                    const sitwidtdh = 0
                    const marginwidth = 38
                    return `width: ${(this.device.ancho-marginwidth-sitwidtdh)*x/100}px !important`
                    }, 
                
                async procesoInicial() {
                    this.loading = true;
                    this.device = this.$device 
                    eventBus().emitter.on('device', (sz) => {  this.device=sz })          
                    this.fetchData()
                    
                }, 
                
                async fetchData(){ 
                    this.loading = true
                    const url =`/buy/traspasos-list`
                    window.fake_console('TraspasosList',url, this.fetchUrl)
                    if (url !=this.fetchUrl||this.contador>0)
                    {
                        this.fetchUrl = url
                        const result = await this.$http.get(url) 
                        window.fake_console('TraspasosList','Es cache?? : ',result.request.fromCache)
                        await this.tratamientoDatos(result.data)
                    }
                    this.SelectedFilters=  {'fecped' : 0   , 'nompos' : 0 }
                    this.filtrar()
                    this.loading = false
                }, 
                Validar_Linea(data) {
                    window.fake_console('SkuDetails ',  data)
                    return data.est_gest =='P' ? 'check_azul' :null
                }, 
                
                debounceSearch() {
                    clearTimeout(this.debounce)
                    this.debounce = setTimeout(() => { this.filtrar()}, 250)
                },
                async irDetalle(item) {
                    window.fake_console('L401 : ', item)
                    this.$router.push(`/traspasos/detail/${item.codemp}/${item.codsuc}/${item.numpet}`)
                },
                async filtrar() {
                    window.fake_console(JSON.stringify(this.SelectedFilters))
                    const selectores = _.reduce(this.SelectedFilters, (m,v,k)=> {
                        if (v!=0 && v['id']!=0 ) {m[k]= v['id']}
                        return m 
                    } , {})
                    window.fake_console(selectores)
                    window.fake_console(Object.keys(selectores).length)
                    let filtrados = _.filter(this.datos, _.matcher(selectores))
                    if (this.termino!=undefined && this.termino.length>=2){
                        filtrados = filtrados.filter((v)=> {return JSON.stringify(v).toLowerCase().indexOf(this.termino)!=-1})
                    }
                    this.filtrados = filtrados
                    window.fake_console('TraspasosListPage',this.filtrados.length, this.filas, this.paginador)
                    if (this.filtrados.length <= this.filas) { this.paginador = false}
                        else { this.paginador = true}
                    window.fake_console('TraspasosListPage',this.filtrados.length, this.filas, this.paginador)
                    this.loading = false
                    window.fake_console('TraspasosListPage',filtrados.length)
                    window.fake_console('TraspasosListPage',JSON.stringify(filtrados).slice(0,100))
                },
                async tratamientoDatos(result) {
                        const generar = function(d,p,t) {
                            const total = {'id':0,'n': t}
                            const w =  _.map(_.unique(_.pluck(d, p)).sort(), function(k) {return _.object(['id','n'],[k,k])})
                            w.unshift(total)
                            return w
                        }
                        this.fechas = generar(result,'fecpet','Todas las fechas')
                        this.pdvs = generar(result,'nompos','Todos los pdvs')
                        this.usuarios = generar(result,'usu','Todos los usuarios')
                        this.datos = result
                        this.filtrados = result
                        this.largo = result.length
                        this.filas = this.largo <10? this.largo :10
                        this.paginador = this.largo <10 ? false :true
                }, 
            
                async EditTraspasos (act) {
                    var self = this
                    const modal = await modalController
                        .create({
                                component: TraspasosMultiActionModal,
                                cssClass: 'custom-modal',
                                componentProps: { items: this.selectedTraspasos, act: act },
                                })
                    eventBus().emitter.on('cerrar-edicion-traspaso',async (que)=>{
                        modal.dismiss();
                        if (que!=undefined) {  
                            window.fake_console('en la condicion de cambio')
                            self.contador+=1
                            self.$router.replace(`/traspasos/list?q=${Date.now()}`)
                        }    
                    });
                    modal.present();
                }, 
                
               
            }
            
})
</script>


<style lang="scss">
    table>thead>tr>th[class=""]{
        display: none !important; 
        padding:0px !important;
    }
</style>
<style scoped>
    i.wait-left {
        color:var(--ion-color-primary) !important;
        position:relative !important; 
        left:-12px !important;
    }

    .azul {
        color:var(--ion-color-primary) !important;
        }
    ion-button.botones {
        --padding-bottom: 0.5rem !important; 
        --padding-top:  0.5rem !important; 
        --padding-start:  0.5rem !important; 
        --padding-end:  0.5rem !important; 
    }
    ion-row.selectores {
        border: 1px solid var(--ion-color-light);
    }
    ion-row.grande {
        margin-top: 12px! important; 
        margin-bottom: 12px! important; 
    }

    ion-button {
        margin: 2px !important;
        color:white !important;
    }
    /* color del texto del boton  */
    .ion-color-warning {
        --ion-color-contrast:white !important;
    }
    .derecha {
        text-align: right !important;
        width: 100%;
    }
    .izquierda {
        text-align: left !important;
        width: 100%;
    }
    .centro {
        text-align: center !important;
        width: 100%;
    }


</style>