<template>
   <ion-page :style="fontSize" >
        <CabeceraComponent :titulo="titulo_pag" color="primary"/>
        <ion-content class="ion-padding" v-if="device" >  
 
            
            <ion-row class="ion-justify-content-left ion-align-items-top selectores grande" v-if="loading==false">
                    <ion-col  :size="device.tamanyo == 0 ? 48 : device.tamanyo==1 ? 18 : 24" >
                        <div class="p-input-icon-right  mismo-que-paginador">
                            <InputText type="text" v-model="termino" placeholder="Buscar (min 3 letras)" @input="debounceSearch"/>
                            <i class="pi pi-search" />
                        </div>
                        <div class="nota">
                            <ion-note v-if="disponibles"> {{disponibles}} registros de {{datos.length}} totales, viendo los {{filtrados.length}} primeros </ion-note>
                        </div>
                    </ion-col>
                    <ion-col  :size="device.tamanyo == 0 ? 48 : device.tamanyo==1 ? 30 : 24" >
                        <div class="p-input-icon-right mismo-que-paginador force-right" >
                            <wj-collection-view-navigator
                                class="navegador force-right"
                                headerFormat="Pagina {currentPage:n0} de {pageCount:n0}"
                                :byPage="true"
                                :cv="filtrados" />
                            <DropDown v-model="filas_paginas" :options="paginador"   class="selector_paginas cabecera force-right"
                                v-bind:key="'selector_filas'"  
                                @hide="tratamientoDatos"
                                placeholder="Filas " /> 
                        </div>
                    </ion-col>
            </ion-row>
            <ion-row v-if="loading" class="spin ion-justify-content-center "  >
                <ion-col  v-if="loading" class="spin ion-align-self-center" size="12" > 
                    <ion-spinner color="primary" v-if="loading"></ion-spinner>
                </ion-col>
            </ion-row>
           <ion-row class="ion-padding-left ion-margin-left grande">
                <ion-toolbar> 
                    <DropDown v-model="vista" :options="vistas" optionLabel="etiqueta" class="selector_kg"
                        :filter="false"   v-bind:key="'selector_vista_editados'"  
                        :disabled="cambios==0||cambios==undefined"
                        @hide="tratamientoDatos"
                        :placeholder="'Estado de los registros' " />

                    <ion-buttons slot="end" >
                        <ion-button color="tertiary"  fill="solid" @click="go_actuals">
                            <b>Ver actuales</b>
                        </ion-button>  
                        <ion-button color="primary"  fill="solid" @click="descartar">
                            <b>Descartar todo</b>
                        </ion-button>  

                        <ion-button color="success"  fill="solid" @click="grabar" :disabled="cambios==0||cambios==undefined" >
                            <b>{{vista.id==undefined||vista.id!='C' ? 'Revisar' : 'Grabar'}} Cambios</b>
                        </ion-button>  
                    </ion-buttons>
                </ion-toolbar>

            </ion-row>
            <ion-row v-if="!loading">
                <wj-flex-grid 
                    :showMarquee="true"
                    :anchorCursor="true"
                    :headersVisibility="'Column'"
                   
                    :showSelectedHeaders="'All'"
                    :itemsSource="filtrados" 
                    :alternatingRowStep="0"  
                    ref="theGrid" v-if="datos"
                    :cellEditEnding = "onEditCell"
                    :pastedCell = "onEditCell"  
                    :loaded-rows = "onloadedRows"
                    >
                    <wj-flex-grid-filter />
                        <wj-flex-grid-column v-for="(c,index) in columnas"   
                            :key="'c'+index" :header="c.header" :binding="c.col" 
                            :width="c.width" 
                            :align="c.align == undefined ? '': c.align"
                            :isReadOnly ="isEditable(c.col)"
                            :cssClass="isEditable(c.col) ? 'bloqueada' : 'no-bloqueada'"
                            :format="c.format==undefined ? '' : c.format" 
                            />

                        <wj-flex-grid-column
                            key="'c_edit" header="" 
                            width="0.3*"  align="center" 
                            :isReadOnly ="true"
                            cssClass="bloqueada del"
                            :cellTemplate="tplCustomEdit"
                            >
                        </wj-flex-grid-column>
                        <wj-flex-grid-column
                            key="'c_at" header="Borrar" 
                            width="0.7*"  align="center" :isReadOnly ="true"
                            cssClass="bloqueada del"
                            :cellTemplate="tplCustomDelButton"
                            >
                        </wj-flex-grid-column>
                        
                        
                </wj-flex-grid>
                </ion-row>    
        </ion-content>
        


    </ion-page>
</template>

<script>

    import { IonPage, IonContent,   
            IonRow,IonCol,  
            IonSpinner,  
            IonToolbar, IonButtons, IonButton, IonNote, 
     } from '@ionic/vue';
    import CabeceraComponent from '@/components/CabeceraComponent'
    import { Control } from "@grapecity/wijmo";
    import { CellMaker } from '@grapecity/wijmo.grid.cellmaker';
    import { CollectionView } from '@grapecity/wijmo';
    import { defineComponent } from 'vue';
    import { pluck, groupBy, chain, pick, union, difference, omit   } from 'underscore';
    import {openAlerta ,openToast} from  '@/utilities/global_functions';
    import eventBus from "@/event-bus";
    import { useRoute } from 'vue-router';
    export default defineComponent({
            name : "ProhabGrupoListEditable",
            components: { 
                IonPage, IonContent, 
                IonRow,IonCol, IonSpinner, 
                IonToolbar, IonButtons, IonButton, IonNote, 
                CabeceraComponent  
                
            } , 
            props : ['codemp', 'codmar'], 
            computed :{
                fontSize () { 
                    window.fake_console('PriceProtectionEditable',`--texto-size : ${9+(this.device.tamanyo)}px`)
                    return `--texto-size : ${9+(this.device.tamanyo)}px;`
                }, 

                columnas() {
                    const cols = [
                        { "col": "codemp" , "header": "Pdv",   "width": "*", "show" : true },  
                        { "col": "codmar" , "header": "Marca",   "width": "*", "show" : true },  
                        { "col": "artpro" , "header": "Articulo",   "width": "2*", "show" : true },  
                        { "col": "pmp" , "header": "Pmp",  "format" : "n5", "width": "1.5*",  "align": "right","show" : true }, 
                        { "col": "unipre" , "header": "Unidad Precio", "format" : "n0", "width": "1.5*", "align": "right","show" : true },  
                        { "col": "tipo" , "header": "Unidad Precio", "format" : "n0", "width": "1.5*", "align": "right","show" : true },  
                        { "col": "codpro" , "header": "Proveedor", "format" : "n0", "width": "1.5*", "align": "right","show" : true },  
                        { "col": "fecha_aplicacion" , "header": "Fecha Aplicac.",   "width": "1.2*" , "align": "center", "show": true },
                        { "col": "last_update" , "header": "Ult.Cambio ",   "width": "1.2*" , "align": "center", "show" : this.device.tamanyo>1 },
                        { "col": "usr" , "header": "Usuario ",   "width": "1.4*", "align": "left", "show" : this.device.tamanyo>2 }
                        ]
                    return cols.filter(x=>x.show) 
                }
            },
            data() {    
                return {
                    vistas: [{'id':'A' ,'etiqueta': 'Todos'}, {'id':'C' ,'etiqueta': 'Solo Cambios'} ], 
                    vista: {'id':'A' ,'etiqueta': 'Todos'},

                    cambios: 0, 
                    titulo_pag:"Configuracion asignacion proveedores internos" , 
                    filas_paginas : 30, 
                    paginador : [15,30,50,100],
                    tplCustomDelButton: CellMaker.makeButton({
                        text : '<i class="${item.a==\'D\' ? \'fa fa-undo\' :  (item.a!=\'X\'||item.ms_uuid!=undefined) ? \'fa fa-trash\'  : \'\'  }"/>',
                        click: this.onDeleteCell
                    }),                 
                    tplCustomEdit: CellMaker.makeButton({
                            text: '<i class="${item.a==\'D\' ?  \'fa fa-trash\' : item.edit===1 ? \'fa fa-pencil\'  : \'\'  }"/>',
                    }),

                   /** Variables para los filtros */

                   /** Variable de control para actuar en mount/update */
                    ruta: undefined , 
                    fetchUrl : undefined, 
                    /** Variable que controla el spinner de carga  */
                    loading: true,                     

                    /** variable de control del debounce (diferimiento ) */
                    debounce: null,

                    /**
                     * Variable donde estan los datos que efectivamente se ven en la tabla 
                     * una vez aplicados todos los filtros 
                     */
                    datos: undefined, 
                                        
                    /**
                     * Variable donde estan los datos que efectivamente se ven en la tabla 
                     * una vez aplicados todos los filtros 
                     */
                    filtrados: undefined, 
                    disponibles : undefined, 
                    editable : undefined, 
                    campos : undefined, 

                    /**     
                     * Rermino de busqueda que introducimos en el filtro libre 
                     */                    
                    termino: undefined, 

                    /** 
                     * Datos del dispositivo, son globales, controlamos los eventos de 'window' 
                     * para responder a los cambios de tamaño de ventana, orientacion...  
                     */                    
                    device: this.$device, 

                }
            },  
            async updated(){
                window.fake_console('PriceProtectionEditable','en el update 245')
                const route = useRoute();
                window.fake_console('PriceProtectionEditable',this.ruta, route.path)
                if (route.fullPath!=this.ruta)
                {
                    this.loading = true;
                    window.fake_console('PriceProtectionEditable','llamo al update ')
                    this.ruta = route.fullPath
                    await this.procesoInicial()
                    this.actualizacion()
                }         
                else {
                    window.fake_console('PriceProtectionEditable','NO llamo al update')
                }       
            },
            async mounted() {
                const route = useRoute();
                this.ruta = route.fullPath
                window.fake_console('ProhabGrupo',route.path, route.query)
                await this.procesoInicial()
            }, 
            methods :{
                async validar() {

                }, 

                async procesoInicial() {
                    this.loading = true;
                    this.device = this.$device 
                    eventBus().emitter.on('device', (sz) => {  this.device=sz })          
                    this.fetchData()
                    
                }, 
                gridUpdate() {
                    const theGrid = Control.getControl(this.$refs.theGrid.$el);
                    theGrid.collectionView.refresh();
                },

                isEditable(cname){
                    /** no esta en la lista de editables  */
                    return this.editable.indexOf(cname)==-1
                },
                onloadedRows(grid) {
                    grid.rows.forEach(row => {
                        const dt = row.dataItem; 
                        row.cssClass= dt.a=='D' ? "tachar" : dt.a =='X' ? '' : 'cambiado'
                    })
      
                 },      
                onDeleteCell(e,ctx) {
                    const dt = ctx.item
                    const row = ctx.row
                    if (dt.a == 'X' && dt.edit!=1 && dt.ms_uuid==undefined)
                    { void(0) }
                    else {
                        dt.a = dt.a!='D' ? 'D' : dt.edit===1 ? dt.act : 'X'
                        row.isReadOnly = dt.a == 'D' ;
                        row.cssClass= dt.a=='D' ? "tachar" : dt.a =='X' ? '' : 'cambiado'
                        this.cambios = pluck(this.datos,'a').filter((z)=>{return z!='X'}).length
                        window.fake_console(this.cambios)
                    }
                    
                }, 

                onEditCell(grid,e) {
                    const dt = grid.rows[e.row].dataItem
                    const row = grid.rows[e.row]
                    dt.edit = 1 
                    dt.a =  dt.ms_uuid == undefined ? 'I' : 'U'
                    row.cssClass= 'cambiado'
                    this.cambios = pluck(this.datos,'a').filter((z)=>{return z!='X'}).length
                    window.fake_console(this.cambios)
                }, 


                
                async fetchData(c){ 
                    this.loading = true
                    const url =`/buy/price-protection-todas`
                    window.fake_console('PriceProtectionEditable',url, this.fetchUrl)
                    if (url !=this.fetchUrl ||c!=undefined)
                    {
                        
                        this.fetchUrl = url
                        const result = await this.$http.get(url) 
                        window.fake_console('PriceProtectionEditable','Es cache?? : ',result.request.fromCache)
                        window.fake_console(result.data)
                        this.editable = result.data.editable 
                        const data = result.data.data 
                        this.datos = data .reduce((m,v)=> {
                            v['a']='X';
                            v['i']= 'fa fa-trash'
                            m.push(v);return m} ,[])
                        await this.tratamientoDatos()
                    }
                    this.loading = false
                }, 
                debounceSearch() {
                    clearTimeout(this.debounce)
                    this.debounce = setTimeout(() => { this.tratamientoDatos()}, 250)
                },

                async tratamientoDatos() {
                    let filtrados = this.datos
                    if (this.termino!=undefined && this.termino.length>=2){
                        this.termino.toLowerCase().split(' ').forEach(t=>
                            filtrados = filtrados.filter((v)=> {return JSON.stringify(v).toLowerCase().indexOf(t)!=-1})
                        )
                    }
                    if (this.vista.id ==='C') {
                        filtrados = filtrados.filter(x =>x.a!='X')
                    }
                    this.disponibles = filtrados.length
                    this.vista = await this.vistas.filter((i)=>{window.fake_console(i); return i.id=='A'})[0]
                    this.filtrados =  new CollectionView(filtrados, { pageSize: this.filas_paginas});
                }, 
                
                async descartar() {
                    this.fetchData(1)
                },
                async go_actuals() {
                    const pdv = (this.codemp =='000' && this.codmar== '999') ? '012' : this.codemp 
                    const marca = this.codemp!='000' ? '999' : this.codmar 
                    window.fake_console(this.codemp, this.codmar, pdv, marca)
                    this.$router.replace(`/prohab-grupo/list/${pdv}/${marca}?q=${Date.now()}`)
                }, 

                async cambiarPuntoVista(){
                    this.vista = await this.vistas.filter((i)=>{window.fake_console(i); return i.id=='C'})[0]
                    await this.tratamientoDatos()
                }, 

                async grabar() {
                    var self = this
                    if ( this.vista.id =='A')
                    {
                        openAlerta(
                            'Antes de seguir...',
                            'Vas a actualizar datos de asignacion de proveedor interno a marcas, antes de hacerlo dale un vistado a los cambios.' 
                             , ()=>{
                                self.cambiarPuntoVista()
                             })
                    }
                    else 
                    {
                        const campos =  difference(this.campos, ['ms_uuid','usr'])
                        const editables = union(this.editable,['ms_uuid'])
                        const fecha = new Date().toISOString().slice(0,10)
                        const nuevos = groupBy(this.datos.filter(x =>x.a!='X'),'a')
                        nuevos['D'] = chain(nuevos['D']).pluck('ms_uuid').compact().value()
                        nuevos['U'] = chain(nuevos['U']).map((v)=>pick(v,editables)).value()
                        nuevos['I'] = chain(nuevos['I']).map((v)=>{
                            const c = pick(v,campos);
                            c['last_update']= fecha;
                            return c }).value()
                        const url = `/buy/maintain-prohab-grupo/${this.codemp}/${this.codmar}`
                        const post = await this.$http.post(url, omit(nuevos,'X')) 
                        if (post.data.status && post.data.status ===false ) {
                                openAlerta('Error proveedores internos', 'Algo no hay ido bien, contacta con ATI...',
                                ()=> this.go_actuals() )
                         }
                        else {
                            const texto = this.codemp == '000' ? 'de la marca' : 'del pdv'
                            openToast('Edicion proveedores internos',`Tus cambios de proveedor interno han sido grabados, te llevamos al listado principal ${texto}`)
                            this.go_actuals()
                        }

                    }

                }               
            }
            
})
</script>


<style scoped lang="scss">
     @import '@/theme/listas-editables.scss';
</style>