<template>
    <ion-page>
        <!--VERSION PEDIDO UNICO-->
        
        <ion-header color="primary" >
            <ion-toolbar color="primary" >
                <ion-title class="ion-padding">
                    {{titulos[act]}} 
                </ion-title>
            </ion-toolbar>
            
        </ion-header>
        <ion-content v-if="loading" class="ion-padding">
            <ion-row v-if="loading" class="spin ion-justify-content-center "  >
                <ion-col  v-if="loading" class="spin ion-align-self-center" size="12" > 
                    <ion-spinner color="primary" v-if="loading"></ion-spinner>
                </ion-col>  
            </ion-row>
        </ion-content>
        <ion-content v-if="items && !loading" class="ion-padding">
            <ion-row>
                <ion-title>Vas a {{claves[act]}} los siguientes traspasos: </ion-title>
                <ul class="indentado">
                    <li v-for="(item, index) in items" :key="index" class="traspasos"> 
                        {{item.id}} - {{item.usu}} 
                    </li>
                </ul>

            </ion-row>

            <ion-row>
                <p class="grande" v-html="mensajes[act]"></p>

                <ul class="indentado">
                        <li class="acciones">
                            <b>Cerrar</b> : Cerraras el formulario sin cambios
                        </li>
                        <li class="acciones">
                            <b>Aceptar</b> : {{subtitulos[act]}} 
                        </li>
                </ul>
            </ion-row>

        </ion-content>
        <ion-footer >
            <ion-toolbar class="full"  color="blanco">
                <ion-buttons slot="end">
                    <ion-button @click="cerrar()" color="danger" expand="block" fill="solid" >
                        <ion-icon :md="closeCircle"></ion-icon>
                        Cerrar
                    </ion-button>
                    <ion-button @click="editar_estados()" color="success"  expand="block" fill="solid" >
                        <ion-icon :md="checkmarkCircle"></ion-icon>
                        Aceptar
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-footer>

    </ion-page>
</template>

<script>
    import {    
            IonPage, IonContent,IonRow,IonButton, IonHeader,IonFooter,IonTitle, IonCol, 
            IonIcon,IonButtons,IonToolbar, IonSpinner,
            } from '@ionic/vue';
    import { defineComponent } from 'vue';    
    import { pick } from 'underscore';
    import { closeCircle, checkmarkCircle } from 'ionicons/icons';
    import {openAlerta} from  '@/utilities/global_functions';
    import eventBus from "@/event-bus";
    export default defineComponent({
        name : "GestorOrderMultiActionModal",
        components: {  
            IonPage,  IonContent,IonRow,  IonHeader,IonFooter,IonTitle,IonCol,
            IonButton, 
            IonIcon,IonButtons,IonToolbar, IonSpinner,
        },
        props : {
            items: Array, 
            act: String
        }, 
        mounted() {
                this.items.forEach(v => v.answer = undefined)
           }, 
        data() {    
                return {
                    closeCircle, checkmarkCircle,
                    loading : false, 
                    mensajes :{
                        'a': 'Se realizará el traspaso entre sucursales en Gestión 2000<br/>No se realizará notificacion a los usuarios',
                        'r': 'Se anulará la petición de traspaso. No se notificará por email a los usuarios afectados',
                    },
                    claves :{
                        'a':'Traspasar', 
                        'r':'Rechazar'
                    },
                    titulos : {
                        'a': 'Traspasar Stock de operaciones',
                        'r':'Rechazar traspaso de stock de operaciones',
                    }, 
                    subtitulos : {
                        'a':'Realizaras el traspaso ', 
                        'r':'Rechazaras el traspaso ',
                    }
                }
        },
        methods: {
            async cerrar() {
                eventBus().emitter.emit('cerrar-edicion-traspaso', undefined)
            },
            async editar_estados(){
                const action = this.act
                this.loading = true;
                const data = this.items.reduce((m,v)=> {
                    const k = pick(v, ['codemp','numpet']);
                    m.push(k);
                    return m; 
                } , [])
                const ap = await this.$http.post(`/buy/traspasos-update-header/${action}`, {data:data})
                window.fake_console({action,data})
                const respuesta = ap.data
                window.fake_console(respuesta)
                if (respuesta.ok !=undefined){
                    const respuestas = respuesta.ok
                    const confirmacion = respuestas[false].length==0
                    if (confirmacion) {
                        openAlerta('Actualizacion Correcta!', 'Todos los traspasos han sido actualizados', ()=>{ eventBus().emitter.emit('cerrar-edicion-traspaso', true) })    
                    }
                    else  {
                        const errores = respuestas[false].map(v=> {return `<li>${v}</li>`}).join(' ')
                        const msg = `<ul>Los siguientes traspasos no han podido ser actualizados.: ${errores}<br> Intentalo más tarde</ul>`
                        openAlerta('Uff, algún traspaso ha fallado!', msg, ()=>{ eventBus().emitter.emit('cerrar-edicion-traspaso', true) })    
                    }
                }
                else {
                    openAlerta('Error en la actualización!', respuesta.ko, ()=>{})
                }
                this.loading = false;
            }
        }
})
</script>

<style scoped>
    .ok {
        color: var(--ion-color-success)
    }
    .ko {
        color: var(--ion-color-danger)
    }
    .grande {
        padding: 0px 16px !important;
        font-size: larger !important; 
        font-weight: 700 !important;
        color: var(--ion-color-dark) !important;

    }
    .negrita { font-weight: bold;}
    ion-title {
        width:100% !important; 
        padding-left: 16px !important; 

    }
    .full {
        width:100% !important;
    }
    ul {
        padding-left:32px !important;
    }
    li.acciones {
        padding: 6px  15px !important; 
    }
    li.traspasos {
        padding: 2px  15px !important; 
    }
    .padded {
        padding-left:40px !important;
        font-weight: bold;
    }
    .peque {
        font-size: 0.8rem;
    }
    
    ion-buttons {
        width: 50% !important; 
        float: right !important; 
    }
    ion-button {
        width: 50% !important; 
        margin-left:6px !important; 
        margin-right:6px !important; 
    }
</style>