/** Constantes globales utilizadas en el conjunto de la aplicación  */

/** Delay de actualización (datos de disco, no actualizamos desde server) */
const DELAY = 1800000 // 1000 
const PDVS_FLUIDS = ['032','099','127','129','132','133','134','135']
const CARGO_TRANSPORTE = 0.0175

/**
 * Nombres asociados a los distintos ejes (como se presentan)
 */

    const etiquetas = {
        'marcas' : 'Marcas', 'fabs' : 'Fabricantes', 
        'pdvs' : 'Pdvs', 'drvs' : 'Drvs',  'zonas' : 'Zonas', 
        'plines' : 'Product Lines'
    }
/** titulos de pagina en función del esquema de datos utilizado  */
    const titulos =  {
        'sufficiency': 'Sufficiency pdvs (<%= ultMes %>)', 
        'stock': 'Stocks (<%= ultDia %>)', 
        'ajustes' : 'Regularizaciones inventario (<%= ultDia %>)',  
        'outlet' : 'Detalle Outlet (<%= ultDia %>)',  
        'weg' : 'Provision Würth (<%= ultMes %>)', 
        'top_dx': 'Top simulados Pdvs (<%= ultDia %>)',   
        'top_dg': 'Top simulados Würth (<%= ultDia %>)'
    }
/** Estrategias articulos provision wurth */

const estrategias = {
    "01" : "01.Fast Runners", 
    "02" : "02.Nuevos incorporados l3m", 
    "03" : "03.Nuevos on-watch", 
    "04" : "04.Nuevos sin movimiento", 
    "05" : "05.Acelerar salida", 
    "06" : "06.Ajustar compra", 
    "07" : "07.Resto", 
    "08" : "08.Resto sin venta ltm", 
    "09" : "09.Scrap", 
    "10" : "10.Resueltos"
}
/** colores de headers y footers en función del esquema utilizado */

    const colores_titulos = {
        'sufficiency': 'primary', 
        'stock': 'darksuccess', 
        'ajustes' : 'secondary', 
        'outlet' : 'danger',
        'weg' : 'darkdanger', 
        'top_dx': 'primary', 
        'top_dg': 'darkdanger' 

    }
/**
 * Propiedades asociadas a cada eje , es decir que campo de datos se ha de utilizar 
 * para filtrar en funcion de cada propiedad 
 */    

    const propiedadEje = {
        'marcas': 'm', 'fabs': 'm', 
        'pdvs' :'p' , 'drvs' :'p' , 'zonas' :'p', 
        'plines' :'l'
    }

/** 
 * Colores que aplicamos a los badge que muestran los valores seleccionados en los filtros 
 * Lo hacemos para poder distinguir que estan filtrando de forma visual, en especial para distinguir 
 * marcas y fabricantes que en muchos casos se llaman igual 
 */
    const colores_badges = {
        'marcas' : 'primary', 'fabs' : 'tertiary', 
        'pdvs' : 'primary', 'drvs' : 'secondary',  'zonas' : 'tertiary'
    }

/** 
 * Nombres de las variables que vienen en la descarga de datos de sufficiency 
 */
    const nombres_campos = {
        "sufficiency" :
            {   "p": "Pdv" , "m": "Marca" ,
                "ar": "Referencias", "su_a":"Unidades Mes Ant.", "su_c":"Uds. Stock", 
                "sv_a":"Valor Mes Ant.", "sv_c":"Valor Stock", 
                "vn_c":"Venta Ltm", "co_c": "Compra Ult.15d", 
                "roap_c":"% Provi",  "pc":"Provision", 
                "dm":"P&L Mes","dy":"P&L Ytd", "dl":"P&L Ltm", 
                "wc" :"% Provision Efectivo", 
                "nu_c" : "Meses fin «Nuevo»", 
                "vn_u": "Ultima Venta", "co_u":"Ultima compra"
            }, 
        "stock": 
            { 
                "p": "Pdv" , "m": "Marca" ,
                "ar": "Referencias", 
                "vc" : "Valor Stock", "vm":"Valor Mes Ant.", "vy":"Valor Inicio Año",
                "sc" : "Unids. Stock", "sm":"Unids. Mes Ant.", "sy":"Unids. Inicio Año",
                "ec" : "Pdte. Servir", "em":"Pdte. Servir Mes Ant.", "ey":"Pdte. Servir Inicio Año",
                "rc" : "Pdte. Recibir", "rm":"Pdte. Recibir Mes Ant.", "ry":"Pdte. Recibir Inicio Año", 
                "dm" : "Delta Mes", "dy" : "Delta ytd", 
                "use": "Uds. Medias Semana", "vn_x":"Venta Ltm", 
                "nu_x" : "Meses fin «Nuevo»"
            },
        "weg" :
            { 
                "p": "Pdv" , "m": "Marca" ,
                "ar": "Referencias", 
                "su_c":"Uds. Stock", "vn_c":"Venta Ltm",
                "sv_c":"Valor Stock", "roap_c":"% Provi",  
                "pc":"Provis.", "pa": "Provi. Ant", "pl": "Provis. Ltm", "po": "Provi.Inicio", 
                "t": "Tendencia", "e": "Estrategia", 
                "c6_c": "Compra L6M", "r6_c": "Regulariz. L6M", "d6_c": "Devol. L6M", 
                "dm":"P&L Mes","dy":"P&L ytd", "dl":"P&L Ltm", 
                "vn_u": "Ultima Venta", "co_u":"Ultima compra"

            }, 
        "ajustes": 
            {
                "p": "Pdv" , "m": "Marca" ,
                "ar": "Referencias", 
                "da": "Valor Mes-1", "dd": "Valor Dia", "dl": "Valor Ltm", "dm": "Valor mes", "dy": "Valor ytd",
                "sa": "Uds Mes-1","sd": "Uds Dia","sl": "Uds Ltm","sm": "Uds mes","sy": "Uds ytd"
            }, 
        "outlet" :
            {
                "p" : "Pdv" , "m":"Marca", "ar" : "Articulo","nm" : "Nombre articulo", "l": "Product line",
                "pv" : "Precio", "up" : "Unidad de precio", "ud": "Unidades Disponible", "vd": "Valor Disponible", 
                "dt": "Descuento s/pmp" , "pm":"Pmp"
            }
    }

/**
 * Columnas que queremos que se vean en la tabla para los datos de sufficiency en función del tamaño de la pantalla 
 */
    const columnas_tipo = {
        "sufficiency": 
            {        
                'sm' : ['p','m','ar','sv_c', 'pc','dm'], 
                'md' : ['p','m','ar','su_c','sv_c','vn_c', 'pc','dm'],  
                'lg' : ['p','m','ar','su_c','sv_c','vn_c', 'co_c', 'nu_c','pc','dm','dy'],  
                'xl' : ['p','m','ar','su_c','sv_c','vn_c', 'co_c', 'roap_c','nu_c','pc','dm','dy','vn_u', 'co_u']
            },
        "stock": 
            {        
                'sm' : ['p','m','ar','vc', 'dm','dy'], 
                'md' : ['p','m','ar','vc', 'sc','dm','dy','rc','ec'],  
                'lg' : ['p','m','ar','vc', 'sc','dm','dy','rc','ec','ey'],  
                'xl' : ['p','m','ar','vc', 'sc','dm','dy','rc','ec','ry','ey','use','vn_x','nu_x']
            },
        "weg": 
            {        
                'sm' : ['m','ar','sv_c', 'pc','dm'], 
                'md' : ['m','ar','su_c','sv_c','vn_c', 'pc','dm'],  
                'lg' : ['m','ar','su_c','sv_c','vn_c', 'c6_c', 'pc','dm'],  
                'xl' : ['m','ar','su_c','sv_c','vn_c', 'c6_c', 'e', 'd6_c',  'r6_c', 'roap_c','pc','dm','vn_u', 'co_u']
            }, 
        "ajustes": 
            {
                'sm' : ['p','m','ar','sd', 'dd','dm'], 
                'md' : ['p','m','ar','sd', 'dd','sm','dm'],  
                'lg' : ['p','m','ar','sd', 'dd','sm','dm','sa','da'],  
                'xl' : ['p','m','ar','sd', 'dd','sm','dm','sa','da'],                  
            }, 
        "outlet" :
            {  
            'sm': ['p','m','l','ar','ud', 'pv','up'], 
            'md': ['p','m','l','ar','ud','vd', 'pv','up', 'dt'], 
            'lg': ['p','m','l','ar','ud', 'vd', 'pv','up', 'dt', 'pm'], 
            'xl': ['p','m','l','ar','nm', 'ud', 'pv','up', 'dt', 'pm']
            }

        }
    const columnas_ajuste = {
        'top_dd':{
            'sm' : ['p','m','ar','sd', 'dd','dm'], 
            'md' : ['p','m','ar','sd', 'dd','sm','dm'],  
            'lg' : ['p','m','ar','sd', 'dd','sm','dm','sa','da'],  
            'xl' : ['p','m','ar','sd', 'dd','sm','dm','sa','da'],  
            }, 
        'top_dm':{
            'sm' : ['p','m','ar','sm','dm','da'], 
            'md' : ['p','m','ar','sm','dm','sa','da'],  
            'lg' : ['p','m','ar','sm','dm','sa','da','dy'],  
            'xl' : ['p','m','ar','sm','dm','sa','da','sy','dy'],  
            }, 
        'top_dy':{
            'sm' : ['p','m','ar','sy','dy','da'], 
            'md' : ['p','m','ar','sy','dy','sa','da'],  
            'lg' : ['p','m','ar','sy','dy','sa','da','sm'],  
            'xl' : ['p','m','ar','sy','dy','sm','dm','sa','da'],  
            }, 
        'top_da':{
            'sm' : ['p','m','ar','sa','da','dm'], 
            'md' : ['p','m','ar','sa','da','sm','dm'],  
            'lg' : ['p','m','ar','sa','da','sm','dm','dy'],  
            'xl' : ['p','m','ar','sa','da','sm','dm','sy','dy'],  
            }, 
    }

/**
 * Variable que define la prioridad de cada filtro en caso de que no se puedan ver todos. 
 * Por ejemplo, si  la pantalla solo admite 2 filtros, 
 * cogerá los dos primeros (una vez excluidos los puntos de vista)
 * Los presentará siguiendo el orden que establezca la propiedad 'filters' 
 * en la constante filtros_activos ( ver esquema más adelanta siguiente ) para ese formato de vista
 */    
 
    const priorizacion_filtros = ['pdvs','marcas','plines','drvs','fabs','zonas']


/**
 * Filtros máximos que podemos ver en función del tamaño de pantalla 
 * Es el número total de filtros (distintos del punto de vista) posibles  
 */
    const numberOfFilters = {'sm':2, 'md':2,'lg':8, 'xl':8}


/**
 * Opciones de filtros en función de cada formato de uso 
 *  single / multiple (solo 1 pdv en scope o varios)
 *  eje de entrada en el listado (pdvs, marcas o plines)
 */

    const filtros_activos = {
        'single':
            {
                'pdvs': {
                    'filters' : ['plines', 'marcas', 'fabs'], 
                    'queryFilter': undefined, 
                    'pov': undefined
                    }, 
                'marcas': {
                    'filters' : ['plines', 'marcas', 'fabs' ], 
                    'queryFilter': 'marcas', 
                    'pov': undefined
                    }, 
                'plines': {
                    'filters': ['plines', 'marcas', 'fabs'], 
                    'queryFilter': 'plines', 
                    'pov': undefined
                }
            },
        'multi':
            {
                'pdvs': {
                    'filters' :  ['marcas','plines', 'fabs'], 
                    'queryFilter': undefined, 
                    'pov': 'pdvs'
                    }, 
                'marcas': {
                    'filters' : ['pdvs','plines', 'drvs','zonas' ], 
                    'queryFilter': undefined, 
                    'pov': 'marcas'
                    }, 
                'plines': {
                    'filters': ['pdvs','marcas', 'drvs','fabs','zonas' ], 
                    'queryFilter': undefined, 
                    'pov': 'plines'
                }
            }
        }
    
    
     

export {
    etiquetas, nombres_campos , columnas_tipo , colores_badges, 
    filtros_activos, numberOfFilters , priorizacion_filtros, propiedadEje, 
    titulos, colores_titulos, columnas_ajuste, estrategias, DELAY, PDVS_FLUIDS , 
    CARGO_TRANSPORTE
}

