<template>
  <ion-app>
    <ion-split-pane content-id="main-content" when="$device.ancho>1500">

    <!-- CONTENIDO NUEVO
    <Menu :usr="usuario"  />
    -->
    <Menu />
        <!-- Aqui incorporaremos el contenido de las navegaciones  -->
        <ion-content id="main-content" scroll-y ="true" >
          <router-view ></router-view>
        </ion-content>
    </ion-split-pane>
  </ion-app>

</template>

<script>
import { IonApp, IonContent , alertController, IonSplitPane
  } from '@ionic/vue';
import Menu from '@/components/Menu.vue'
import { defineComponent } from 'vue';
import eventBus from "@/event-bus";

export default defineComponent({
  name: 'App',
  data() {
    return {
      usuario: this.$current_user_name, 
      deferredPrompt: null, 
      intentos : 0 
    }
  },  
  components: {
    IonApp,  IonContent, IonSplitPane, 
    Menu
  }, 
   methods: {
    async openIosAlert() {
      const ancho_imagen = 1072
      const largo_imagen = 1775 
      const ancho_screen = window.screen.width
      const ancho = parseInt(ancho_screen*0.85)
      const alto = parseInt(largo_imagen*ancho/ancho_imagen)
      window.fake_console('app ',alto,ancho)
      const alerta = await alertController
        .create({
          cssClass: 'my-custom-class',
          message: `<img src="assets/tool-tip-install-ios.png" style="width:${ancho} !important; height:${alto} !important; border-radius: 2px">`,
          mode : "md",
          buttons: [
            {
              text: 'Ok',
              handler: () => { 
                window.fake_console('app ','Confirm Okay')
                },
            },
          ]
        })
      return alerta.present();
    }, 
    async openInstallPromotion() {
      const toast = await alertController
        .create({
          header: 'Instala la aplicación ',
          message: 'Irá mucho más rápida y la tendrás accesible desde tu pantalla principal sin necesidad de entrar en Chrome.',
          position: 'middle',
          buttons: [
            {
              text: 'Si',
              //side: 'end',
              icon: 'checkmark-circle-sharp',
              handler: () => {
                window.fake_console('app ','Favorite clicked');
                this.intentos+=1
                this.install();
                toast.dismiss()
              }
            }, {
              text: 'No',
              //side: 'end',
              icon: 'close-circle-sharp',					
              role: 'cancel',
              handler: () => {
                this.dismiss()
                window.fake_console('app ','Cancel clicked');
              }
            }
          ]
        })
      return toast.present();
   },
    async dismiss() {
      window.fake_console('app ','El usuario no ha querido ')
      this.deferredPrompt = null;
    },
    async install() {
      window.fake_console('app ','Instalando.... ')
      this.deferredPrompt.prompt();
    }, 
    getDevice() {
      const w = window.innerWidth 
      const h = window.innerHeight 
      return { 'ancho': w , 'largo' : h , 
        'tamanyo': w < 576 ? 0 : w < 900 ? 1 : w < 1200 ? 2 : 3, 
        'tipo' : w < 576 ? 'sm' : w < 900 ? 'md' : w < 1200 ? 'lg' : 'xl'}
    }
  },

  mounted() {

    /* Deteccion de IOS y propuesta de que el usuario siga pasos de instalación  */
      // Detects if device is on iOS 
      const isIos = () => {
        const userAgent = window.navigator.userAgent.toLowerCase();
        return /iphone|ipad|ipod/.test( userAgent );
      }
      // Detects if device is in standalone mode
      const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);
      // Checks if should display install popup notification:
      if (isIos() && !isInStandaloneMode()) {
        this.openIosAlert()
      }
    // Detecta cambios de ancho
      window.addEventListener('resize', () => {
          eventBus().emitter.emit('device',this.getDevice());
        })

    // Inicialización del tamaño de pantalla y tipo de dispositivo 
        eventBus().emitter.emit('device',this.getDevice());
  }, 
  
  created(){
      /** Control para instalar la app en android  */
      window.addEventListener("beforeinstallprompt", e => {
          e.preventDefault();
          // Stash the event so it can be triggered later.
          this.deferredPrompt = e;
          if (this.intentos==0) {this.openInstallPromotion()}
        });
      window.addEventListener("appinstalled", () => {
          this.deferredPrompt = null;
        });
  }
});
</script>

import

/** General Styles  */
<style lang="scss">


  @import "@/theme/wijmo-styles.scss";

  @import "@/theme/wijmo-styles-rac.scss";
  @import "@/theme/main-styles.scss";
  @import "@/theme/primevue-styles.scss";
  
</style>
