<template>
   <ion-page :style="fontSize" >
        <!-- <CabeceraComponent :titulo="titulo_pag" color="primary"/>-->
        
           
        <ion-header color="darksuccess" >
            <ion-toolbar color="darksuccess" >
                <ion-title class="ion-padding">
                    {{titulo_pag}}
                </ion-title>
            </ion-toolbar>
            
        </ion-header>
        
        <ion-content class="ion-padding" v-if="device" >  
 
            
            <ion-row class="ion-justify-content-left ion-align-items-top selectores grande" v-if="loading==false">
                    <ion-col  :size="device.tamanyo == 0 ? 48 : device.tamanyo==1 ? 18 : 24" >
                        <div class="p-input-icon-right  mismo-que-paginador">
                            <InputText type="text" v-model="termino" placeholder="Buscar (min 3 letras)" @input="debounceSearch"/>
                            <i class="pi pi-search" />
                        </div>
                        <div class="nota">
                            <ion-note v-if="disponibles"> {{disponibles}} registros de {{datos.length}} totales, viendo los {{filtrados.length}} primeros </ion-note>
                        </div>
                    </ion-col>
                    <ion-col  :size="device.tamanyo == 0 ? 48 : device.tamanyo==1 ? 30 : 24" >
                        <div class="p-input-icon-right mismo-que-paginador force-right" >
                            <wj-collection-view-navigator
                                class="navegador force-right"
                                headerFormat="Pagina {currentPage:n0} de {pageCount:n0}"
                                :byPage="true"
                                :cv="filtrados" />
                            <DropDown v-model="filas_paginas" :options="paginador"   class="selector_paginas cabecera force-right"
                                v-bind:key="'selector_filas'"  
                                @hide="tratamientoDatos"
                                placeholder="Filas " /> 
                        </div>
                    </ion-col>
            </ion-row>

           <ion-row class="ion-padding-left ion-margin-left grande">
                <ion-toolbar> 
                    <FileUpload mode="basic"  
                        name="demo[]" 
                        :multiple="false" accept=".xls,.xlsx" 
                        :customUpload="true" 
                        :auto="true" 
                        @uploader="myUploader($event)"  
                        :fileLimit="1"
                        chooseLabel="Selecciona un archivo xls o xlsx"  
                        color="primary"
                        ref = "boton_carga"
                        />
                    <ion-buttons slot="end" >
                        <ion-button color="darksuccess"  fill="solid" @click="importar_cambios">
                            <b>Importar</b>
                        </ion-button>  
                        <ion-button color="primary"  fill="solid" @click="descartar">
                            <b>Descartar todo</b>
                        </ion-button>  
                    </ion-buttons>
                </ion-toolbar>

            </ion-row >
            <ion-row  v-if="loading">
                 <wj-flex-grid 
                    :showMarquee="false"
                    :headersVisibility="'Column'"
                    :showSelectedHeaders="'All'"
                    :alternatingRowStep="1"  
                    ref="tempGrid"/>
                    
            </ion-row>
            <ion-row  v-if="!loading">
                <wj-flex-grid 
                    :showMarquee="true"
                    :anchorCursor="true"
                    :headersVisibility="'Column'"
                    :showSelectedHeaders="'All'"
                    :itemsSource="filtrados" 
                    :alternatingRowStep="0"  
                    ref="theGrid" v-if="datos"
                    :cellEditEnded = "onEditCell"
                    :pastedCell = "onEditCell"  
                    >
                    <ion-row class="titulin" >
                        <div v-for="(ck,index) in titulos"  :key="'cx'+index" :style="`width : ${ck.sz}% !important`"  
                            class="wj-header ion-text-center ion-align-self-center">
                            {{ck.gr}}
                        </div>
                    </ion-row>

                    <wj-flex-grid-filter  :filterColumns="['codemp', 'codmar', 'desfam']" />
                        <wj-flex-grid-column v-for="(c,index) in columnas"   
                            :key="'c'+index" :header="c.header" :binding="c.col" 
                            :width="c.width+'*'" 
                            :align="c.align == undefined ? '': c.align"
                            :isReadOnly ="false"
                            cssClass="no-bloqueada"
                            :format="c.format==undefined ? '' : c.format" 
                            :dataMap="c.dmap == undefined ? '' : c.dmap"
                            >
                            <!-- descuentos negativos resaltados-->
                            <wj-flex-grid-cell-template cellType="Cell" v-if="['oa','ob','oc','od','oe','oz','og'].indexOf(c.col)!=-1 "  v-slot="cell">
                                     <span :class="(cell.item[c.col]<=0 ) ? 'peligro' :'' ">
                                        {{formatNumber(cell.item[c['col']],'n1')}}
                                      </span>
                            </wj-flex-grid-cell-template>
                        </wj-flex-grid-column>
                </wj-flex-grid>
                </ion-row>    
        </ion-content>
        


    </ion-page>
</template>

<script>

    import { IonPage, IonContent,   
            IonRow,IonCol,  
            IonToolbar, IonButtons, IonButton, IonNote, 
            IonHeader,IonTitle
     } from '@ionic/vue';
    import { Control } from "@grapecity/wijmo";
    import { CollectionView } from '@grapecity/wijmo';
    import { wjFormat } from '@grapecity/wijmo.vue2.core';
    import * as wjcGridXlsx from '@grapecity/wijmo.grid.xlsx';
    import { defineComponent } from 'vue';
    import { pluck,  chain,  each, map , intersection  } from 'underscore';
    //import {openAlerta ,openToast} from  '@/utilities/global_functions';
    import eventBus from "@/event-bus";
    import { useRoute } from 'vue-router';
    export default defineComponent({
            name : "DescuentosClientesListImportacion",
            components: { 
                IonPage, IonContent, 
                IonRow,IonCol, 
                IonToolbar, IonButtons, IonButton, IonNote, 
                IonHeader,IonTitle
                //CabeceraComponent  
            } , 
            props : ['codemp', 'codmar'], 
            computed :{
                fontSize () { 
                    window.fake_console('ClasificacionesImportador',`--texto-size : ${9+(this.device.tamanyo)}px`)
                    return `--texto-size : ${9+(this.device.tamanyo)}px;`
                }, 
                titulos() {
                    const totales = this.columnas.reduce( (m,v) => {return m+v['width']}, 0 )
                    const titulos =  chain(this.columnas).pluck('grupo').uniq().reduce((m,v)=>{m[v]=0;return m },{}).value()
                    each(this.columnas, (v)=> {titulos[v['grupo']]+=v['width']})
                    const resultado = map(titulos, (v,k)=> { return {'gr': k,'sz':(v/totales*100) }} )
                    return resultado
                }, 
                columnas() {
                    const cols = [
                        { 'grupo' : '' ,  "col": "codemp" , "header": "Pdv",   "width": 0.5},  
                        { 'grupo' : '' ,  "col": "codmar" , "header": "Marca",   "width": 0.5},  
                        { 'grupo' : '' ,  "col": "desfam" , "header": "Desfam",  "width": 1 },  
                        { 'grupo' : 'Objetivo Margen por clasif.' ,  "col": "a" , "header": "A", "format" : "n1", "width": 0.5, "align": "right"},
                        { 'grupo' : 'Objetivo Margen por clasif.' ,  "col": "b" , "header": "B", "format" : "n1", "width": 0.5, "align": "right"},
                        { 'grupo' : 'Objetivo Margen por clasif.' ,  "col": "c" , "header": "C", "format" : "n1", "width": 0.5, "align": "right"},
                        { 'grupo' : 'Objetivo Margen por clasif.' ,  "col": "d" , "header": "D", "format" : "n1", "width": 0.5, "align": "right"},
                        { 'grupo' : 'Objetivo Margen por clasif.' ,  "col": "e" , "header": "E", "format" : "n1", "width": 0.5, "align": "right"},
                        { 'grupo' : 'Objetivo Margen por clasif.' ,  "col": "z" , "header": "Cont.", "format" : "n1", "width": 0.5, "align": "right"},
                        { 'grupo' : 'Objetivo Margen por clasif.' ,  "col": "g" , "header": "GGCC", "format" : "n1", "width": 0.5, "align": "right"},
                        { "grupo" : "Margen Alerta" ,  "col": "marmin" , "header": "Alerta", "format" : "n1", "width": 0.5, "align": "right"},
                        { "grupo" : "Margen Alerta" ,  "col": "marblq" , "header": "Bloqueo", "format" : "n1", "width": 0.5, "align": "right"},
                        { 'grupo' : 'Formato' ,  "col": "tipo" , "header": "Tipo", "width": 0.7, "align": "left" ,"dmap": ["MARG",'DTO'] } , 
                        { 'grupo' : 'Borrar' ,  "col": "borrar" , "header": "Borrar", "width": 0.7, "align": "left" ,"dmap": [null,undefined,0,1] } , 
                        ]
                    return cols
                }
            },
            data() {    
                return {
                    titulo_pag:"Importacion condiciones excel" , 
                    filas_paginas : 15, 
                    paginador : [15,30,50,100],

                   /** Variable de control para actuar en mount/update */
                    ruta: undefined , 
                    fetchUrl : undefined, 
                    /** Variable que controla el spinner de carga  */
                    loading: true,                     

                    /** variable de control del debounce (diferimiento ) */
                    debounce: null,

                    /**
                     * Variable donde estan los datos que efectivamente se ven en la tabla 
                     * una vez aplicados todos los filtros 
                     */
                    datos: [], 
                                        
                    /**
                     * Variable donde estan los datos que efectivamente se ven en la tabla 
                     * una vez aplicados todos los filtros 
                     */
                    filtrados: undefined, 
                    disponibles : undefined, 
                    editable : undefined, 
                    campos : undefined, 
                    largo: undefined, 


                    /** 
                     * Termino de busqueda que introducimos en el filtro libre 
                     */                    
                    termino: undefined, 

                    /** 
                     * Datos del dispositivo, son globales, controlamos los eventos de 'window' 
                     * para responder a los cambios de tamaño de ventana, orientacion...  
                     */                    
                    device: this.$device, 

                }
            },  
            async updated(){
                window.fake_console('ClasificacionesImportador','en el update 245')
                const route = useRoute();
                window.fake_console('ClasificacionesImportador',this.ruta, route.path)
                if (route.fullPath!=this.ruta)
                {
                    this.loading = true;
                    window.fake_console('ClasificacionesImportador','llamo al update ')
                    this.ruta = route.fullPath
                    await this.procesoInicial()
                    
                }         
                else {
                    window.fake_console('ClasificacionesImportador','NO llamo al update')
                }       
            },
            async mounted() {
                const route = useRoute();
                this.ruta = route.fullPath
                window.fake_console('ClasificacionesImportador',route.path, route.query)
                await this.procesoInicial()
            }, 
            methods :{
                async myUploader(e){
                    var self = this
                    let cols = pluck(this.columnas, 'col')
                    if (e.files) {
                        const tempGrid =  await Control.getControl(this.$refs.tempGrid.$el);
                        await wjcGridXlsx.FlexGridXlsxConverter.loadAsync(
                            tempGrid,
                            e.files[0],
                            {
                                includeColumnHeaders: true 
                                
                            }, 
                            ()=> {

                                const columnas_excel = pluck(tempGrid.columns,'header')
                                const columnas = intersection(cols,columnas_excel)
                                const posiciones = columnas.reduce((m,v)=>{
                                    m[v]= columnas_excel.indexOf(v)
                                    return m
                                },{})
                                const nuevos = tempGrid.rows.reduce( (m,v,k)=> {
                                    const item = {}
                                    each(posiciones , (cv,ck)=>  {
                                        item [ck] = tempGrid.getCellData(k,cv)
                                    })
                                    m.push(item) 
                                    return m 
                                }, []) 
                                self.datos = nuevos 
                                self.filtrados  = new CollectionView(nuevos, { pageSize: self.filas_paginas}); 
                                self.loading = false 
                            }
                        );
                    }

                     
                },
                
                async procesoInicial() {
                    this.device = this.$device 
                    eventBus().emitter.on('device', (sz) => {  this.device=sz })          
                }, 
                formatNumber: function(value, format) {
                    return wjFormat(value, format);
                } ,
               async onEditCell(grid,e) {
                    const dt = grid.rows[e.row].dataItem
                    const row = grid.rows[e.row]
                    dt.edit = 1 
                    dt.ea =  dt.ms_uuid == undefined ? 'I' : 'U'
                    dt['check_dto'] = (dt['tipo']=='MARG' && dt['perc_dto']>50) || (dt['tipo']=='DTO' && dt['perc_dto']<50);
                    row.cssClass= 'cambiado'
                    this.cambios = pluck(this.datos,'ea').filter((z)=>{return z!='X'}).length
                    window.fake_console(this.cambios)
                    
                }, 
                debounceSearch() {
                    clearTimeout(this.debounce)
                    this.debounce = setTimeout(() => { this.tratamientoDatos()}, 250)
                },

                async tratamientoDatos() {
                    let filtrados = this.datos
                    if (this.termino!=undefined && this.termino.length>=2){
                        this.termino.toLowerCase().split(' ').forEach(t=>
                            filtrados = filtrados.filter((v)=> {return JSON.stringify(v).toLowerCase().indexOf(t)!=-1})
                        )
                    }
                    this.disponibles = filtrados.length
                    this.filtrados =  new CollectionView(filtrados, { pageSize: this.filas_paginas});
                    return 1
                }, 
                async importar_cambios(){
                    eventBus().emitter.emit('cerrar-importador-descuentos_abc', this.datos)
                }, 
                async descartar() {
                        eventBus().emitter.emit('cerrar-importador-descuentos_abc', undefined)
                }            
            }
            
})
</script>


<style scoped lang="scss">
    ion-title {
        width:100% !important; 
        padding-left: 16px !important; 

    }
     @import '@/theme/listas-editables.scss';

</style>