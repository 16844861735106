<template>
   <ion-page :style="fontSize" >
        <CabeceraComponent :titulo="titulo_pag" color="primary"/>
        <ion-content class="ion-padding" v-if="device" >  
 
             <ion-row v-if="loading" class="spin ion-justify-content-center "  >
                <ion-col  v-if="loading" class="spin ion-align-self-center" size="12" > 
                    <ion-spinner color="primary" v-if="loading"></ion-spinner>
                </ion-col>
            </ion-row>
            <ion-row class="ion-align-items-top selectores grande" v-if="loading==false">
                    <ion-col  :size="device.tamanyo == 0 ? 48 : device.tamanyo==1 ? 18 : 24" >
                        <div class="p-input-icon-right mismo-que-paginador" >
                            <InputText type="text" v-model="termino" placeholder="Buscar (min 3 letras)" @input="debounceSearch"/>
                            <i class="pi pi-search" />
                        </div>
                        <div class="nota">
                            <ion-note v-if="disponibles"> {{disponibles}} registros de {{datos.length}} totales</ion-note>
                        </div>

                    </ion-col>
                    <ion-col  :size="device.tamanyo == 0 ? 48 : device.tamanyo==1 ? 30 : 24" class="force-right" >
                        <div class="p-input-icon-right mismo-que-paginador force-right" >
                            <wj-collection-view-navigator
                                class="navegador force-right"
                                headerFormat="Pagina {currentPage:n0} de {pageCount:n0}"
                                :byPage="true"
                                :cv="filtrados" />
                            <DropDown v-model="filas_paginas" :options="paginador"   class="selector_paginas cabecera force-right"
                                v-bind:key="'selector_filas'"  
                                @hide="tratamientoDatos"
                                placeholder="Filas " /> 
                        </div>
                    </ion-col>
            </ion-row>
            <ion-row class="ion-justify-content-left ion-align-items-top grande" v-if="loading==false">
                    <ion-toolbar> 
                        <DropDown v-model="selector.pdv" :options="selectores.pdv" optionLabel="n" class="selector_kg"
                            :filter="true"   v-bind:key="'selector_pdv'"  
                            @hide="Go(selector.pdv._id,'999')"
                            placeholder="Pdvs activos" />
                        <DropDown v-model="selector.marca" :options="selectores.marca" optionLabel="n"  class="selector_kg"
                            :filter="true"   v-bind:key="'selector_marcas'"  
                            @hide="Go('000',selector.marca._id)"
                            placeholder="Marcas activas " /> 


                        <ion-buttons slot="end" >
                            <ion-button color="primary"  fill="solid" @click="NuevaCombinacion">
                                <b>Editar...</b>
                            </ion-button>  
                        </ion-buttons>
                    </ion-toolbar>
            
            </ion-row>
           
            <ion-row v-if="!loading">   
                <wj-flex-grid 
                    :showMarquee="true"
                    :anchorCursor="true"
                    :showSelectedHeaders="'All'"
                    :itemsSource="filtrados" 
                    :alternatingRowStep="0"  
                    :headersVisibility="'Column'"
                    :loaded-rows = "onloadedRows"
                    :selectionMode="'None'"
                    ref="theGrid" v-if="datos">
                    <ion-row class="titulin" >
                                <div v-for="(ck,index) in titulos"  :key="'cx'+index" :style="`width : ${ck.sz}% !important`"  class="wj-header ion-text-center ion-align-self-center">
                                    {{ck.gr}}
                                </div>
                    </ion-row>
                    <wj-flex-grid-filter  :filterColumns="['codemp', 'codmar', 'desfam']" />



                        <wj-flex-grid-column v-for="(c,index) in columnas"   
                            :key="'c'+index" :header="c.header" 
                            :binding="c.col" 
                            :width="c.width+'*'" 
                            :align="c.align == undefined ? '': c.align"
                            :isReadOnly ="true"
                            cssClass="no-bloqueada"
                            :format="c.format==undefined ? '' : c.format" 
                            >
                            <!-- Campos que requieren una presentación específica--> 
                            <wj-flex-grid-cell-template cellType="Cell" v-if="c.col=='tipo'"  v-slot="cell">
                                     <span :class="(cell.item['check_dto']===true ) ? 'peligro' :'' ">
                                        {{cell.item.tipo}}
                                      </span>
                                    <i class="peligro fa fa-exclamation-triangle" v-if="cell.item['check_dto']===true"></i>  
                            </wj-flex-grid-cell-template>

                            <!-- descuentos negativos resaltados-->
                            <wj-flex-grid-cell-template cellType="Cell" v-if="['oa','oc','oe','oz'].indexOf(c.col)!=-1 "  v-slot="cell">
                                     <span :class="(cell.item[c.col]<=0 ) ? 'peligro' :'' ">
                                        {{formatNumber(cell.item[c['col']],'n1')}}
                                      </span>
                            </wj-flex-grid-cell-template>

                            <!-- descuentos negativos resaltados-->

                            <wj-flex-grid-cell-template cellType="Cell" v-if="['p_dto_z','perc_dto'].indexOf(c.col)!=-1 "  v-slot="cell">
                                <span v-html="TipoHabitual(cell.item[c['col']])" :class="(cell.item['check_dto']===true ) ? 'peligro' :'' "/>
                            </wj-flex-grid-cell-template>

                        </wj-flex-grid-column>
                        <wj-flex-grid-column
                            key="c_at" header="" 
                            width="0.4*" 
                            align="center"
                            :isReadOnly ="true"
                            cssClass="bloqueada del"
                            :cellTemplate="tplCustomButton"
                            >
                        </wj-flex-grid-column>
                </wj-flex-grid>
                </ion-row>    
        </ion-content>
        


    </ion-page>
</template>

<script>

    import { IonPage, IonContent,   
            IonRow,IonCol,  
            IonToolbar, IonButtons, IonButton, IonNote, 
            IonSpinner, modalController
     } from '@ionic/vue';
    import CabeceraComponent from '@/components/CabeceraComponent'
    import SelectorPdvMarcaModal  from  '@/components/SelectorPdvMarcaModal'
    import { CellMaker } from '@grapecity/wijmo.grid.cellmaker';
    import { CollectionView } from '@grapecity/wijmo';
    import { wjFormat } from '@grapecity/wijmo.vue2.core';
    import { defineComponent } from 'vue';
    import { useRoute } from 'vue-router';
    import { chain,  each , map  } from 'underscore';
    import eventBus from "@/event-bus";
    export default defineComponent({
            name : "DescuentosClientesList",
            components: { 
                IonPage, IonContent,   
                IonToolbar, IonButtons, IonButton, IonNote, 
                IonRow,IonCol, IonSpinner,   
                CabeceraComponent  
            } , 
            props : ['codemp', 'codmar'], 
            computed :{
                fontSize () { 
                    window.fake_console('Clasificaciones',`--texto-size : ${9+(this.device.tamanyo)}px`)
                    return `--texto-size : ${9+(this.device.tamanyo)}px;`
                }, 
                titulos() {
                    const totales = this.columnas.reduce( (m,v) => {return m+v['width']}, 0.4 )
                    const titulos =  chain(this.columnas).pluck('grupo').uniq().reduce((m,v)=>{m[v]=0;return m },{}).value()
                    titulos['Otros']=titulos['Otros']+0.4
                    each(this.columnas, (v)=> {titulos[v['grupo']]+=v['width']})
                    const resultado = map(titulos, (v,k)=> { return {'gr': k,'sz':(v/totales*100) }} )
                    return resultado
                }, 
                columnas() {
                    const cols = [
                        { "grupo" : "" ,  "col": "codemp" , "header": "Pdv",   "width": 0.5, "show" : true },  
                        { "grupo" : "Marca" ,  "col": "codmar" , "header": "Marca",   "width": 0.5, "show" : true },  
                        { "grupo" : "Marca" ,  "col": "nommar" , "header": "Nombre", "width": 1.5, "show" : this.device.tamanyo>2  },  
                        { "grupo" : "Familia Descuento" ,  "col": "desfam" , "header": "Desfam",  "width": 1, "show" : true },  
                        { "grupo" : "Familia Descuento" ,  "col": "nomdf" , "header": "Descripcion ", "width": 1.5, "show" : this.device.tamanyo>1 },  
                        { "grupo" : "Datos Familia" ,  "col": "destot" , "header": "Dto.Compra","format" : "n1", "width": 0.8,"align": "right",  show : true },
                        { "grupo" : "Datos Familia" ,  "col": "factor_tarifa" , "header": "Tarven/Tarcom","format" : "n2", "width": 0.9,"align": "right",  show : true },
                        { "grupo" : "Datos Familia" ,  "col": "act" , "header": "Activa", "format" : "n0", "width": 0.5, "align": "right","show" : true },
                        { "grupo" : "Datos Familia" ,  "col": "ranking" , "header": "Rank ", "format" : "n0", "width": 0.5, "align": "right","show" : true },
                        { "grupo" : "Forma Venta Habitual" ,  "col": "perc_dto" , "header": "Pdv ",  "width": 0.8, "align": "left", "show" : true },
                        { "grupo" : "Forma Venta Habitual" ,  "col": "p_dto_z" , "header": "Zona ", "width": 0.8, "align": "left", "show" : true },
                        { "grupo" : "Margen Medio" ,  "col": "media" , "header": "Pdv", "format" : "n1", "width": 0.5 , "align": "right","show" : true },
                        { "grupo" : "Margen Medio" ,  "col": "z_avg" , "header": "Zona", "format" : "n1", "width":  0.5 , "align": "right","show" : true },
                        { "grupo" : "Objetivo Margen por clasif." ,  "col": "a" , "header": "A", "format" : "n1", "width": 0.5, "align": "right","show" : true },
                        { "grupo" : "Objetivo Margen por clasif." ,  "col": "c" , "header": "C", "format" : "n1", "width": 0.5, "align": "right","show" : true },
                        { "grupo" : "Objetivo Margen por clasif." ,  "col": "e" , "header": "E", "format" : "n1", "width": 0.5, "align": "right","show" : true },
                        { "grupo" : "Objetivo Margen por clasif." ,  "col": "z" , "header": "Cont.", "format" : "n1", "width": 0.5, "align": "right","show" : true },
                        { "grupo" : "Margen Alerta" ,  "col": "marmin" , "header": "Alerta", "format" : "n1", "width": 0.5, "align": "right","show" : true },
                        { "grupo" : "Margen Alerta" ,  "col": "marbloq" , "header": "Bloqueo", "format" : "n1", "width": 0.5, "align": "right","show" : true },
                        { "grupo" : "Condicion G2K por clasificacion" ,  "col": "tipo" , "header": "Tipo", "width": 0.7, "align": "left",  show : true }, 
                        { "grupo" : "Condicion G2K por clasificacion" ,  "col": "oa" , "header": "A", "format" : "n1", "width": 0.5, "align": "right","show" : true },
                        { "grupo" : "Condicion G2K por clasificacion" ,  "col": "oc" , "header": "C", "format" : "n1", "width": 0.5, "align": "right","show" : true },
                        { "grupo" : "Condicion G2K por clasificacion" ,  "col": "oe" , "header": "E", "format" : "n1", "width": 0.5, "align": "right","show" : true },
                        { "grupo" : "Condicion G2K por clasificacion" ,  "col": "oz" , "header": "Z", "format" : "n1", "width": 0.5, "align": "right","show" : true },
                        { "grupo" : "Otros" ,  "col": "usr" , "header": "Usuario ",   "width": 1.4, "align": "left", "show" : this.device.tamanyo>2 },
                        ]
                    return cols.filter(x=>x.show) 
                }
            },
            data() {    
                return {
                    titulo_pag:"Margenes y descuentos por clasificación de cliente" , 
                    filas_paginas : 30, 
                    paginador : [15,30,50,100],
                    selector : {}, 
                    selectores : undefined, 

                    /** Variable que controla el spinner de carga  */
                    loading: true, 
                    /** Boton para el grid de wijmo */
                    tplCustomButton: CellMaker.makeButton({
                        text: '<i class="fa fa-pencil"></i>',
                        click: (e, ctx) => this.$router.replace(`/descuentos-abc/list_editable/${ctx.item.codemp}/${ctx.item.codmar}`) 
                    }),
                   /** Variable de control para actuar en mount/update */
                    ruta: undefined , 
                    fetchUrl : undefined, 

                    /** variable de control del debounce (diferimiento ) */
                    debounce: null,

                    /**
                     * Variable donde estan los datos que efectivamente se ven en la tabla 
                     * una vez aplicados todos los filtros 
                     */
                    datos: undefined, 
                                        
                    /**
                     * Variable donde estan los datos que efectivamente se ven en la tabla 
                     * una vez aplicados todos los filtros 
                     */
                    filtrados: undefined, 
                    disponibles : undefined, 

                    /** 
                     * Termino de busqueda que introducimos en el filtro libre 
                     */                    
                    termino: undefined, 

                    /** 
                     * Datos del dispositivo, son globales, controlamos los eventos de 'window' 
                     * para responder a los cambios de tamaño de ventana, orientacion...  
                     */                    
                    device: this.$device, 

                }
            },  
            async updated(){
                const route = useRoute();
                window.fake_console('Clasificaciones',this.ruta, route.path)
                if (route.fullPath!=this.ruta)
                {
                    this.loading = true;
                    window.fake_console('Clasificaciones','llamo al update ')
                    this.ruta = route.fullPath
                    await this.procesoInicial()
                }         
                else {
                    window.fake_console('Clasificaciones','NO llamo al update')
                }       
            },
            async mounted() {
                const route = useRoute();
                this.ruta = route.fullPath
                window.fake_console('Clasificaciones',route.path, route.query)
                await this.procesoInicial()
            }, 
            methods :{
                onloadedRows(grid) {
                    grid.rows.forEach(row => {
                        const dt = row.dataItem; 
                        row.cssClass= dt.act==0 ? "alerta-warning" :  '' 
                    })
                },
                formatNumber: function(value, format) {
                    return wjFormat(value, format);
                }  , 
                TipoHabitual: function(value){
                    const tipo = value<50 ? 'MARG' : 'DTO' 
                    const valor = value <50 ? 100-value : value 
                    return `${tipo} <span style="font-size: smaller !important;"> ( ${valor}% )</span>`
                },
                async procesoInicial() {
                    /** fallback por si viene la combinación más generica de todas  */
                    if (this.codemp =='000' && this.codmar === '999') {
                        this.$router.replace(`/descuentos-abc/list/001/999?q=${Date.now()}`)
                    }
                    this.loading = true;
                    this.device = this.$device 
                    eventBus().emitter.on('device', (sz) => {  this.device=sz })    
                    this.fetchData()
                }, 
                
                async fetchData(){ 
                    this.loading = true
                    const select_url = `/pricing/clasificaciones-select`
                    const url =`/pricing/clasificaciones-actuales/${this.codemp}/${this.codmar}`
                    window.fake_console('Clasificaciones',url, this.fetchUrl)
                    if (url !=this.fetchUrl)
                    {
                        const selc = await this.$http.get(select_url)
                        this.selectores = selc.data
                        this.selector['pdv'] = this.selectores['pdv'].filter(v=>v._id===this.codemp)[0]
                        /*console.log(this.selectores)*/
                        this.selector['marca'] = this.selectores['marca'].filter(v=>v._id===this.codmar)[0]
                        this.fetchUrl = url
                        const result = await this.$http.get(url) 
                        window.fake_console('Clasificaciones','Es cache?? : ',result.request.fromCache)
                        this.datos = result.data.data.map((v)=> {
                            v['check_dto'] = (v['tipo']=='MARG' && v['perc_dto']>50) || (v['tipo']=='DTO' && v['perc_dto']<50)
                            return v 
                        })
                        this.tratamientoDatos()
                    }
                    this.loading = false
                }, 
                debounceSearch() {
                    clearTimeout(this.debounce)
                    this.debounce = setTimeout(() => { this.tratamientoDatos()}, 250)
                },

                async tratamientoDatos() {
                    let filtrados = this.datos
                    if (this.termino!=undefined && this.termino.length>=2){
                        this.termino.toLowerCase().split(' ').forEach(t=>
                            filtrados = filtrados.filter((v)=> {return JSON.stringify(v).toLowerCase().indexOf(t)!=-1})
                        )
                    }
                    this.disponibles = filtrados.length
                    this.filtrados =  new CollectionView(filtrados, { pageSize: this.filas_paginas});
                }, 
                async Go(codemp,codmar) {
                    //alert(codemp,codmar)
                    const pdv = (codemp =='000' && codmar== '999') ? '001' : codemp 
                    const marca = codmar
                    this.$router.replace(`/descuentos-abc/list/${pdv}/${marca}?q=${Date.now()}`)

                 }, 
                async NuevaCombinacion () {
                    var self = this
                    const modal = await modalController
                        .create({
                                component: SelectorPdvMarcaModal,
                                cssClass: 'custom-modal', 
                                componentProps: { titulo: 'Nueva configuracion de KG' },
                                })
                    eventBus().emitter.on('cerrar-selector-pdv-marca',async (seleccion)=>{
                        modal.dismiss();
                        if (seleccion!=undefined) {  
                            window.fake_console(seleccion)
                            window.fake_console('en la condicion de cambio')
                            self.$router.replace(`/descuentos-abc/list_editable/${seleccion.pdv}/${seleccion.marca}`) 
                            
                        }    
                    });
                    modal.present();
                }, 
            }
            
})
</script>

<style scoped lang="scss">
     @import '@/theme/listas.scss';
</style>