<template>
   <ion-page :style="fontSize" >
        <CabeceraComponent :titulo="titulo_pag" color="primary"/>
        <ion-content class="ion-padding" v-if="device" >  

                
            <ion-row v-if="loading" class="spin ion-justify-content-center "  >
                <ion-col  v-if="loading" class="spin ion-align-self-center" size="12" > 
                    <ion-spinner color="primary" v-if="loading"></ion-spinner>
                </ion-col>
            </ion-row>

            <DataTable :value="datos" 
                v-if="!loading && datos"
                v-bind:key="device.tipo && device.ancho"
                :paginator="false" 
                class="editable-cells-table"
                scrollHeight="flex" 
                scrollDirection="vertical" 
                :scrollable="true" 
                removableSort
                :sortable="true"
                ref="datatable_gestor_detail"
                responsiveLayout="scroll"
                :rowClass="Validar_Linea"
                selectionMode="single" 
                editMode="row" dataKey="numlin" v-model:editingRows="editingRows" @row-edit-save="recalcular_linea"
                @rowUnselect="onRowUnselect" 
                @rowExpand="onRowExpand"
                v-model:expandedRows="expandedRows"

                    >
                <template #header>
                <div >
                    <ion-row>
                        <ion-col size="36" >
                            <h3>Detalle del traspaso {{cabecera.id}} del {{$filters.text2Date(cabecera.fecped)}}</h3>
                            <h5>
                                <ul>
                                    <li>Proveedor : {{cabecera.prov}}</li>
                                    <li>Usuario : {{cabecera.usu}}</li>
                                    <li v-if="cabecera.obstra!=null">Nota : {{cabecera.obstra}}</li>
                                    <li>Cantidades mayores a disponible: {{bloqueo_excesos}}</li>
                                </ul>
                            </h5>
                        </ion-col>
                         <ion-col size="12">
                            <ion-toolbar> 
                                <ion-buttons slot="end" size="large">
                                    <ion-button color="success" fill="solid" size="large" 
                                        :disabled="bloqueo_excesos"
                                        @click="updateOrder"> Grabar Cambios</ion-button>                                                       
                                    <ion-button color="danger"  fill="solid"  size="large" @click="goBack"> Salir sin Grabar</ion-button> 
                                </ion-buttons>
                            </ion-toolbar>
                            <ion-button color="danger" fill="outline" size="small" 
                                style="float:right"
                                v-if="bloqueo_excesos">Unidades erroneas</ion-button>
                        </ion-col>
                    </ion-row>
                </div>


                </template>
                <template #loading>
                    Cargando datos de stock. Por favor, espera.
                </template>

                <!-- COLUMNAS DE LA CABECERA--> 
                <ColumnGroup type="header" >
                    <Row :headerStyle="AnchoCol(100)">
                           
                        <!-- Datos Generales --> 
                        <Column :headerStyle="anchos['dummy1']" :bodyStyle="anchos['dummy1']" />
                        <Column header="Linea" :sortable="true" field="numlin" :headerStyle="anchos['numlin']" :bodyStyle="anchos['numlin']" />
                        <Column header="Marca" :sortable="true" field="codmar" :headerStyle="anchos['codmar']" :bodyStyle="anchos['codmar']" />
                        <Column header="Articulo" :sortable="true" field="artpro" :headerStyle="anchos['artpro']" :bodyStyle="anchos['artpro']" />
                        <Column header="Nombre" :sortable="true" field="nomart" :headerStyle="anchos['nomart']" :bodyStyle="anchos['nomart']" />
                        <Column header="Unidades" :sortable="true" field="unidad" :headerStyle="anchos['unidad']" :bodyStyle="anchos['unidad']" />
                        <Column header="Valor" :sortable="true" field="implin" :headerStyle="anchos['implin']" :bodyStyle="anchos['implin']" />

                        <Column header="Stock Suc.99" :sortable="true" field="stoalm" :headerStyle="anchos['stoalm']" :bodyStyle="anchos['stoalm']" />
                        <Column header="Disponible Suc.99" :sortable="true" field="dispo" :headerStyle="anchos['dispo']" :bodyStyle="anchos['dispo']" />

                        <!-- botones de accion --> 
                        <Column header="Num.Lot" :sortable="true" field="numlot" :headerStyle="anchos['numlot']" :bodyStyle="anchos['numlot']" />
                        <Column header="Acciones" :sortable="false" field="act" :headerStyle="anchos['act']" :bodyStyle="anchos['act']" />
                    </Row>  
                </ColumnGroup>

                <!-- COLUMNAS DE DATOS --> 
                <Column :expander="true" :headerStyle="anchos['dummy1']" :bodyStyle="anchos['dummy1']"  />
                <Column v-for="(c, index) of columnas" :field="c" 
                    :key="c + '_' + index" 
                    :sortable="true"
                    :headerStyle="anchos[c]" 
                    :bodyStyle="anchos[c]" 
                    >   

                    <template #body="slotProps" >
                        <!-- campos texto alineacion izquierda-->
                        <div class="izquierda azul" v-if="['codmar','artpro'].indexOf(c)!=-1">
                            {{slotProps.data[c]}} 
                        </div>

                        <div class="izquierda azul" v-else-if="c=='artpro'">
                             <i class="wait fa fa-clock-o" v-if="slotProps.data.est_gest=='P'"></i>  
                             {{slotProps.data[c]}} 
                        </div>

                                                   

                        <div class="derecha azul" v-else-if="['numlot'].indexOf(c)!=-1">
                            {{slotProps.data[c]}} 
                        </div>
                        <!-- campos decimales-->
                        <div class="derecha azul" v-else-if="['numlin','unidad', 'stoalm','dispo'].indexOf(c)!=-1">
                            {{ $filters.Entero(slotProps.data[c])}}
                        </div>
                        <div class="derecha azul" v-else-if="['implin'].indexOf(c)!=-1">
                            {{ $filters.s1Decimal(slotProps.data[c])}}
                        </div>
                    </template>

                    <!-- TEMPLATE PARA EDICION DE DATOS  -->
                    <template #editor="slotProps" v-if="c =='unidad'" >
                        <div class="derecha">
                            <InputNumber class="p-inputtext-sm p-invalid"  v-model="slotProps.data[c]"  />
                        </div>
                    </template>
                </Column>   

                <Column :rowEditor="true" :headerStyle="anchos['act']" 
                    :bodyStyle="anchos['act']" 
                ></Column>

                <!-- DETALLE EXPANDIBLE DE LA REFERENCIA -->
                <template #expansion="slotProps" >
                    <div class="sku-details-subtable"  style=" margin-left: 30% !important; margin-bottom: 0px !important">
                        <DataTable :value="slotProps.data.detalle" 
                            :rowClass="esGrupo"
                            class="p-datatable-detalle p-datatable-sm" 
                            :scrollable="false" 
                            scrollHeight="flex"
                        >
                            <template #loading>
                                Cargando datos de la referencia. Por favor, espera.
                            </template>
                            <Column v-for="(c, index) of ['p',  'sc',   'ec','rc','vc', 'wc', 'wx', 'vn_x', 'vn_u','co_u', 'fse', 'use']" :field="c" 
                                :header="titulos_detalle[c]" :key="c + '_det_' + index+'_'+[device.tipo]" 
                                :headerStyle="ancho_detalle" 
                                :bodyStyle="ancho_detalle"
                                :sortable="false">
                                <template #body="slotProps" >
                                    <div class="izquierda azul" v-if="['p','m','ar'].indexOf(c)!=-1">
                                        {{slotProps.data[c]}} 
                                    </div>
                                    <div class="derecha azul resaltado" v-else-if="['wc','wx'].indexOf(c)!=-1">
                                        {{ $filters.sIntPorcentaje(slotProps.data[c])}} 
                                    </div>

                                    <div class="derecha azul" v-else-if="[ 'fse'].indexOf(c)!=-1">
                                        {{ $filters.sIntPorcentaje(slotProps.data[c])}} 
                                    </div>

                                    <div class="derecha azul" v-else-if="['vn_u','co_u'].indexOf(c)!=-1">
                                        {{ $filters.num2Month(slotProps.data[c])}} 
                                    </div>

                                    <div class="derecha azul" v-else>
                                            {{ $filters.Entero(slotProps.data[c]) }}
                                    </div>
                                </template>
                            </Column>
                        </DataTable>
                    </div>
                </template>


                <ColumnGroup type="footer" >
                    <Row :footerStyle="AnchoCol(100)" >
                           <!--  :footer="$filters.sDecimal(total_original)"-->
                        <Column :footerStyle="anchos_footer['inicio']" >
                            <template #footer>
                                <div class="izquierda azul indentado">Totales (Originales)</div>
                            </template>
                        </Column>
                        
                        <Column  class="derecha azul" :footerStyle="anchos_footer['implin']" >
                            <template #footer>
                                    <div class="derecha azul">
                                    {{ $filters.s1Decimal(total_original)}}
                                </div>
                            </template>
                        </Column>
                        <Column :footerStyle="anchos_footer['resto']" />
                    </Row>          
                    <Row :footerStyle="AnchoCol(100)" >
                        <Column :footerStyle="anchos_footer['inicio']" >
                            <template #footer>
                                <div class="izquierda azul indentado">Nuevo total traspaso tras cambios</div>
                            </template>
                        </Column>
                        <Column  class="derecha azul" :footerStyle="anchos_footer['implin']" >
                            <template #footer>
                                    <div class="derecha azul">
                                    {{ $filters.s1Decimal(nuevo_total)}}
                                </div>
                            </template>
                        </Column>
                        <Column :footerStyle="anchos_footer['resto']" />
                    </Row>          

                </ColumnGroup>

            </DataTable>
        </ion-content>
        


    </ion-page>
</template>

<script>
    import { IonPage, IonContent,   
    IonRow,IonCol,  IonButton, 
    IonSpinner,IonButtons,IonToolbar,
     } from '@ionic/vue';
    import CabeceraComponent from '@/components/CabeceraComponent'
    import {openAlerta} from  '@/utilities/global_functions';
    import { defineComponent } from 'vue';
    import * as _ from 'underscore';
    import eventBus from "@/event-bus";
    import { closeCircle } from 'ionicons/icons';
    import { useRoute } from 'vue-router';
    export default defineComponent({
            name : "TraspasoDetail",
            components: { 
                IonPage, IonContent, 
                IonRow,IonCol, IonButton,
                IonSpinner,IonButtons,IonToolbar,
                CabeceraComponent  
                
            } , 
            props :['codemp','codsuc','numpet'],
            computed :{
                fontSize () { 
                    window.fake_console('GestorList',`--texto-size : ${11+(this.device.tamanyo)}px`)
                    return `--texto-size : ${11+(this.device.tamanyo)}px;`
                }, 
                ancho_detalle() {
                        return `width: ${(this.device.ancho-32)/15}px !important`
                },

                anchos_footer() {
                    const x = this.base_anchos
                    const grupos = {
                        'inicio':x['dummy1']+x['numlin']+x['codmar']+x['artpro']+x['nomart']+x['unidad'],
                        'implin':x['implin'], 
                        'resto': x['stoalm']+x['dispo']+x['numlot'] + x['act']       
                    }
                    const factor = _.reduce(_.values(this.base_anchos), (m,v)=> {return m+=(v/100)},0)
                    const resultado = _.reduce(grupos, (m,v,k)=> {m[k]= this.AnchoCol(v / factor) ;return m },{})
                    return resultado
                },

                anchos () {
                    /** Nos aseguramos que las definiciones de columnas sumen 100, sino aplicamos proporcionalidad  */
                    const factor = _.reduce(_.values(this.base_anchos ), (m,v)=> {return m+=(v/100)},0)
                    window.fake_console(factor)
                    const resultado = _.reduce(this.base_anchos , (m,v,k)=> {m[k]= this.AnchoCol(v / factor) ;return m },{})
                    window.fake_console(this.device.tipo, this.device.ancho,  factor, resultado)
                    return resultado
                    },
            },
            data() {    
                return {
                    base_anchos : { 
                            "dummy1": 2,"numlin" : 2,  "codmar"  : 2 , "artpro"  : 5 , "nomart"  : 8 , "unidad"  : 3 , 
                            "stoalm":3, "dispo": 3, 
                            "numlot"  : 3 , "implin"  : 3 , "act":3
                        },
                    titulos_detalle: {
                            "co_x" : "Compra 15d", "co_u" : "Ult. Compra","ec" : "Pdte. Servir","fse" : "Freq. Venta",
                            "p" : "Pdv","rc" : "Pdte. Recibir","use" : "Uds. Med. Semanal","sc" : "Uds Stock",
                            "vc" : "Valor Stock","vn_x" : "Venta Ltm","vn_u" : "Ult. venta", "wx" : "% Hoy", 
                            "wc" : "% Provision Efectivo Cierre"  , 
                        }, 
                    titulo_pag:"Detalle peticion de traspaso " , 
                    clases: {'edited':'info', 'deleted':'warning', 'added':'success'}, 
                    paginador: false, 
                    editingRows: [],
                    expandedRows: [] ,
                    filas_actualizar: {},

                    /** Iconos, se han de declara en data para poder ser utilizados  */
                    closeCircle,                     
                    columnas :["numlin","codmar","artpro","nomart","unidad","implin","stoalm","dispo","numlot"],
                    tiempo: undefined,


                   /** Variable de control para actuar en mount/update */
                    ruta: undefined , 
                    fetchUrl : undefined, 
                    filas: undefined , 
                    /** Variable que controla el spinner de carga  */
                    loading: true,  
                    inicio: undefined, 
                    total_original : undefined, //total original del traspaso 
                    nuevo_total: undefined, //total tras cambios 
                    bloqueo_excesos: false, // Bloqueo en caso de que haya lineas con más unidades que disponible 
                    

                    /** variable de control del debounce (diferimiento ) */
                    debounce: null,

                  
                    /**
                     * Variable donde estan los datos que efectivamente se ven en la tabla 
                     * una vez aplicados todos los filtros 
                     */
                    datos: undefined, 
                    cabecera: undefined, 
                                        
                    /**
                     * Variable donde estan los datos que efectivamente se ven en la tabla 
                     * una vez aplicados todos los filtros 
                     */
                    filtrados: undefined, 
                    largo: undefined, 

                    /** 
                     * Termino de busqueda que introducimos en el filtro libre 
                     */                    
                    termino: undefined, 

                    
                    
                    /** 
                     * Datos del dispositivo, son globales, controlamos los eventos de 'window' 
                     * para responder a los cambios de tamaño de ventana, orientacion...  
                     */                    
                    device: this.$device, 

                }
            },  
            async updated(){
                this.inicio = new Date().getTime()
                window.fake_console('GestorList','en el update 245')
                const route = useRoute();
                window.fake_console('GestorList',this.ruta, route.path)
                if (route.fullPath!=this.ruta)
                {
                    this.loading = true;
                    window.fake_console('GestorList','llamo al update ')
                    this.ruta = route.fullPath
                    this.procesoInicial()

                }         
                else {
                    window.fake_console('GestorList','NO llamo al update')
                }       
            },
            async mounted() {
                this.inicio = new Date().getTime()
                this.loading = true;
                window.fake_console('GestorList','en el mount 273')
                const route = useRoute();
                window.fake_console('GestorList',this.ruta, route.path, route.query)
                this.ruta = route.fullPath
                window.fake_console('GestorList',route.path, route.query)
                this.procesoInicial()

            }, 
            methods :{
                AnchoCol(x) { return `width: ${(this.device.ancho-32)*x/100}px !important`}, 
                
                async procesoInicial() {
                    this.loading = true;
                    this.device = this.$device 
                    eventBus().emitter.on('device', (sz) => {  this.device=sz })          
                    this.fetchData()
                }, 
                
                async fetchData(){ 
                    this.loading = true
                    const url = `/buy/traspasos-detail/${this.codemp}/${this.codsuc}/${this.numpet}`
                    window.fake_console(url)
                    window.fake_console('GestorList',url, this.fetchUrl)
                    if (url !=this.fetchUrl)
                    {
                        this.fetchUrl = url
                        const result = await this.$http.get(url) 
                        window.fake_console('GestorList','Es cache?? : ',result.request.fromCache)
                        await this.tratamientoDatos(result.data)
                    }
                    this.loading = false
                }, 

                onRowEditSave (event){
                    window.fake_console(event.data)
                    this.recalcular_linea(event.data)
                    let { newData, index } = event;
                    window.fake_console(newData, index)

                },


                async onRowExpand(e) {
                    window.fake_console(JSON.stringify(e.data))
                    const pdv = this.codemp
                    const detalle = await this.fetchSkuDetailData(pdv,e.data.codmar, e.data.artpro )
                    window.fake_console(detalle)
                    e.data.detalle = detalle
                    e.data.selected = true;
                    //alert(JSON.stringify(this.skuData))
                }, 

                async fetchSkuDetailData(pdv,marca,articulo){ 
                    try 
                        {                    
                            const url =`/sku_details?sku=${marca}.${articulo}`
                            window.miconsola('SkuDetails ',  url, this.fetchUrl)
                            const result = await this.$http.get(url) 
                            const data = result.data
                            window.miconsola('L477 : ',data)
                            const target = ['000','012',pdv]
                            const final =  _.chain(data).filter( (v)=>{ return target.indexOf(v.p)!=-1 }).sortBy((y)=> {return target.indexOf(y.p)}).value()
                            const temp = _.chain(data).filter((v)=> {return target.indexOf(v.p)==-1}).sortBy((y)=>{ return y.sc==undefined ? 0 : -y.sc }).value().slice(0,3)
                            return _.union(final, temp)
                        }                
                    catch(ex) {
                        return false
                    }
                },

                Validar_Linea(data) {
                    window.fake_console('SkuDetails ',  data)
                    return data.est_gest =='P' ? 'check_azul' : data.exceso ? 'check_danger' : null
                }, 
             
                onRowUnselect(e) {
                    e.selected = false;
                },  
                async tratamientoDatos(result) {
                    window.fake_console(JSON.stringify(result).slice(0,100))
                    const d = result 
                    this.total_original = _.pluck(d.detail, 'implin').reduce((m,v)=> {return m+=v}, 0)
                    this.datos = d.detail.map(x=> {x.exceso = (x.dispo<x.unidad ||x.unidad<0 ); return x})
                    this.bloqueo_excesos = _.pluck(this.datos,'exceso').indexOf(true)!=-1
                    this.cabecera = d.head                         
                    this.tiempo = new Date().getTime() - this.inicio 
                    window.fake_console('test out')
                },
            
            async recalcular_linea(event){
                    let pd = event.data
                    window.fake_console(pd)
                    pd.implin = +((pd.unidad*pd.premed1000)/1000).toFixed(3)
                    pd.action = 'edited'
                    pd.exceso = (pd.dispo<pd.unidad ||pd.unidad<0 )
                    this.nuevo_total = _.pluck(this.datos, 'implin').reduce((m,v)=> {return m+=v}, 0)
                    this.bloqueo_excesos = _.pluck(this.datos,'exceso').indexOf(true)!=-1
                    const clave = `${this.codemp}.${this.codsuc}.${this.numpet}.${pd.numlin}`
                    const {codmar, artpro, unidad, numlin } = pd
                    this.filas_actualizar[clave] = {codemp: this.codemp, codsuc: this.codsuc, numpet:this.numpet, codmar, artpro, unidad, numlin }
                    window.fake_console(this.filas_actualizar)
                },    
            esGrupo(data) {
                    window.fake_console('SkuDetails ',  data)
                    return data.p==='000' ? 'resaltar' : null
                }, 
            goBack(){
                this.$router.replace('/traspasos/list')
            }, 
            async updateOrder(){
                const self = this
                const valores = Object.values(this.filas_actualizar)
                window.fake_console(valores)
                const ord = await this.$http.post(`/buy/traspasos-update-detail/${this.codemp}/${this.codsuc}/${this.numpet}`, {data: valores})
                const confirmacion = ord.data
                window.fake_console(ord)
                if (confirmacion.ok!=undefined) {
                        openAlerta('Actualizacion Correcta!', confirmacion.ok, ()=>{ self.goBack() } )
                    }
                    
                else {
                    openAlerta('Error en la actualización!', confirmacion.ko, ()=>{ self.goBack() })
                }
            }
            }, 
            
            
})
</script>



<style scoped>
    i.wait {
        color:var(--ion-color-primary) !important;
        float: right !important;
    }

    .azul {
        color:var(--ion-color-primary) !important;
        }
    .indentado {
        padding-left: 20px !important;
    }
    .resaltado {
          background-color: var(--color-resaltado-negativo) !important;  
          border: dotted 1px var(--color-dark-danger) !important;
    }
    ion-row.selectores {
        border: 1px solid var(--ion-color-light);
    }
    ion-row.grande {
        margin-top: 12px! important; 
        margin-bottom: 12px! important; 
    }

    ion-button {
        margin: 2px !important;
        color:white !important;
    }
    /* color del texto del boton  */
    .ion-color-warning {
        --ion-color-contrast:white !important;
    }
    .derecha {
        padding-left: 6px !important;
        text-align: right !important;
        width: 100%;
    }
    .izquierda {
        padding-right: 6px !important;
        text-align: left !important;
        width: 100%;
    }
    .centro {
        text-align: center !important;
        width: 100%;
    }

    tfoot.p-datatable-tfoot {
        background-color: red !important;
    }
</style>