<template>
  <ion-page>
    <ion-content>

      <ion-grid class="google">
        <ion-row class="google ion-align-items-start ion-justify-content-center">
          <ion-img src="img/icons/android-chrome-192x192.png" />
        </ion-row>

        <ion-row class="google ion-align-items-center ion-justify-content-center" v-if="loading">
          <ion-col>
            <ion-spinner></ion-spinner>
          </ion-col>
        </ion-row>

        <section v-else>
          <ion-row class="google ion-align-items-center ion-justify-content-center">
            <ion-button v-on:click="loginWithGoogle" fill="outline" size="large" color="primary" class="login">
              <ion-icon slot="start" icon="assets/google.svg" />
              Login Google
            </ion-button>
          </ion-row>
          <ion-row class="google ion-align-items-center ion-justify-content-center">
            <ion-button v-on:click="loginWithMicrosoft" fill="outline" size="large" color="primary" class="login">
              <ion-icon slot="start" icon="assets/microsoft.svg" />
              Login WURTH
            </ion-button>
          </ion-row>
        </section>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage, IonContent, IonButton,
  IonGrid, IonRow, IonIcon, IonImg,
  IonCol, IonSpinner
} from '@ionic/vue';
import { defineComponent } from 'vue';
import eventBus from "@/event-bus";
import { setInitDates, getInitFilters } from '@/utilities/services/init_services'
export default defineComponent({
  name: "Login",
  components: {
    IonPage, IonContent, IonButton,
    IonGrid, IonRow, IonIcon, IonImg,
    IonCol, IonSpinner
  },
  data() {
    return {
      ges: false,
      loading: false,
    }
  },
  methods: {
    async loginWithGoogle() {
      const self = this
      self.loading = true

      this.$firebase.auth().setPersistence(this.$firebase.auth.Auth.Persistence.LOCAL)
        .then(function () {
          const provider = new self.$firebase.auth.GoogleAuthProvider();
          provider.setCustomParameters({
            prompt: 'select_account'
          });
          self.$firebase
            .auth()
            .signInWithPopup(provider)
            .then((usr) => {
              /** Hay usuario, obtenemos token y fijamos rol y datos del usuario  */
              window.fake_console('Login 48: ' + JSON.stringify(usr.user.email))
              self.$firebase.auth().currentUser.getIdToken(true).then(async (idToken) => {
                try {
                  self.$http.defaults.headers['Authorization'] = 'Bearer ' + idToken
                  self.$fechas = await setInitDates()
                  await getInitFilters()
                  const supRequest = await self.$http.get('/check')
                  const roles = supRequest.data
                  roles['who'] = usr.displayName
                  window.fake_console('Login 61 ', JSON.stringify(roles))
                  eventBus().emitter.emit('user-logged', roles);
                } catch (error) {
                  self.loading = false
                }
              })
            })
            .catch(err => {
              self.loading = false
              alert('en el login ' + JSON.stringify(err.message));
            });
        })
        .catch(function (error) {
          self.loading = false
          window.fake_console('Login 73 (Error) : ', JSON.stringify(error))
        })
    },

    async loginWithMicrosoft() {
      const self = this;
      self.loading = true;
      const provider = new self.$firebase.auth.OAuthProvider('microsoft.com');

      this.$firebase
        .auth()
        .setPersistence(this.$firebase.auth.Auth.Persistence.LOCAL)
        .then(function () {

          // Con consent, entra directamente con el usuario con el que has iniciado sesion,
          // Pero con login, hay siempre inicio de sesion sin recordar el usuario
          provider.setCustomParameters({
            prompt: 'select_account',
            tenant: "45de56ea-e505-43a5-bd60-4957c8afc438"
          });

          self.$firebase
            .auth()
            .signInWithPopup(provider)
            .then((usr) => {
              /** Hay usuario, obtenemos token y fijamos rol y datos del usuario  */
              window.fake_console(
                "Login 48: " + JSON.stringify(usr.user.email)
              );

              self.$firebase
                .auth()
                .currentUser.getIdTokenResult(true)
                .then(async (idTokenResult) => {
                  try {
                    self.$http.defaults.headers['Authorization'] = 'Bearer ' + idTokenResult.token
                    self.$fechas = await setInitDates()
                    await getInitFilters()
                    const supRequest = await self.$http.get('/check')
                    const roles = supRequest.data
                    roles['who'] = usr.displayName
                    window.fake_console('Login 61 ', JSON.stringify(roles))
                    eventBus().emitter.emit('user-logged', roles);
                  } catch (error) {
                    self.loading = false
                  }
                  
                  self.$router.replace(self.$deeplink != undefined ? self.$deeplink : '/gestor/list')
                })
                .catch(
                  (err) => {
                    self.loading = false
                    alert("en el login " + JSON.stringify(err.message));
                  }
                );

            })
            .catch((err) => {
              self.loading = false
              alert("en el login " + JSON.stringify(err.message));
            });
        })
        .catch(function (error) {
          self.loading = false
          window.fake_console("Login 73 (Error) : ", JSON.stringify(error));
        });
    },
  }
})

</script>

<style scoped>
ion-grid.google {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 95vh;
  width: 20rem;
  margin-left: auto;
  margin-right: auto;
}

ion-button.login {
  width: 100%;
}
</style>