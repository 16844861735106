
var globalFilters = {    
    // signal_numero
        sMoneda: function (n){
            return [undefined, null, 0,].indexOf(n)!=-1 ? '-' : (n>0 ? '+' :'')+Math.round(n).toLocaleString('de-DE')+' €'
        },
        //numero
        Moneda : function  (n){
            return [undefined, null, 0,].indexOf(n)!=-1 ? '-' : Math.round(n).toLocaleString('de-DE')+' €'
        }
        ,
        //entero
        Entero : function  (n){
            return [undefined, null, 0].indexOf(n)!=-1 ? '-' : Math.round(n).toLocaleString('de-DE')
        }
        ,
        //miles
        Miles : function  (n){
            return [undefined, null, 0,].indexOf(n)!=-1 ? '-' : (n/1000).toLocaleString('de-DE', {minimumFractionDigits: 1, maximumFractionDigits: 1})
        },
        Miles0 : function  (n){
            return [undefined, null, 0,].indexOf(n)!=-1 ? '-' : (n/1000).toLocaleString('de-DE', {minimumFractionDigits: 0, maximumFractionDigits: 0})
        }
        ,
        //signal_entero
        sEntero :function (n){
            return [undefined, null, 0].indexOf(n)!=-1 ? '-' : (n>0 ? '+' :'')+Math.round(n).toLocaleString('de-DE')
        }  ,
        //signal_entero
        sPorcentaje :function (n){
            return [undefined, null, 0,].indexOf(n)!=-1 ? '-' : Math.round(n*100).toLocaleString('de-DE', {minimumFractionDigits: 1, maximumFractionDigits: 1})+' %'
        }  
        ,
        //Entero mostrado como porcentaje
        sIntPorcentaje :function (n){
            return [undefined, null, 0,].indexOf(n)!=-1 ? '-' : (n).toLocaleString('de-DE', {minimumFractionDigits: 0, maximumFractionDigits: 0})+' %'
        }  ,
        //Entero mostrado como porcentaje
        sIntPorcentaje1D :function (n){
            return [undefined, null, 0,].indexOf(n)!=-1 ? '-' : (n).toLocaleString('de-DE', {minimumFractionDigits: 1, maximumFractionDigits: 1})+' %'
        }  ,

        //Entero mostrado como porcentaje
        sIntPorcentaje2D :function (n){
            return [undefined, null, 0,].indexOf(n)!=-1 ? '-' : (n).toLocaleString('de-DE', {minimumFractionDigits: 2, maximumFractionDigits: 2})+' %'
        }  ,        
        
        sDecimal: function (n){
            return [undefined, null, 0,].indexOf(n)!=-1 ? '-' : n.toLocaleString('de-DE', {minimumFractionDigits: 2, maximumFractionDigits: 2})
        },

        s1Decimal: function (n){
            return [undefined, null, 0,].indexOf(n)!=-1 ? '-' : n.toLocaleString('de-DE', {minimumFractionDigits: 1, maximumFractionDigits: 1})
        },
        sDecimalRenderer: function (n){
            return [undefined, null, 0,].indexOf(n.value)!=-1 ? '-' : Math.round(n.value).toLocaleString('de-DE', {minimumFractionDigits: 2, maximumFractionDigits: 2})
        },
        Capitalize : function(n) {
            if (typeof n !== 'string') return ''
            return n.charAt(0).toUpperCase() + n.slice(1).toLowerCase()
          },
        num2Month : function(n){
            if (Number.isNaN(n)||n===0||n==undefined ) return '-'
            else 
                {            
                    const x = n.toString()
                    return `${x.slice(0,4)}.${x.slice(4,6)}`
                }
        }, 
        MesesNuevo : function(n){
            if (Number.isNaN(n)||n===undefined ) { return '-' }
            else {return n>= 12 ? '-' : ( 12-n ) }
        },
        text2Date: function(n) {
            const re = new RegExp(/^(\d{2})(\d{2})(\d{2})(\d{2})$/,'i')
            if (/^(\d{2})(\d{2})(\d{2})(\d{2})$/.test(n)) { return n.replace(re,'$4/$3/$2')}
            else { return '-'}

        }, 
        now2Date() {
            const t = new Date()
            return t.toISOString().replace(/-/g,'.').slice(0,10)
        },


    }

const Entero = globalFilters.Entero
export { globalFilters, Entero  }
