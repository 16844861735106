<template>
   <ion-page :style="fontSize" >
        <CabeceraComponent :titulo="titulo_pag" color="primary"/>
        <ion-content class="ion-padding" v-if="device" >  
            
            <ion-row class="ion-justify-content-left ion-align-items-top selectores grande" v-if="loading==false">
                    <ion-col  :size="device.tamanyo == 0 ? 24 : device.tamanyo==1 ? 16 : 12" >
                        <div class="p-input-icon-right">
                            <InputText type="text" v-model="termino" placeholder="Buscar (min 3 letras)" @input="debounceSearch"/>
                            <i class="pi pi-search" />
                        </div>
                    </ion-col>
               
                    <ion-col :size="device.tamanyo == 0 ? 24 : device.tamanyo==1 ? 16 : 12" v-for = "(vf,idf) in 
                            device.tamanyo>1 ? { 'nompm' : product_managers, 'fecped' : fechas   , 'prov' : proveedores, 'usu': usuarios , 'nompos' : pdvs }
                                : {  'prov' : proveedores,'nompos' : pdvs }
                            " 
                            v-bind:key="device.tipo+idf+'_999'" >
                        <DropDown v-model="SelectedFilters[idf]" :options="vf" optionLabel="n" class="selector"
                            :filter="true"   v-bind:key="device.tipo+idf"  
                            @change="filtrar"
                            :placeholder="'Filtrar '+etiquetas[idf]" />
                    </ion-col>

            </ion-row>
            <ion-row v-if="loading" class="spin ion-justify-content-center "  >
                <ion-col  v-if="loading" class="spin ion-align-self-center" size="12" > 
                    <ion-spinner color="primary" v-if="loading"></ion-spinner>
                </ion-col>
            </ion-row>
            <DataTable :value="filtrados" 
                v-if="!loading && datos"
                v-bind:key="device.tipo + device.ancho + contador"
                :paginator="paginador" :rows="filas"
                scrollHeight="flex" 
                scrollDirection="vertical" 
                :scrollable="true" 
                removableSort
                :sortable="true"
                ref="datatable_gestor"
                paginatorTemplate="RowsPerPageDropdown  FirstPageLink PrevPageLink  NextPageLink LastPageLink CurrentPageReport"
                :rowsPerPageOptions="[10,20,50,100]"
                currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                responsiveLayout="scroll"
                :rowClass="Validar_Linea"
                selectionMode="multiple" 
                v-model:selection="selectedOrders"
                style="height: 72vh;"
                    >

                <template #header>
                <div >
                    <ion-row>
                        <ion-col :size="device.tamanyo < 1 ? 48 : 28" >
                            <h3>Gestor de compras ({{largo}} pedidos por autorizar)</h3>
                        </ion-col>
                        <ion-col >
                            <ion-toolbar> 
                                <!-- :disabled="selectedOrders===undefined ||selectedOrders.length==0"-->
                                <ion-buttons slot="end" :size="device.tamanyo==0 ? 'small' : 'large'" >
                                      <ion-button color="primary" fill="outline" :size="device.tamanyo==0 ? 'small' : 'large'" 
                                            @click="pageUpdate">
                                        <small>Actualizar</small>
                                    </ion-button>       
                                    <ion-button color="success" fill="solid" :size="device.tamanyo==0 ? 'small' : 'large'" 
                                            :disabled="selectedOrders===undefined ||selectedOrders.length==0"
                                            @click="ApproveOrders"
                                            >
                                            <b>(A)</b><small>ceptar</small>
                                    </ion-button>                                                       
                                    <ion-button color="danger"  fill="solid"  :size="device.tamanyo==0 ? 'small' : 'large'" 
                                            :disabled="selectedOrders===undefined ||selectedOrders.length==0"
                                            @click="EditOrders('r')"
                                            >
                                            <b>(R)</b><small>echazar</small>
                                    </ion-button>     
                                    <ion-button color="tertiary"  fill="solid"  :size="device.tamanyo==0 ? 'small' : 'large'" 
                                            :disabled="selectedOrders===undefined ||selectedOrders.length==0"
                                            @click="EditOrders('d')"
                                            >
                                            <b>(D)</b><small>esbloquear</small>
                                    </ion-button>                                                       
                                    <ion-button color="warning" fill="solid"  :size="device.tamanyo==0 ? 'small' : 'large'" 
                                        :disabled="selectedOrders===undefined ||selectedOrders.length==0"
                                        @click="EditOrders('b')"
                                        >
                                        <b>(B)</b><small>loquear</small>
                                    </ion-button>                                               
                                    <ion-button color="secondary" fill="solid"  :size="device.tamanyo==0 ? 'small' : 'large'" 
                                        :disabled="selectedOrders===undefined ||selectedOrders.length==0"
                                        @click="EditOrders('t')"
                                        >
                                        <b>(T)</b><small>raspasar</small>
                                    </ion-button>                         
                                </ion-buttons>
                            </ion-toolbar>
                        </ion-col>
                    </ion-row>
                </div>


                </template>
                <template #loading>
                    Cargando datos de stock. Por favor, espera.
                </template>
                <ColumnGroup type="header" >
                    <Row :headerStyle="AnchoCol(100)" >
                        <Column class="no-border" :headerStyle="anchos_titulo['pdv']" :bodyStyle="anchos_titulo['pdv']" />
                        <Column class="no-border" :headerStyle="anchos_titulo['ped']" :bodyStyle="anchos_titulo['ped']" />
                        <Column class="no-border" :headerStyle="anchos_titulo['prov']" :bodyStyle="anchos_titulo['prov']" />
                        <Column class="no-border" :headerStyle="anchos_titulo['usu']" :bodyStyle="anchos_titulo['usu']"  v-if="device.tamanyo>2"/>

                        <Column header="Datos Clave" :headerStyle="anchos_grupos['datos_clave']" :bodyStyle="anchos_grupos['datos_clave']" />
                        <Column class="no-border" header="Cobertura" :headerStyle="anchos_titulo['st_caldes']" :bodyStyle="anchos_titulo['st_caldes']" />
                        <Column header="Impactos economicos" :headerStyle="anchos_grupos['eco_impact']" :bodyStyle="anchos_grupos['eco_impact']" />

                        <Column class="no-border" :headerStyle="anchos_titulo['notped']" :bodyStyle="anchos_titulo['notped']" v-if="device.tamanyo>0"/>
                        <Column class="no-border" :headerStyle="anchos_titulo['notges']" :bodyStyle="anchos_titulo['notges']" v-if="device.tamanyo>1"/>
                        <Column class="no-border" :headerStyle="anchos_titulo['act']" :bodyStyle="anchos_titulo['act']" />
                        

                    </Row>
                    <Row :headerStyle="AnchoCol(100)">
                        <Column header="Pdv" :sortable="true" field="pdv"    :headerStyle="anchos['pdv']" :bodyStyle="anchos['pdv']" />
                        <Column header="Pedido" :sortable="true" field="ped"    :headerStyle="anchos['ped']" :bodyStyle="anchos['ped']" />
                        <Column header="Proveedor" :sortable="true" field="prov"    :headerStyle="anchos['prov']" :bodyStyle="anchos['prov']" />
                        <Column header="Usuario" :sortable="true" field="usu"    :headerStyle="anchos['usu']" :bodyStyle="anchos['usu']"   v-if="device.tamanyo>2"/>

                        <!-- segundo nivel: Datos Clave -->
                        <Column header="Fecha" :sortable="true" field="fecped" :headerStyle="anchos['fecped']" :bodyStyle="anchos['fecped']" />
                        <Column header="Importe" :sortable="true" field="impnet"  :headerStyle="anchos['impnet']" :bodyStyle="anchos['impnet']" />
                        <Column header="Valor Cl" :sortable="true" field="ncl"  :headerStyle="anchos['ncl']" :bodyStyle="anchos['ncl']" />

                        <!--segundo nivel, Alternativa Caldes-->
                        <Column header="Caldes" :sortable="true" field="st_caldes" :headerStyle="anchos['st_caldes']" :bodyStyle="anchos['st_caldes']" />

                        <!-- Segundo nivel: Impactos económicos-->
                        <Column header="&#x0394; Stock" :sortable="true" field="eur_stock"  :headerStyle="anchos['eur_stock']" :bodyStyle="anchos['eur_stock']" />
                        <Column header="&#x0394; Dias" :sortable="true" field="eur_days" :headerStyle="anchos['eur_days']" :bodyStyle="anchos['eur_days']" />
                        <Column header="&#x0394; Vendi." :sortable="true" field="rate_sold" :headerStyle="anchos['rate_sold']" :bodyStyle="anchos['rate_sold']" />
                        <Column  header="Venta" :sortable="true" field="eur_venta"  :headerStyle="anchos['eur_venta']" :bodyStyle="anchos['eur_venta']" />
                        <Column header="Margen" :sortable="true" field="perc_margen"  :headerStyle="anchos['perc_margen']" :bodyStyle="anchos['perc_margen']" />

                        <!-- nivel único-->
                        <Column header="Nota pedido" :sortable="true" field="notped"    :headerStyle="anchos['notped']" :bodyStyle="anchos['notped']"  v-if="device.tamanyo>0" />
                        <Column header="Nota gestor" :sortable="true" field="notges"    :headerStyle="anchos['notges']" :bodyStyle="anchos['notges']"  v-if="device.tamanyo>1" />
                        <Column header="Sit." :sortable="true" field="sit"    :headerStyle="anchos['sit']" :bodyStyle="anchos['sit']" />
                        
                    </Row>  
                </ColumnGroup>

                <Column selectionMode="multiple" headerStyle="width: 4rem"></Column>
                
                <Column v-for="(c, index) of columnas" :field="c" 
                    :key="c + '_' + index" 
                    :sortable="true"
                    :headerStyle="anchos[c]" 
                    :bodyStyle="anchos[c]" 
                    >
                    
                    <template #body="slotProps" >
                        <!-- campos texto alineacion izquierda-->
                        <div class="izquierda azul" v-if="['pdv'].indexOf(c)!=-1">
                            <i class="outlet fa fa-exclamation-triangle" v-if="slotProps.data.outlet==1"></i>  
                            {{device.tamanyo==0 ? slotProps.data[c].split(' ')[0]: slotProps.data[c]}} 
                        </div>
                        <div class="izquierda azul" v-else-if="['prov'].indexOf(c)!=-1">
                            {{device.tamanyo==0 ? slotProps.data[c].split(' ')[0]: slotProps.data[c]}} 
                        </div>

                        <div class="izquierda azul" v-else-if="['notped'].indexOf(c)!=-1 && device.tamanyo >0">
                            {{slotProps.data[c]}} 
                        </div>

                        <div class="izquierda azul" v-else-if="['notges'].indexOf(c)!=-1 && device.tamanyo >1">
                            {{slotProps.data[c]}} 
                        </div>

                        <div class="izquierda azul" v-else-if="['usu'].indexOf(c)!=-1 && device.tamanyo >2">
                            <i class="outlet fa fa-envelope-open" v-if="slotProps.data.fecmai!=null"></i>  
                            {{slotProps.data[c]}} 
                        </div>

                        <!-- campos link-->
                        <div class="derecha azul" v-else-if="['ped'].indexOf(c)!=-1" @click="irDetalle(slotProps.data)">
                            <i class="wait-left fa fa-clock-o" v-if="slotProps.data.est_gest=='P'"></i>  
                            <a  href="javascript:void(0);">{{ $filters.Entero(slotProps.data[c] ==undefined ? '-' : slotProps.data[c])}}</a>
                        </div>                        <!-- campos decimales-->
                        <div class="derecha azul" v-else-if="['st_caldes','eur_stock','eur_days','impnet','ncl','delta_caldes','eur_venta'].indexOf(c)!=-1">
                            {{ $filters.Entero(slotProps.data[c] ==undefined ? '-' : slotProps.data[c])}}
                        </div>
                        <!-- Campos porcentaje 1 digito-->
                        <div class="derecha azul" v-else-if="['perc_margen'].indexOf(c)!=-1">
                            {{ $filters.sIntPorcentaje1D(slotProps.data[c] ==undefined ? '-' : slotProps.data[c])}}
                        </div>
                        <!-- Campos porcentaje sin decimal-->
                        <div class="derecha azul" v-else-if="['perc_caldes','rate_sold'].indexOf(c)!=-1">
                            {{ $filters.sIntPorcentaje(slotProps.data[c] ==undefined ? '-' : slotProps.data[c])}}
                        </div>
                        <!-- Campos fecha -->
                        <div class="derecha azul" v-else-if="['fecped'].indexOf(c)!=-1">
                            {{ $filters.text2Date(slotProps.data[c] ==undefined ? '-' : slotProps.data[c])}} 
                        </div>

                        <div v-else-if="c=='sit'" class="centro">
                            
                            <ion-button v-if="slotProps.data[c]" color="warning"  disabled size="small" class="botones">B</ion-button>                                       
                        </div>

                    </template>
                </Column>   
            </DataTable>
        </ion-content>
        


    </ion-page>
</template>

<script>

    import { IonPage, IonContent,   
            IonRow,IonCol,  IonButton, 
            IonSpinner,IonButtons,IonToolbar, 
            modalController
     } from '@ionic/vue';
    import CabeceraComponent from '@/components/CabeceraComponent'
    import  GestorOrderMultiApprovalModal  from  '@/pages/gestor/GestorOrderMultiApprovalModal'
    import  GestorOrderMultiActionModal  from  '@/pages/gestor/GestorOrderMultiActionModal'
    import { defineComponent } from 'vue';
    import * as _ from 'underscore';
    import eventBus from "@/event-bus";
    import { closeCircle } from 'ionicons/icons';
    import { useRoute } from 'vue-router';
    export default defineComponent({
            name : "GestorList",
            components: { 
                IonPage, IonContent, 
                IonRow,IonCol, IonButton,
                IonSpinner,IonButtons,IonToolbar,
                CabeceraComponent  
                
            } , 
            computed :{
                fontSize () { 
                    window.fake_console('GestorList',`--texto-size : ${9+(this.device.tamanyo)}px`)
                    return `--texto-size : ${9+(this.device.tamanyo)}px;`
                }, 
                anchos_grupos() {
                    const bases = this.Bases()
                    const grupos = {
                        'datos_clave' : this.base_anchos['fecped']+this.base_anchos['impnet']+this.base_anchos['ncl'],
                        'eco_impact' : this.base_anchos['eur_stock']+this.base_anchos['eur_days']+this.base_anchos['rate_sold']+this.base_anchos['eur_venta']+this.base_anchos['perc_margen'], 
                    }
                    const factor = _.reduce(_.values(bases), (m,v)=> {return m+=(v/100)},0)
                    const resultado = _.reduce(grupos, (m,v,k)=> {m[k]= this.AnchoCol(v / factor) ;return m },{})
                    return resultado
                },

                anchos_titulo(){
                    const bases = this.Bases()
                    const factor = _.reduce(_.values(bases), (m,v)=> {return m+=(v/100)},0)
                    const resultado = _.reduce(bases, (m,v,k)=> {m[k]= this.AnchoCol(v / factor) ;return m },{})
                    resultado['sit'] =  `width : ${(12+(this.device.tamanyo))*3}px !important;`
                    return resultado
                },
                anchos () {
                    /** Nos aseguramos que las definiciones de columnas sumen 100, sino aplicamos proporcionalidad  */
                    const bases = this.Bases()
                    const factor = _.reduce(_.values(bases ), (m,v)=> {return m+=(v/100)},0)
                    window.fake_console(factor)
                    const resultado = _.reduce(bases , (m,v,k)=> {m[k]= this.AnchoCol(v / factor) ;return m },{})
                    window.fake_console(this.device.tipo, this.device.ancho,  factor, resultado)
                    resultado['sit'] =  `width : ${(12+(this.device.tamanyo))*3}px !important;`
                    return resultado
                    },
                columnas() {
                    const cc = ["pdv", "ped", "prov", "usu", "fecped", "impnet", "ncl", "st_caldes",   
                        "eur_stock", "eur_days", "rate_sold", "eur_venta", "perc_margen", "notped", "notges", "sit"]
                    return this.device.tamanyo >1 ? cc : _.without(cc,'usu','notges')
                }
            },
            data() {    
                return {
                    base_anchos : {
                        "pdv": 1.3,   "ped": 2.5,   "prov": 4,   "usu": 5,   "fecped": 2.5,   "impnet": 2,   "ncl": 2,   "st_caldes": 2,  
                        "eur_stock": 2,   "eur_days": 2,   "rate_sold": 2,   "eur_venta": 2,   "perc_margen": 2,   "notped": 6,  
                        "notges": 6,  
                        },
                
                    etiquetas:  {

                        'prov':'proveedor',
                        'nompm':'product manager',
                        'fecped':'fecha pedido',
                        'usu': 'usuario',
                        'nompos': 'pdv'
                    },
                    titulo_pag:"Gestor de Compras " , 
                    paginador: false, 
                    /** Iconos, se han de declara en data para poder ser utilizados  */
                    closeCircle, 
                    
                    /*columnas :["pdv", "ped", "prov", "usu", "fecped", "impnet", "ncl", "st_caldes", 
                        "eur_stock", "eur_days", "rate_sold", "eur_venta", "perc_margen", "notped", "notges", "sit", "act"], */
                   
                   /** Variables para los filtros */
                    proveedores: undefined, 
                    product_managers: undefined, 
                    fechas : undefined, 
                    usuarios: undefined, 
                    pdvs : undefined ,
                    tiempo: undefined,

                    /** Valores seleccionados para cada filtro, los posibles son los que estan en filters */
                    SelectedFilters: { 'nompm' : 0, 'fecped' : 0   , 'prov' : 0, 'usu': 0 , 'nompos' : 0 }, 

                   /** Variable de control para actuar en mount/update */
                    ruta: undefined , 
                    fetchUrl : undefined, 
                    filas: undefined , 
                    selectedOrders:undefined, 
                    /** Variable que controla el spinner de carga  */
                    loading: true, 
                    inicio: undefined, 
                    contador : 0,


                    /** variable de control del debounce (diferimiento ) */
                    debounce: null,

                  
                    /**
                     * Variable donde estan los datos que efectivamente se ven en la tabla 
                     * una vez aplicados todos los filtros 
                     */
                    datos: undefined, 
                                        
                    /**
                     * Variable donde estan los datos que efectivamente se ven en la tabla 
                     * una vez aplicados todos los filtros 
                     */
                    filtrados: undefined, 
                    largo: undefined, 

                    /** 
                     * Termino de busqueda que introducimos en el filtro libre 
                     */                    
                    termino: undefined, 

                    /** 
                     * Datos del dispositivo, son globales, controlamos los eventos de 'window' 
                     * para responder a los cambios de tamaño de ventana, orientacion...  
                     */                    
                    device: this.$device, 

                }
            },  
            async updated(){
                this.inicio = new Date().getTime()
                window.fake_console('GestorList','en el update 245')
                const route = useRoute();
                window.fake_console('GestorList',this.ruta, route.path)
                if (route.fullPath!=this.ruta)
                {
                    this.loading = true;
                    window.fake_console('GestorList','llamo al update ')
                    this.ruta = route.fullPath
                    this.actualizacion()

                }         
                else {
                    window.fake_console('GestorList','NO llamo al update')
                }       
            },
            async mounted() {
                window.fake_console('en el moutn')
                await this.actualizacion()
            }, 
            methods :{
                
                async pageUpdate() {
                    const tb = (this.termino==undefined||this.termino.length==0) ? '' : `&termino=${this.termino}`
                    const d = `/gestor/list?q=${Date.now()}${tb}`
                    this.$router.replace(d)
                }, 
                async actualizacion () {
                    window.fake_console('En la actualizacion')
                    this.inicio = new Date().getTime()
                    this.loading = true;
                    window.fake_console('GestorList','en el mount 273')
                    const route = useRoute();
                    const {termino} = route.query
                    window.fake_console(termino, route.query)
                    this.termino = termino!=undefined ? termino : this.termino
                    window.fake_console('GestorList',this.ruta, route.path, route.query)
                    this.ruta = route.fullPath
                    window.fake_console('GestorList',route.path, route.query)
                    this.procesoInicial()
                }, 

                Bases () {
                    const omisiones =  {
                        0 : ['usu','notges','notped'],
                        1 : ['usu','notges'], 
                        2 : ['usu'],
                    }
                    
                    return this.device.tamanyo>2 ? this.base_anchos  : _.omit(this.base_anchos,omisiones[this.device.tamanyo]) 
                }, 

                AnchoCol(x) { 
                    const sitwidtdh = (11+this.device.tamanyo)*3
                    const marginwidth = 38
                    return `width: ${(this.device.ancho-marginwidth-sitwidtdh)*x/100}px !important`
                    }, 
                
                async procesoInicial() {
                    this.loading = true;
                    this.contador+=1
                    this.device = this.$device 
                    eventBus().emitter.on('device', (sz) => {  this.device=sz })          
                    this.fetchData()
                }, 
                
                async fetchData(){ 
                    this.loading = true
                    const url =`/buy/monitor-list`
                    window.fake_console('GestorList',url, this.fetchUrl)
                    if (url !=this.fetchUrl||this.contador>0)
                    {
                        this.fetchUrl = url
                        const result = await this.$http.get(url) 
                        window.fake_console('GestorList','Es cache?? : ',result.request.fromCache)
                        await this.tratamientoDatos(result.data)
                    }
                    this.SelectedFilters=  { 'nompm' : 0, 'fecped' : 0   , 'prov' : 0, 'usu': 0 , 'nompos' : 0 }
                    this.filtrar()
                    this.loading = false
                }, 
                Validar_Linea(data) {
                    window.fake_console('SkuDetails ',  data)
                    return !data.chk ? null : data.chk
                }, 
                
                debounceSearch() {
                    clearTimeout(this.debounce)
                    this.debounce = setTimeout(() => { this.filtrar()}, 250)
                },
                async irDetalle(item) {
                    window.fake_console('L401 : ', item)
                    const tb = (this.termino==undefined||this.termino.length==0) ? '' : `?termino=${this.termino}`
                    this.$router.push(`/gestor/detail/${item.pdv}/${item.suc}/${item.ped}${tb}`)
                },
                async filtrar() {
                    window.fake_console(JSON.stringify(this.SelectedFilters))
                    const selectores = _.reduce(this.SelectedFilters, (m,v,k)=> {
                        if (v!=0 && v['id']!=0 ) {m[k]= v['id']}
                        return m 
                    } , {})
                    window.fake_console(selectores)
                    window.fake_console(Object.keys(selectores).length)
                    let filtrados = _.filter(this.datos, _.matcher(selectores))
                    if (this.termino!=undefined && this.termino.length>=2){
                        filtrados = filtrados.filter((v)=> {return JSON.stringify(v).toLowerCase().indexOf(this.termino)!=-1})
                    }
                    this.filtrados = filtrados
                    window.fake_console('GestorListPage',this.filtrados.length, this.filas, this.paginador)
                    if (this.filtrados.length <= this.filas) { this.paginador = false}
                        else { this.paginador = true}
                    window.fake_console('GestorListPage',this.filtrados.length, this.filas, this.paginador)
                    this.loading = false
                    window.fake_console('GestorListPage',filtrados.length)
                    window.fake_console('GestorListPage',JSON.stringify(filtrados).slice(0,100))
                },
                async tratamientoDatos(result) {
                        var validacion = function(data) {
                            const rso = data.rate_sold >= 75  // vendido en un 75%
                            const eus = data.eur_stock < 500  // Aumento de stock inferior a 1000€
                            const eud = data.eur_days <=45 // impacto en dias <= 45 dias 
                            const pdm = data.perc_margen >=15 // margen superior al 15%
                            const chk =  data.est_gest=='P' ? 'check_azul' : rso && pdm && (eus || eud) ? 'check_success' : (eus||eud) ? 'check_warning': false
                            return  {'chk' :chk , 'tip':chk!=false ? 'valido': false }  
                        }
                        window.fake_console(result['validate'])
                        const v = result['validate'].reduce((m,e)=>{m[e['id']]=e; return m },{})
                        const d = result['data'].reduce((m,el)=>{
                            const x = _.extend(el,v[el['id']]);
                            x['selected'] = false; 
                            m.push(x);
                            return m
                        },[])
                        window.fake_console(v)
                        window.fake_console(d)
                        const generar = function(d,p,t) {
                            const total = {'id':0,'n': t}
                            const w =  _.map(_.unique(_.pluck(d, p)).sort(), function(k) {return _.object(['id','n'],[k,k])})
                            w.unshift(total)
                            return w
                        }
                        this.proveedores = generar(d,'prov','Todos los proveedores')
                        this.product_managers = generar(d,'nompm','Todos los product managers')
                        this.fechas = generar(d,'fecped','Todas las fechas')
                        this.usuarios = generar(d,'usu','Todos los usuarios')
                        this.pdvs = generar(d,'nompos','Todos los pdvs')
                        _.each(d, function (el){ _.extend(el,validacion(el))})
                        this.datos = d
                        window.fake_console(this.datos.filter(k=>k.id=='032.00.142920'))
                        this.filtrados = d
                        this.largo = d.length
                        this.filas = this.largo <20? this.largo :20
                        this.paginador = this.largo <20 ? false :true
                }, 
                async ApproveOrders () {
                    var self = this
                    const modal = await modalController
                        .create({
                                component: GestorOrderMultiApprovalModal,
                                cssClass: 'custom-modal',
                                componentProps: { items: this.selectedOrders },
                                })
                    eventBus().emitter.on('cerrar-edicion-pedido',async (que)=>{
                        modal.dismiss();
                        if (que!=undefined) {  
                            window.fake_console('en la condicion de cambio')
                            self.selectedOrders = undefined
                            self.contador+=1
                            const tb = (self.termino==undefined||self.termino.length==0) ? '' : `&termino=${self.termino}`
                            self.$router.replace(`/gestor/list?q=${Date.now()}${tb}`)
                        }    
                    });
                    modal.present();
                }, 

                async EditOrders (act) {
                    var self = this
                    const modal = await modalController
                        .create({
                                component: GestorOrderMultiActionModal,
                                cssClass: 'custom-modal',
                                componentProps: { items: this.selectedOrders, act: act },
                                })
                    eventBus().emitter.on('cerrar-edicion-pedido',async (que)=>{
                        modal.dismiss();
                        if (que!=undefined) {  
                            window.fake_console('en la condicion de cambio')
                            self.selectedOrders = undefined
                            self.contador+=1
                            const tb = (self.termino==undefined||self.termino.length==0) ? '' : `&termino=${self.termino}`
                            self.$router.replace(`/gestor/list?q=${Date.now()}${tb}`)
                        }    
                    });
                    modal.present();
                }, 
            }
            
})
</script>


<style lang="scss">
    table>thead>tr>th[class=""]{
        display: none !important; 
        padding:0px !important;
    }
</style>
<style scoped>
    i.wait-left {
        color:var(--ion-color-primary) !important;
        position:relative !important; 
        left:-12px !important;

    }
    i.outlet {
        color:var(--ion-color-danger) !important; 
        float:right !important;  
    }
    .azul {
        color:var(--ion-color-primary) !important;
        }
    ion-button.botones {
        --padding-bottom: 0.5rem !important; 
        --padding-top:  0.5rem !important; 
        --padding-start:  0.5rem !important; 
        --padding-end:  0.5rem !important; 
    }
    ion-row.selectores {
        border: 1px solid var(--ion-color-light);
    }
    ion-row.grande {
        margin-top: 12px! important; 
        margin-bottom: 12px! important; 
    }

    ion-button {
        margin: 2px !important;
        color:white !important;
    }
    /* color del texto del boton  */
    .ion-color-warning {
        --ion-color-contrast:white !important;
    }
    .derecha {
        text-align: right !important;
        width: 100%;
    }
    .izquierda {
        text-align: left !important;
        width: 100%;
    }
    .centro {
        text-align: center !important;
        width: 100%;
    }


</style>