<template>
    <ion-page>
        
        <ion-header color="primary" >
            <ion-toolbar color="primary" >
                <ion-title class="ion-padding">
                    {{titulo}}
                </ion-title>
            </ion-toolbar>
            
        </ion-header>
        <ion-content v-if="loading" class="ion-padding">
            <ion-row v-if="loading" class="spin ion-justify-content-center "  >
                <ion-col  v-if="loading" class="spin ion-align-self-center" size="12" > 
                    <ion-spinner color="primary" v-if="loading"></ion-spinner>
                </ion-col>  
            </ion-row>
        </ion-content>
        <ion-content v-if="!loading" class="ion-padding">
            <p class="grande">Elige un punto de venta</p>
            <ion-row class="ion-padding ">
                                <DropDown v-model="seleccion.pdv" :options="filtro_pdv" optionLabel="k" class="selector"
                                    :optionValue="(e)=>e.v[0]"
                                    :filter="true"  v-bind:key="device.tipo+'_pdvc'"  
                                    placeholder="Pdv" />
            </ion-row>  
            <ion-row>
                <p class="grande">Seleccion actual:</p>
            </ion-row>
            <ion-row class="padded">    
                <ul class="indentado grandote">
                    <li key="pdv" class="seleccciones"> 
                        Pdv - {{seleccion.pdv =='000'  ? 'Todos los pdvs' : seleccion.pdv}} 
                    </li>
                </ul>

            </ion-row>  

        </ion-content>
        <ion-footer >
            <ion-toolbar class="full"  color="blanco">
                <ion-buttons slot="secondary" class="full">
                    <ion-button @click="cerrar()" color="danger" expand="block" fill="solid" 
                        >
                        <ion-icon :md="closeCircle"></ion-icon>
                        Cerrar
                    </ion-button>
                    <ion-button @click="go()" color="primary"  
                        expand="block" fill="solid" slot="end" 
                        :disabled="desactivar"
                        >
                        <ion-icon :md="addCircle"></ion-icon>
                        Nueva
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-footer>

    </ion-page>
</template>

<script>
    import {    
            IonPage, IonContent,IonRow,IonButton, IonHeader,IonFooter,IonTitle, IonCol, 
            IonIcon,IonButtons,IonToolbar, IonSpinner,
            } from '@ionic/vue';
    import { defineComponent } from 'vue';    
    import eventBus from "@/event-bus";
    import { Plugins } from '@capacitor/core';
    import { union } from 'underscore';
    const { Storage } = Plugins;
    import { closeCircle, addCircle } from 'ionicons/icons';
    export default defineComponent({
        name : "SelectorPdvModal",
        components: {  
            IonPage,  IonContent,IonRow,  IonHeader,IonFooter,IonTitle,IonCol,
            IonButton, 
            IonIcon,IonButtons,IonToolbar, IonSpinner,
        },
        props :['titulo'], 
        async mounted() {
                this.device = this.$device 
                const fl =  await Storage.get({ key: 'stocks:filtros' });
                window.fake_console('MarcasKgList',fl)
                this.filtros = JSON.parse(fl.value) 
                this.filtro_pdv = union([],this.filtros['pdvs'])
            }, 
         computed :{
                desactivar: function () {
                    return this.seleccion.pdv =='GES' && this.seleccion.marca == '999'
                }, 
            },
        data() {    
                return {
                    closeCircle, addCircle, 
                    loading : false, 
                    /** 
                     * Datos del dispositivo, son globales, controlamos los eventos de 'window' 
                     * para responder a los cambios de tamaño de ventana, orientacion...  
                     */                    
                    device: this.$device, 

                    /**  
                     *   Filtros posibles del usuario, obtenidos en la carga inicial, 
                     *   solo los que pueden tener sentido para el y con valores 
                     *   ya filtrados (sus pdvs, sus marcas.. ) 
                     *   Selectores para crear una combinacion nueva 
                    */
                    filtros: {},
                    filtro_pdv: undefined, 
                    seleccion : {
                        pdv: '000' 
                    },
                    

                }
        },
        methods: {
            async cerrar() {
                eventBus().emitter.emit('cerrar-selector-pdv', undefined)
            },
            async go(){
                eventBus().emitter.emit('cerrar-selector-pdv', this.seleccion)
                
            } 
        }
})
</script>

<style scoped>
    ion-row {
        padding-top: 8px !important; 
        padding-bottom: 8px !important; 
    }
    .ok {
        color: var(--ion-color-success)
    }
    .ko {
        color: var(--ion-color-danger)
    }
    p.grande {
        margin: 0.8rem 0rem !important; 
        padding: 0px 16px !important;
        font-size: larger !important; 
        font-weight: 600 !important;
        color: var(--ion-color-dark) !important;

    }
    .negrita { font-weight: bold;}
    ion-title {
        width:100% !important; 
        padding-left: 16px !important; 

    }
    .full {
        width:100% !important;
    }
    ion-buttons {
        float:right !important; 
    }
    ion-button {
        float: right !important; 
        width: 25% !important; 
        margin-left:6px !important; 
        margin-right:6px !important; 
    }

    .full {
        width:100% !important;
    }
    ul {
        padding-left:32px !important;
        width: 100% !important; 
        margin-top: 0px !important; 
        margin-bottom: 0px !important; 
    }
    li.selecciones {
        padding: 6px  40px !important; 
    }
    .padded {
        padding-left:40px !important;
        
    }
    .peque {
        font-size: 0.8rem;
    }
    .grandote {
        font-size:larger !important;
    }
</style>