<template>
    <ion-page>
        <!--VERSION PEDIDO UNICO-->
        
        <ion-header color="primary" >
            <ion-toolbar color="primary" >
                <ion-title class="ion-padding">
                    Acepta pedido/s
                </ion-title>
            </ion-toolbar>
            
        </ion-header>
        <ion-content v-if="loading" class="ion-padding">
            <ion-row v-if="loading" class="spin ion-justify-content-center "  >
                <ion-col  v-if="loading" class="spin ion-align-self-center" size="12" > 
                    <ion-spinner color="primary" v-if="loading"></ion-spinner>
                </ion-col>  
            </ion-row>
        </ion-content>
        <ion-content v-if="items && !loading" class="ion-padding">
            <ion-row>
                <ion-title>{{mensaje1}}</ion-title>
                <ul class="indentado">
                    <li v-for="(item, index) in items" :key="index" class="pedidos"> 
                        {{item.id}} - {{item.usu}} 
                    </li>
                </ul>
            </ion-row>

            <ion-row>
                <p class="grande">{{mensaje2}}</p>
                <ul class="indentado">
                        <li class="acciones">
                            <b>Cerrar</b> : Cerraras el formulario sin cambios
                        </li>
                        <li v-if="ptl" class="acciones">
                            <b>Ptl</b> : se enviará el pedido por <b>PTL</b> y una notificación del envio al usuario que lo generó) 
                        </li>
                        <li v-if="email" class="acciones">
                            <b>Mail</b> : se enviará el pedido en pdf por mail al proveedor, 
                            <br/> se copiará al usuario que lo generó para su conocimiento
                        </li>
                        <li class="acciones">
                            <b>Imprimir</b> : <b>No se enviará el pedido al proveedor</b> sólo se envia una copia en pdf al usuario que lo generó 
                        </li>
                </ul>
            </ion-row>

        </ion-content>
        <ion-footer >
            <ion-toolbar class="full"  color="blanco">
                <ion-buttons slot="end" class="full">
                    <ion-button @click="cerrar()" color="danger" expand="block" fill="solid" >
                        <ion-icon :md="closeCircle"></ion-icon>
                        Cerrar
                    </ion-button>
                    <ion-button :disabled="!ptl" @click="editar_estados('ap')" :color="ptl ? 'secondary' : 'default'" expand="block" :fill="ptl ? 'solid' : 'outline'" >
                        <ion-icon :md="cloudUpload"></ion-icon>
                        PTL
                    </ion-button>
                    <ion-button :disabled="!email" @click="editar_estados('am')" :color="email ? 'success' : 'default' " expand="block" :fill="email ? 'solid' : 'outline'" >
                        <ion-icon :md="mail"></ion-icon>
                        Mail
                    </ion-button>
                    <ion-button @click="editar_estados('au')" color="primary"  expand="block" fill="solid" >
                        <ion-icon :md="documentAttach"></ion-icon>
                        Usuario
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-footer>

    </ion-page>
</template>

<script>
    import {    
            IonPage, IonContent,IonRow,IonButton, IonHeader,IonFooter,IonTitle, IonCol, 
            IonIcon,IonButtons,IonToolbar, IonSpinner,
            } from '@ionic/vue';
    import { defineComponent } from 'vue';    
    import { pick } from 'underscore';
    import { closeCircle, mail,cloudUpload, documentAttach } from 'ionicons/icons';
    import {openAlerta} from  '@/utilities/global_functions';
    import eventBus from "@/event-bus";
    export default defineComponent({
        name : "GestorOrderMultiApprovalModal",
        components: {  
            IonPage,  IonContent,IonRow,  IonHeader,IonFooter,IonTitle,IonCol,
            IonButton, 
            IonIcon,IonButtons,IonToolbar, IonSpinner,
        },
        props : {
            items: Array
        }, 
        mounted() {
                this.items.forEach((v) => { 
                    v.answer = undefined
                    v.send_methods= JSON.parse(v.send_methods)
                    })
               window.fake_console(this.items)
           }, 
        data() {    
                return {
                    closeCircle, mail,cloudUpload,documentAttach, 
                    act: undefined, 
                    loading : false, 
                    mensaje1 :'Se cambiará el estado de los pedidos siguientes a "P": ',
                    mensaje2 :'Para continuar debes elegir que procedimiento de envio quieres elegir, se detallan los disponibles para los pedidos seleccionados. ',
 
                }
        },
        computed :{
                ptl: function () {
                    return this.items.filter(v=>v.send_methods.ptl===1).length == this.items.length
                }, 
                email:function () {
                    return this.items.filter(v=>v.send_methods.dest!=undefined).length == this.items.length
                },   
            },
        methods: {
            async cerrar() {
                eventBus().emitter.emit('cerrar-edicion-pedido', undefined)
            },
            async editar_estados(action){
                this.loading = true;
                const data = this.items.reduce((m,v)=> {
                    const k = pick(v, ['pdv','suc','ped']);
                    m.push(k);
                    return m; 
                } , [])
                const ap = await this.$http.post(`/buy/monitor-update-orders/${action}`, {data:data})
                const respuesta = ap.data
                window.fake_console(respuesta)
                if (respuesta.ok !=undefined){
                    const respuestas = respuesta.ok
                    const confirmacion = respuestas[false].length==0
                    if (confirmacion) {
                        openAlerta('Actualizacion Correcta!', 'Todos los pedidos han sido actualizados', ()=>{ eventBus().emitter.emit('cerrar-edicion-pedido', true) })    
                    }
                    else  {
                        const errores = respuestas[false].map(v=> {return `<li>${v}</li>`}).join(' ')
                        const msg = `<ul>Los siguientes pedidos no han podido ser actualizados.: ${errores}<br> Intentalo más tarde</ul>`
                        openAlerta('Uff, algún pedido ha fallado!', msg, ()=>{ 
                            eventBus().emitter.emit('cerrar-edicion-pedido', true) })    
                    }
                }
                else {
                    openAlerta('Error en la actualización!', respuesta.ko, ()=>{})
                }
                this.loading = false;
            }
        }
})
</script>

<style scoped>
    .ok {
        color: var(--ion-color-success)
    }
    .ko {
        color: var(--ion-color-danger)
    }
    .grande {
        padding: 0px 16px !important;
        font-size: larger !important; 
        font-weight: 700 !important;
        color: var(--ion-color-dark) !important;

    }
    .negrita { font-weight: bold;}
    ion-title {
        width:100% !important; 
        padding-left: 16px !important; 

    }
    .full {
        width:100% !important;
    }
    ul {
        padding-left:32px !important;
    }
    li.acciones {
        padding: 6px  15px !important; 
    }
    li.pedidos {
        padding: 2px  15px !important; 
    }
    .padded {
        padding-left:40px !important;
        font-weight: bold;
    }
    .peque {
        font-size: 0.8rem;
    }
    ion-buttons {
        float:right !important; 
    }
    ion-button {
        width: 25% !important; 
        margin-left:6px !important; 
        margin-right:6px !important; 
    }
    /*
    ion-button.derecha {
        float: right ;
        margin-top: 16px;
        margin-right: 16px ;  
        width: 8rem; 
        height: 2.2rem;
        --border-radius: 6px;
    }*/
</style>